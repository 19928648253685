import GraphqlCli from "util/GraphqlCli";
import gql from "graphql-tag";
import _ from "lodash";

export const stopEmergencyMessage = async (locationId, deviceIds) => {
  const _gql = gql`
    mutation stopEmergencyMessage($locationId: Int, $deviceIds: [Int]) {
      result: stopEmergencyMessage(
        locationId: $locationId
        deviceIds: $deviceIds
      ) {
        status
        error {
          message
          code
        }
      }
    }
  `;

  const result = (
    await GraphqlCli.mutate("kiosk?stopEmergencyMessage", _gql, {
      locationId,
      deviceIds
    })
  ).data.result;
  return result;
};

export const changeTemplate = async (locationId, deviceIds, templateId) => {
  const _gql = gql`
    mutation changeTemplate(
      $locationId: Int
      $deviceIds: [Int]
      $templateId: Int
    ) {
      result: changeTemplate(
        locationId: $locationId
        deviceIds: $deviceIds
        templateId: $templateId
      ) {
        status
        error {
          message
          code
        }
      }
    }
  `;

  const result = (
    await GraphqlCli.mutate("kiosk?changeTemplate", _gql, {
      locationId,
      deviceIds,
      templateId
    })
  ).data.result;
  return result;
};

export const saveUpdateEmergencyMessage = async (
  locationId,
  message,
  deviceIds
) => {
  const _gql = gql`
    mutation SaveUpdateEmergencyMessage(
      $locationId: Int
      $message: String
      $deviceIds: [Int]
    ) {
      result: saveUpdateEmergencyMessage(
        locationId: $locationId
        message: $message
        deviceIds: $deviceIds
      ) {
        status
        error {
          message
          code
        }
      }
    }
  `;

  const result = (
    await GraphqlCli.mutate("kiosk?saveUpdateEmergencyMessage", _gql, {
      locationId,
      message,
      deviceIds
    })
  ).data.result;
  return result;
};

export const getEmergencyMessage = async locationId => {
  const _gql = gql`
    query {
      emergencyMessage: getEmergencyMessage(locationId: ${locationId}) {
          status
          data
      }
    }
  `;

  let result = (await GraphqlCli.query("kiosk?getEmergencyMessage", _gql)).data
    .emergencyMessage.data;

  return result;
};

export const getMediaScheduleDates = async () => {
  const _gql = gql`
    query {
      mediaScheduleDates: getMediaScheduleDates {
        status
        data {
          id
          descriptionShort
          descriptionLong
          scheduleDate
        }
      }
    }
  `;

  let result = (await GraphqlCli.query("kiosk?getMediaScheduleDates", _gql))
    .data.mediaScheduleDates.data;
  return result;
};

export const getMediaItems = async () => {
  const _gql = gql`
    query {
      mediaItems: getMediaItems {
        data {
          mediaItemId
          mediaName
          mediaUUID
          mediaTypeId
          mediaTypeName
          mediaTypeCode
          playerType
          videoSourceType
          createdDt
          mediaExtension
          createdBy
        }
      }
    }
  `;

  let result = (await GraphqlCli.query("kiosk?getMediaItems", _gql, {})).data
    .mediaItems.data;

  return result;
};

export const updateMediaResourceColor = async (resourceType, id, color) => {
  const _gql = gql`
    mutation updateMediaResourceColor(
      $resourceType: ResourceType
      $id: Int
      $color: String
    ) {
      result: updateMediaResourceColor(
        resourceType: $resourceType
        id: $id
        color: $color
      ) {
        status
      }
    }
  `;

  let result = await GraphqlCli.mutate("kiosk?updateMediaResourceColor", _gql, {
    resourceType,
    id,
    color
  });
  return result.data.result.status;
};

export const updateServiceEwt = async serviceEwt => {
  const _gql = gql`
    mutation updateServiceEwt($serviceEwt: ServiceEwtInput) {
      result: updateServiceEwt(serviceEwt: $serviceEwt) {
        status
      }
    }
  `;

  let result = await GraphqlCli.mutate("kiosk?updateServiceEwt", _gql, {
    serviceEwt
  });
  return result.data.result.status;
};

export const getMapMediaTimeslots = async (
  deviceId,
  locationId,
  resourceType
) => {
  const _gql = gql`
    query GetMapMeidaTimeslots(
      $deviceId: Int
      $locationId: Int
      $resourceType: ResourceType
    ) {
      mapMediaTimeslots: getMapMediaTimeslots(
        deviceId: $deviceId
        locationId: $locationId
        resourceType: $resourceType
      ) {
        status
        data {
          scheduleDateDescShort
          scheduleDateDescLong
          scheduleDate
          scheduleAt
          resourceId
          color
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.mutate("kiosk?getMapMediaTimeslots", _gql, {
      deviceId,
      locationId,
      resourceType
    })
  ).data.mapMediaTimeslots.data;

  return result;
};

/**
 *
 * @param {*} resourceType 1
 * @param {*} reosurceId "PLAYLIST"
 * @param {*} mapMediaTimeslots [{"id":1, "scheduleAt":"1015"}]
 */
export const updateMapMediaTimeslot = async (
  deviceId,
  locationId,
  resourceType,
  mapMediaTimeslots
) => {
  const _gql = gql`
    mutation UpdateMapMediaTimeslot(
      $deviceId: Int
      $locationId: Int
      $resourceType: ResourceType
      $mapMediaTimeslots: [MapMediaTimeslotInput]
    ) {
      result: updateMapMediaTimeslot(
        deviceId: $deviceId
        locationId: $locationId
        resourceType: $resourceType
        mapMeidaTimeslots: $mapMediaTimeslots
      ) {
        status
      }
    }
  `;

  let result = await GraphqlCli.mutate("kiosk?updateMapMediaTimeslot", _gql, {
    resourceType,
    mapMediaTimeslots,
    deviceId,
    locationId
  });
  return result.data.result.status;
};

export const updateMapMediaPlaylistItem = async (
  playlistId,
  mapMediaPlaylistItems
) => {
  const _gql = gql`
    mutation UpdateMapMediaPlaylistItem(
      $playlistId: Int
      $mapMediaPlaylistItems: [MapMediaPlaylistItemInput]
    ) {
      result: updateMapMediaPlaylistItem(
        playlistId: $playlistId
        mapMediaPlaylistItems: $mapMediaPlaylistItems
      ) {
        status
        error {
          code
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.mutate("kiosk?updateMapMediaPlaylistItem", _gql, {
      playlistId,
      mapMediaPlaylistItems
    })
  ).data.result;

  return result;
};

export const deleteMediaItems = async mediaItemIds => {
  console.dir(mediaItemIds);
  const _gql = gql`
    mutation DeleteMediaItems($mediaItemIds: [Int]) {
      result: deleteMediaItems(mediaItemIds: $mediaItemIds) {
        status
        error {
          code
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.mutate("kiosk?deleteMediaItems", _gql, {
      mediaItemIds
    })
  ).data.result;

  return result;
};

export const updateTemplateResource = async (resource, templateId) => {
  const _gql = gql`
    mutation UpdateTemplateResource($resource: String, $templateId: Int) {
      result: updateTemplateResource(
        resource: $resource
        templateId: $templateId
      ) {
        status
        error {
          code
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.mutate("kiosk?updateTemplateResource", _gql, {
      resource,
      templateId
    })
  ).data.result;

  return result;
};

export const toggleTemplateGadget = async (templateId, gadgetName, onOff) => {
  const _gql = gql`
    mutation ToggleTemplateGadget(
      $templateId: Int
      $gadgetName: String
      $onOff: Boolean
    ) {
      result: toggleTemplateGadget(
        templateId: $templateId
        gadgetName: $gadgetName
        onOff: $onOff
      ) {
        status
        error {
          code
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.mutate("kiosk?toggleTemplateGadget", _gql, {
      templateId,
      gadgetName,
      onOff
    })
  ).data.result;

  return result;
};

export const cudScrollingMessage = async scrollingMessage => {
  const _gql = gql`
    mutation CudScrollingMessage($scrollingMessage: ScrollingMessageCUDInput) {
      result: cudScrollingMessage(scrollingMessage: $scrollingMessage) {
        status
        error {
          code
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.mutate("kiosk?cudScrollingMessage", _gql, {
      scrollingMessage
    })
  ).data.result;

  return result;
};

export const cudPlaylist = async playlist => {
  const _gql = gql`
    mutation CudPlaylist($playlist: PlaylistCUDInput) {
      result: cudPlaylist(playlist: $playlist) {
        status
        error {
          code
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.mutate("kiosk?cudPlaylist", _gql, {
      playlist
    })
  ).data.result;

  return result;
};

export const getSignDevices = async locationId => {
  const _gql = gql`
    query GetSignDevices($locationId: Int) {
      kioskDevices: getKioskDevices(locationId: $locationId) {
        data {
          id
          deviceName
          deviceCode
          playlistId
          playlistName
          scrollingMessageId
          scrollingMessageTitle
          scrollingMessage
          emergencyFlag
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.query(
      "kiosk?getKioskDevices",
      _gql,
      { locationId }
      // "cache-first"
    )
  ).data.kioskDevices.data;

  return result;
};

export const getServiceEwts = async locationId => {
  const _gql = gql`
    query GetServiceEwts($locationId: Int) {
      serviceEwts: getServiceEwts(locationId: $locationId) {
        status
        data {
          id
          serviceName
          ewtMin
          ewtMax
          ewtFixed
          ewtBuffer
          ewtScreen
          ewtCalculated
          ewtScreen
          updatedDt
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.query(
      "kiosk?getServiceEwts",
      _gql,
      { locationId }
      // "cache-first"
    )
  ).data.serviceEwts.data;

  return result;
};

export const getScrollingMessages = async () => {
  const _gql = gql`
    query {
      scrollingMessages: getScrollingMessages {
        status
        data {
          id
          title
          message
          updatedBy
          updatedDt
          color
        }
      }
    }
  `;

  let result = (await GraphqlCli.query("kiosk?getScrollingMessages", _gql, {}))
    .data.scrollingMessages.data;

  return result;
};

export const getMediaPlaylistsMasterOnly = async () => {
  const _gql = gql`
    query {
      mediaPlaylists: getMediaPlaylists {
        status
        data {
          id
          playlistName
          updatedBy
          updatedDt
          color
        }
      }
    }
  `;

  let result = (await GraphqlCli.query("kiosk?mediaPlaylists", _gql, {})).data
    .mediaPlaylists.data;

  return result;
};

export const getMediaPlaylists = async playlistId => {
  const _gql = gql`
    query {
      mediaPlaylists: getMediaPlaylists(playlistId: ${playlistId}) {
        status
        data {
          id
          playlistName
          updatedBy
          updatedDt
          mediaItems {
            mediaItemId
            mediaName
            mediaUUID
            mediaTypeId
            mediaTypeName
            mediaTypeCode
            playerType
            videoSourceType
            duration
            createdDt
            mediaExtension
          }
        }
      }
    }
  `;

  let result = (await GraphqlCli.query("kiosk?mediaPlaylists", _gql, {})).data
    .mediaPlaylists.data;

  return result;
};

export const getDisplayTemplates = async () => {
  const _gql = gql`
    query {
      displayTemplates: getDisplayTemplates {
        data {
          id
          templateName
          templateCode
          templateSetting
          updatedDt
          color
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.query("kiosk?getDisplayTemplates", _gql, {}, "cache-first")
  ).data.displayTemplates.data;

  return result;
};

export const getDisplayTemplate = async templateId => {
  const _gql = gql`
    query GetDisplayTemplate($templateId: Int) {
      displayTemplate: getDisplayTemplate(templateId: $templateId) {
        data {
          id
          templateName
          templateCode
          templateSetting
          updatedDt
        }
      }
    }
  `;

  let result = (
    await GraphqlCli.query("kiosk?getDisplayTemplate", _gql, {
      templateId
    })
  ).data.displayTemplate.data;

  return result;
};

export const getQuestionnaire = async (locationId, language) => {
  console.log(`getQuestionnaire`, locationId, language);
  const _gql = gql`
    query {
      questionnaires: getQuestionnaire(locationId:${locationId}, language:"${language}") {
        status
        data {
          title
          id
          question
          answerId
          answer
        }
      }
    }`;

  let questionnaires = (await GraphqlCli.query("kiosk?getQuestionnaire", _gql))
    .data.questionnaires.data;
  return questionnaires;
};
