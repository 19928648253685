import { Menu, Tabs, Select } from "antd";
import U from "components/Util";
import Parser from "html-react-parser";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getMenuInfo } from "services/user.service";
import Auxiliary from "util/Auxiliary";
import CustomScrollbars from "util/CustomScrollbars";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import MyIcon from "../../util/MyIcon";
import Location from "./Location";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import ChatApp from "./ChatApp";
const { TabPane } = Tabs;
const { Option } = Select;

// import MyMenuItemLink from "components/MyMenuItemLink/index";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  state = {
    menuInfo: [],
    modeCode: "OPERATION",
    tabKey: "location"
  };

  icon_style = {
    fontSize: "23px",
    //color: "#FFF",
    marginTop: "9px"
    // marginLeft: "1px",
    // marginRight: "20px"
  };

  constructor(props) {
    super(props);
    this.props.launchConsoleId && this.props.getConsoleInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedLocation !== this.props.selectedLocation) {
      // console.log("changed", this.props.selectedLocation);
    }
  }

  componentDidMount() {
    // console.log("*** SidebarContent componentDidMount");
    getMenuInfo().then(menuInfo => {
      // console.log("*** menuInfo", menuInfo);
      // console.log("*** selectedLocaton", this.props.selectedLocation);
      this.setState({ menuInfo: menuInfo });
    });
  }

  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = navStyle => {
    // console.log(navStyle);
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  /**
   *
   * @param {*} code
   */
  getMenuItemLink(menuCode, to, desc, icon, props) {
    if (menuCode.indexOf("BLAHBLAH.") === 0) {
      // some other custom render
    } else {
      // default
      return (
        <Link to={to}>
          {icon && (
            <MyIcon
              type={icon}
              style={{ fontSize: "30px", marginTop: "5px" }}
            />
          )}
          {Parser(desc)}
        </Link>
      );
    }
  }
  chgMode = v => {
    this.setState({ modeCode: v });
  };

  chgTab = k => {
    if (k !== "chat") {
      this.setState({ tabKey: k });
    }
  };
  render() {
    const {
      themeType,
      navStyle,
      pathname,
      width,
      selectedLocation
    } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[0];

    return (
      <div>
        <Auxiliary>
          <SidebarLogo />
          <div className="gx-sidebar-content">
            <div
              className={`gx-sidebar-notifications ${this.getNoHeaderClass(
                navStyle
              )}`}
            >
              <UserProfile />
              {/* {navStyle === "NAV_STYLE_MINI_SIDEBAR" && (
                <ChatApp
                  style={{ marginTop: 15, cursor: "pointer" }}
                  iconFontSize={20}
                ></ChatApp>
              )} */}
              <div className="gx-cc-hide-sider-collapsed">
                <Tabs
                  size={"samll"}
                  defaultActiveKey={this.state.tabKey}
                  activeKey={this.state.tabKey}
                  onChange={k => this.chgTab(k)}
                >
                  <TabPane
                    style={{
                      margin: "-6px 0 0 0"
                    }}
                    tab={
                      <FontAwesomeIcon
                        style={{ fontSize: 16 }}
                        icon="laptop-house"
                      ></FontAwesomeIcon>
                    }
                    key="location"
                  >
                    <Location />
                  </TabPane>
                  <TabPane
                    style={{
                      margin: "-6px 0 0 0"
                    }}
                    tab={
                      <FontAwesomeIcon
                        style={{ fontSize: 16 }}
                        icon="users-cog"
                      ></FontAwesomeIcon>
                    }
                    key="mode"
                  >
                    <Select
                      defaultValue={"OPERATION"}
                      style={{ width: "100%" }}
                      onChange={v => this.chgMode(v)}
                    >
                      <Option key={0} value={"OPERATION"}>
                        Operation
                      </Option>
                      <Option key={1} value={"ADMIN"}>
                        Admin
                      </Option>
                    </Select>
                  </TabPane>
                  {/* <TabPane tab={<ChatApp></ChatApp>} key="chat">
                    chat
                  </TabPane> */}
                </Tabs>
              </div>
            </div>
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
              >
                {this.state.menuInfo.map(menuInfoGroup => {
                  //   "selectedLocation",
                  //   JSON.stringify(selectedLocation)
                  // );
                  if (
                    menuInfoGroup.groupDisplayFlag === 1 &&
                    menuInfoGroup.modeCode === this.state.modeCode &&
                    selectedLocation &&
                    menuInfoGroup.locationId === selectedLocation.locationId
                  ) {
                    // console.log("menuInfoGroup", JSON.stringify(menuInfoGroup));
                    // console.log(
                    return (
                      <SubMenu
                        key={menuInfoGroup.groupCode}
                        className={this.getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                            {menuInfoGroup.groupIconClass && (
                              // want to effect when collapse menu , so wrapped with <i class="action">
                              <i className="anticon">
                                <FontAwesomeIcon
                                  icon={menuInfoGroup.groupIconClass}
                                  style={this.icon_style}
                                />
                              </i>
                            )}
                            {/* <IntlMessages
                              id={Parser(menuInfoGroup.groupDesc)}
                            /> */}
                            <span>{Parser(menuInfoGroup.groupDesc)}</span>
                          </span>
                        }
                      >
                        {menuInfoGroup.menuItems.map(menu => {
                          return (
                            <Menu.Item key={menu.menuCode}>
                              {/* <Link to={menu.menuUrl}>{menu.menuDesc}</Link> */}
                              {this.getMenuItemLink(
                                menu.menuCode,
                                menu.menuUrl,
                                menu.menuDesc,
                                menu.menuIconClass,
                                this.props
                              )}
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  } else {
                    return (
                      menuInfoGroup.groupDisplayFlag === 0 &&
                      menuInfoGroup.modeCode === this.state.modeCode &&
                      selectedLocation &&
                      menuInfoGroup.locationId ===
                        selectedLocation.locationId &&
                      menuInfoGroup.menuItems.map(menu => {
                        // console.log(`menuDesc`, menu.menuDesc);
                        return (
                          <Menu.Item key={menu.menuCode}>
                            {/* <Link to={menu.menuUrl}>{menu.menuDesc}</Link> */}
                            {this.getMenuItemLink(
                              menu.menuCode,
                              menu.menuUrl,
                              menu.menuDesc,
                              menu.menuIconClass,
                              this.props
                            )}
                          </Menu.Item>
                        );
                      })
                    );
                  }
                })}
              </Menu>
            </CustomScrollbars>
          </div>
        </Auxiliary>
      </div>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth: { selectedLocation } }) => {
  const { navStyle, themeType, locale, pathname, width } = settings;
  return {
    navStyle,
    themeType,
    locale,
    pathname,
    width,
    selectedLocation
  };
};
export default connect(mapStateToProps, {})(SidebarContent);
