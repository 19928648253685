import { combineReducers } from "redux";
import fetchData from "./fetchData";
import {
  VMC_VR_VISITOR_TYPE_REQUEST,
  VMC_VR_VISITOR_TYPE_SUCCESS,
  VMC_VR_VISITOR_TYPE_ERROR,
  VMC_VR_VISITOR_TYPE_CLEAR,
  VMC_VR_SEARCH_VISITOR_REG_REQUEST,
  VMC_VR_SEARCH_VISITOR_REG_SUCCESS,
  VMC_VR_SEARCH_VISITOR_REG_ERROR,
  VMC_VR_SEARCH_VISITOR_REG_CLEAR,
  VMC_VR_GET_VISITOR_REG_BY_ID_REQUEST,
  VMC_VR_GET_VISITOR_REG_BY_ID_SUCCESS,
  VMC_VR_GET_VISITOR_REG_BY_ID_ERROR,
  VMC_VR_GET_VISITOR_REG_BY_ID_CLEAR,
  VMC_VR_SAVE_VISITOR_REG_REQUEST,
  VMC_VR_SAVE_VISITOR_REG_SUCCESS,
  VMC_VR_SAVE_VISITOR_REG_ERROR,
  VMC_VR_SAVE_VISITOR_REG_CLEAR,
  VMC_VR_SAVE_PATIENT_REG_REQUEST,
  VMC_VR_SAVE_PATIENT_REG_SUCCESS,
  VMC_VR_SAVE_PATIENT_REG_ERROR,
  VMC_VR_SAVE_PATIENT_REG_CLEAR,
  VMC_VR_COMPANY_DATA_REQUEST,
  VMC_VR_COMPANY_DATA_SUCCESS,
  VMC_VR_COMPANY_DATA_ERROR,
  VMC_VR_COMPANY_DATA_CLEAR,
  VMC_VR_VISITOR_REG_LIST_REQUEST,
  VMC_VR_VISITOR_REG_LIST_SUCCESS,
  VMC_VR_VISITOR_REG_LIST_ERROR,
  VMC_VR_VISITOR_REG_LIST_CLEAR,
  VMC_VR_GET_QR_REQUEST,
  VMC_VR_GET_QR_SUCCESS,
  VMC_VR_GET_QR_ERROR,
  VMC_VR_GET_QR_CLEAR,
  VMC_VR_SAVE_STAFF_REG_REQUEST,
  VMC_VR_SAVE_STAFF_REG_SUCCESS,
  VMC_VR_SAVE_STAFF_REG_ERROR,
  VMC_VR_SAVE_STAFF_REG_CLEAR,
  VMC_VR_SEARCH_STAFF_REG_REQUEST,
  VMC_VR_SEARCH_STAFF_REG_SUCCESS,
  VMC_VR_SEARCH_STAFF_REG_ERROR,
  VMC_VR_SEARCH_STAFF_REG_CLEAR,
  VMC_VR_SEARCH_TENANT_REG_REQUEST,
  VMC_VR_SEARCH_TENANT_REG_SUCCESS,
  VMC_VR_SEARCH_TENANT_REG_ERROR,
  VMC_VR_SEARCH_TENANT_REG_CLEAR,
  VMC_VR_GET_STAFF_REG_BY_ID_REQUEST,
  VMC_VR_GET_STAFF_REG_BY_ID_SUCCESS,
  VMC_VR_GET_STAFF_REG_BY_ID_ERROR,
  VMC_VR_GET_STAFF_REG_BY_ID_CLEAR,
  VMC_VR_STAFF_REG_LIST_REQUEST,
  VMC_VR_STAFF_REG_LIST_SUCCESS,
  VMC_VR_STAFF_REG_LIST_ERROR,
  VMC_VR_STAFF_REG_LIST_CLEAR,
  VMC_VR_SAVE_TENANT_REG_REQUEST,
  VMC_VR_SAVE_TENANT_REG_SUCCESS,
  VMC_VR_SAVE_TENANT_REG_ERROR,
  VMC_VR_SAVE_TENANT_REG_CLEAR,
  VMC_VR_GET_TENANT_REG_BY_ID_REQUEST,
  VMC_VR_GET_TENANT_REG_BY_ID_SUCCESS,
  VMC_VR_GET_TENANT_REG_BY_ID_ERROR,
  VMC_VR_GET_TENANT_REG_BY_ID_CLEAR,
  VMC_VR_TENANT_REG_LIST_REQUEST,
  VMC_VR_TENANT_REG_LIST_SUCCESS,
  VMC_VR_TENANT_REG_LIST_ERROR,
  VMC_VR_TENANT_REG_LIST_CLEAR,
  VMC_VR_GET_PATIENT_REG_BY_ID_REQUEST,
  VMC_VR_GET_PATIENT_REG_BY_ID_SUCCESS,
  VMC_VR_GET_PATIENT_REG_BY_ID_ERROR,
  VMC_VR_GET_PATIENT_REG_BY_ID_CLEAR,
  VMC_VR_PATIENT_TYPE_REQUEST,
  VMC_VR_PATIENT_TYPE_SUCCESS,
  VMC_VR_PATIENT_TYPE_ERROR,
  VMC_VR_PATIENT_TYPE_CLEAR,
  VMC_VR_SEARCH_PATIENT_REG_REQUEST,
  VMC_VR_SEARCH_PATIENT_REG_SUCCESS,
  VMC_VR_SEARCH_PATIENT_REG_ERROR,
  VMC_VR_SEARCH_PATIENT_REG_CLEAR,
  VMC_VR_PATIENT_REG_LIST_REQUEST,
  VMC_VR_PATIENT_REG_LIST_SUCCESS,
  VMC_VR_PATIENT_REG_LIST_ERROR,
  VMC_VR_PATIENT_REG_LIST_CLEAR
} from "constants/ActionTypes";

const visitorReg = combineReducers({
  visitorTypeST: fetchData({
    types: [
      VMC_VR_VISITOR_TYPE_REQUEST,
      VMC_VR_VISITOR_TYPE_SUCCESS,
      VMC_VR_VISITOR_TYPE_ERROR,
      VMC_VR_VISITOR_TYPE_CLEAR
    ]
  }),
  patientTypeST: fetchData({
    types: [
      VMC_VR_PATIENT_TYPE_REQUEST,
      VMC_VR_PATIENT_TYPE_SUCCESS,
      VMC_VR_PATIENT_TYPE_ERROR,
      VMC_VR_PATIENT_TYPE_CLEAR
    ]
  }),
  searchVisitorRegST: fetchData({
    types: [
      VMC_VR_SEARCH_VISITOR_REG_REQUEST,
      VMC_VR_SEARCH_VISITOR_REG_SUCCESS,
      VMC_VR_SEARCH_VISITOR_REG_ERROR,
      VMC_VR_SEARCH_VISITOR_REG_CLEAR
    ]
  }),
  searchStaffRegST: fetchData({
    types: [
      VMC_VR_SEARCH_STAFF_REG_REQUEST,
      VMC_VR_SEARCH_STAFF_REG_SUCCESS,
      VMC_VR_SEARCH_STAFF_REG_ERROR,
      VMC_VR_SEARCH_STAFF_REG_CLEAR
    ]
  }),
  searchTenantRegST: fetchData({
    types: [
      VMC_VR_SEARCH_TENANT_REG_REQUEST,
      VMC_VR_SEARCH_TENANT_REG_SUCCESS,
      VMC_VR_SEARCH_TENANT_REG_ERROR,
      VMC_VR_SEARCH_TENANT_REG_CLEAR
    ]
  }),
  searchPatientRegST: fetchData({
    types: [
      VMC_VR_SEARCH_PATIENT_REG_REQUEST,
      VMC_VR_SEARCH_PATIENT_REG_SUCCESS,
      VMC_VR_SEARCH_PATIENT_REG_ERROR,
      VMC_VR_SEARCH_PATIENT_REG_CLEAR
    ]
  }),
  visitorRegByIdST: fetchData({
    types: [
      VMC_VR_GET_VISITOR_REG_BY_ID_REQUEST,
      VMC_VR_GET_VISITOR_REG_BY_ID_SUCCESS,
      VMC_VR_GET_VISITOR_REG_BY_ID_ERROR,
      VMC_VR_GET_VISITOR_REG_BY_ID_CLEAR
    ]
  }),
  staffRegByIdST: fetchData({
    types: [
      VMC_VR_GET_STAFF_REG_BY_ID_REQUEST,
      VMC_VR_GET_STAFF_REG_BY_ID_SUCCESS,
      VMC_VR_GET_STAFF_REG_BY_ID_ERROR,
      VMC_VR_GET_STAFF_REG_BY_ID_CLEAR
    ]
  }),
  tenantRegByIdST: fetchData({
    types: [
      VMC_VR_GET_TENANT_REG_BY_ID_REQUEST,
      VMC_VR_GET_TENANT_REG_BY_ID_SUCCESS,
      VMC_VR_GET_TENANT_REG_BY_ID_ERROR,
      VMC_VR_GET_TENANT_REG_BY_ID_CLEAR
    ]
  }),
  patientRegByIdST: fetchData({
    types: [
      VMC_VR_GET_PATIENT_REG_BY_ID_REQUEST,
      VMC_VR_GET_PATIENT_REG_BY_ID_SUCCESS,
      VMC_VR_GET_PATIENT_REG_BY_ID_ERROR,
      VMC_VR_GET_PATIENT_REG_BY_ID_CLEAR
    ]
  }),
  visitorRegST: fetchData({
    types: [
      VMC_VR_SAVE_VISITOR_REG_REQUEST,
      VMC_VR_SAVE_VISITOR_REG_SUCCESS,
      VMC_VR_SAVE_VISITOR_REG_ERROR,
      VMC_VR_SAVE_VISITOR_REG_CLEAR
    ]
  }),
  patientRegST: fetchData({
    types: [
      VMC_VR_SAVE_PATIENT_REG_REQUEST,
      VMC_VR_SAVE_PATIENT_REG_SUCCESS,
      VMC_VR_SAVE_PATIENT_REG_ERROR,
      VMC_VR_SAVE_PATIENT_REG_CLEAR
    ]
  }),
  companiesST: fetchData({
    types: [
      VMC_VR_COMPANY_DATA_REQUEST,
      VMC_VR_COMPANY_DATA_SUCCESS,
      VMC_VR_COMPANY_DATA_ERROR,
      VMC_VR_COMPANY_DATA_CLEAR
    ]
  }),
  visitorRegListST: fetchData({
    types: [
      VMC_VR_VISITOR_REG_LIST_REQUEST,
      VMC_VR_VISITOR_REG_LIST_SUCCESS,
      VMC_VR_VISITOR_REG_LIST_ERROR,
      VMC_VR_VISITOR_REG_LIST_CLEAR
    ]
  }),
  patientRegListST: fetchData({
    types: [
      VMC_VR_PATIENT_REG_LIST_REQUEST,
      VMC_VR_PATIENT_REG_LIST_SUCCESS,
      VMC_VR_PATIENT_REG_LIST_ERROR,
      VMC_VR_PATIENT_REG_LIST_CLEAR
    ]
  }),
  qrCodeST: fetchData({
    types: [
      VMC_VR_GET_QR_REQUEST,
      VMC_VR_GET_QR_SUCCESS,
      VMC_VR_GET_QR_ERROR,
      VMC_VR_GET_QR_CLEAR
    ]
  }),
  staffRegST: fetchData({
    types: [
      VMC_VR_SAVE_STAFF_REG_REQUEST,
      VMC_VR_SAVE_STAFF_REG_SUCCESS,
      VMC_VR_SAVE_STAFF_REG_ERROR,
      VMC_VR_SAVE_STAFF_REG_CLEAR
    ]
  }),
  tenantRegST: fetchData({
    types: [
      VMC_VR_SAVE_TENANT_REG_REQUEST,
      VMC_VR_SAVE_TENANT_REG_SUCCESS,
      VMC_VR_SAVE_TENANT_REG_ERROR,
      VMC_VR_SAVE_TENANT_REG_CLEAR
    ]
  }),
  staffRegListST: fetchData({
    types: [
      VMC_VR_STAFF_REG_LIST_REQUEST,
      VMC_VR_STAFF_REG_LIST_SUCCESS,
      VMC_VR_STAFF_REG_LIST_ERROR,
      VMC_VR_STAFF_REG_LIST_CLEAR
    ]
  }),
  tenantRegListST: fetchData({
    types: [
      VMC_VR_TENANT_REG_LIST_REQUEST,
      VMC_VR_TENANT_REG_LIST_SUCCESS,
      VMC_VR_TENANT_REG_LIST_ERROR,
      VMC_VR_TENANT_REG_LIST_CLEAR
    ]
  })
});

export default visitorReg;
