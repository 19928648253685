import React, { useEffect } from "react";
import KioskRoute from "./KioskRoute";
import { connect } from "react-redux";
import {
  ScrollingMessage,
  subscribeKioskTemplateChanged,
  EmergencyMessageAlert
} from "./Common";
import {
  forceScrollingMessageChange,
  updateTemplateForPage
} from "appRedux/actions/KioskConsole";
import yaml from "js-yaml";

const mapStateToProps = ({ kioskConsole }) => {
  const { scrollingMessage, launchConsoleId } = kioskConsole;
  return { scrollingMessage, launchConsoleId };
};

export default connect(mapStateToProps, {
  updateTemplateForPage,
  forceScrollingMessageChange
})(
  ({
    scrollingMessage,
    match,
    location,
    forceScrollingMessageChange,
    updateTemplateForPage,
    launchConsoleId
  }) => {
    useEffect(() => {
      subscribeKioskTemplateChanged((page, templateSetting) => {
        console.log(`templateSetting`, page, yaml.safeLoad(templateSetting));
        updateTemplateForPage(page, yaml.safeLoad(templateSetting));
      });
    }, []);

    return (
      <>
        <EmergencyMessageAlert
          key={"kioskEmergencyMessage"}
          deviceId={launchConsoleId}
        ></EmergencyMessageAlert>
        <KioskRoute match={match} location={location} />
        <div className="cc-kiosk">
          <div className="footer" style={{ bottom: 20 }}>
            <div style={{ position: "absolute", left: 10, width: 630 }}>
              {scrollingMessage && (
                <ScrollingMessage
                  hasChanges={(id, message) =>
                    forceScrollingMessageChange(id, message)
                  }
                  {...scrollingMessage}
                ></ScrollingMessage>
              )}
            </div>
            <div style={{ position: "absolute", right: 20 }}>
              <img
                alt="example"
                style={{ height: 50 }}
                src={require("assets/images/logo-powered-by.png")}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);
