import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input, Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
import { userSignIn, verifyOTP, userSignOut } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { TFA_MODE_SMS, TFA_MODE_EMAIL } from "../constants";
import OTPInput, { ResendOTP } from "otp-input-react";
import gql from "graphql-tag";
import { getOperationName } from "../../node_modules/apollo-link/lib/index";
import { Modal } from "antd";
const FormItem = Form.Item;
const { Text } = Typography;
const GEN_OTP = gql`
  mutation genOpt($userId: String, $tfaMode: String) {
    data: genOpt(userId: $userId, tfaMode: $tfaMode)
  }
`;

const OtpPage = props => {
  const [initCount, setInitCount] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [OTP, setOTP] = useState(null);
  const [
    genOtp,
    { loading: mutationGOLoading, error: mutationGOError, data: mutationGOData }
  ] = useMutation(GEN_OTP, {
    context: { path: "ap/oauth/token?genOpt" }
  });
  const [message, setMessage] = useState(null);
  const [userId, setUserId] = useState(localStorage.getItem("tempUserName"));

  const otpSendUserId = "otpSendUserId";

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);

    if (OTP && typeof OTP != "undefined" && OTP.length == 6) {
      //let userName = localStorage.getItem("tempUserName");
      props.verifyOTP({ userId, otp: OTP });
    }
  };

  const sendOtp = tfaMode => {
    genOtp({
      variables: {
        userId,
        tfaMode
      }
    });
    if (TFA_MODE_SMS === tfaMode) {
      setMessage("your mobile!");
    } else if (TFA_MODE_EMAIL === tfaMode) {
      setMessage("your email!");
    }
  };

  useEffect(() => {
    console.log("props.authUser", props.authUser);
    if (props.authUser.tfaModeCode === TFA_MODE_SMS) {
    }

    if (props.authUser.tfaModeCode === TFA_MODE_EMAIL) {
      if (localStorage.getItem(otpSendUserId) !== userId) {
        sendOtp(props.authUser.tfaModeCode);
      }
    }
  }, []);

  // send sms or email to user
  //{ loading: mutationGOLoading, error: mutationGOError, data: mutationGOData }
  useEffect(() => {
    setInitCount(initCount + 1);
    if (initCount < 1) return;
    if (mutationGOLoading === false) {
      if (mutationGOError) {
        console.log("mutationGOError", mutationGOError);
        Modal.error({
          title: "Error",
          content: "Failed to Send"
        });
      } else {
        console.log("mutationGOData", mutationGOData);
        if (mutationGOData.data && mutationGOData.data === "SUCCESS") {
          localStorage.setItem(otpSendUserId, userId);
          Modal.success({
            title: "Success",
            content: "OTP send to " + message
          });
        } else if (mutationGOData.data && mutationGOData.data === "FAIL") {
          Modal.error({
            title: "Error",
            content: "Failed to send OTP to " + message
          });
        }
      }
    }
    return () => {
      localStorage.removeItem(otpSendUserId);
    };
  }, [mutationGOData]);

  useEffect(() => {
    if (props.token !== null && props.otpPass) {
      props.history.push("/");
    }
  }, [props.otpPass, props.token]);

  // signOut() {
  //   this.props.userSignOut();
  //   this.props.history.push("/signin");
  // }
  const signOut = () => {
    props.userSignOut();
    props.history.push("/signin");
    localStorage.removeItem(otpSendUserId);
  };

  const { getFieldDecorator } = props.form;

  const otpStyle = {
    color: "#ff3333",
    fontSize: "20px",
    height: "40px",
    width: "40px",
    borderRadius: "10px",
    border: "none",
    outline: "none"
    // ":focus": {
    //   outline: "none"
    // }
  };

  let errorMessage;

  if (submitted && (!OTP || (typeof OTP != "undefined" && OTP.length != 6))) {
    errorMessage = (
      <div>
        <div>
          <Text type="warning">Please provide OTP.</Text>
        </div>
        <div>
          <Text type="warning">&nbsp;</Text>
        </div>
      </div>
    );
  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src="https://via.placeholder.com/272x395" alt="Neature" /> */}
              <img
                src={require("assets/images/Data_center_in_the_dark.jpg")}
                alt="Neature"
              />
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 10,
                  background: "#9e9797c2",
                  height: 60,
                  width: "100%",
                  textAlign: "center",
                  zIndex: 2
                }}
              >
                <div>
                  <img
                    alt="example"
                    style={{ height: 60, width: 190 }}
                    // src={require("assets/images/IdGates-Black-Small.png")}
                    src={require("assets/images/71ROBINSON_LOGO.png")}
                    
                  />
                </div>
              </div>
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                {/* <IntlMessages id="app.userAuth.signIn" /> */}
                OTP
              </h1>
              <p>Please enter OTP.</p>
              <p>{/* <IntlMessages id="app.userAuth.getAccount" /> */}</p>
            </div>
            <div className="gx-app-logo">
              {/* <img alt="example" src={require("assets/images/logo.png")} /> */}
              {/* <img
                alt="Id Gates"
                src={require("assets/images/IdGates-Black-Small.png")}
              /> */}
            </div>
          </div>

          <div className="gx-app-login-content" style={{ textAlign: "right" }}>
            <div>
              {/* otp : {this.state.OTP}
                <br /> */}
              {/* count : {this.state.count} */}
              <OTPInput
                value={OTP}
                onChange={v =>
                  // this.setState({
                  //   OTP: v
                  // })
                  setOTP(v)
                }
                title="Number only input"
                // autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus={true}
                inputStyles={otpStyle}
                // secure
              />
            </div>
            <br />
            {errorMessage}
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0 "
              layout="inline"
            >
              <Row type="flex" justify="space-between">
                <Button
                  type="danger"
                  className="gx-mb-0"
                  htmlType="button"
                  onClick={() => signOut()}
                >
                  Logout
                </Button>
                <Button
                  type="danger"
                  htmlType="button"
                  className="gx-mb-0"
                  onClick={e => {
                    e.preventDefault();
                    //props.history.push("/");
                    setOTP(null);
                    setSubmitted(false);
                    //refOtpInput.current.focus();
                  }}
                >
                  Clear
                </Button>
                <Button
                  type="success"
                  className="gx-mb-0"
                  htmlType="button"
                  onClick={e => {
                    e.preventDefault();
                    sendOtp(props.authUser.tfaModeCode);
                  }}
                >
                  Resend
                </Button>

                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                {/* <Col span={8}>
                  <FormItem style={{ textAlign: "left" }}>
                    <Button
                      type="danger"
                      className="gx-mb-0"
                      onClick={() => signOut()}
                    >
                      Logout
                    </Button>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem style={{ textAlign: "left" }}>
                    <Button
                      type="danger"
                      className="gx-mb-0"
                      onClick={() => props.history.push("/")}
                    >
                      Clear
                    </Button>
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                    >
                      <IntlMessages id="app.userAuth.signIn" />
                    </Button>
                  </FormItem>
                </Col> */}
              </Row>
            </Form>
            <span className="gx-text-light gx-fs-sm">
              <br />
              Powered by CentriCore OTP
            </span>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(OtpPage);

const mapStateToProps = ({ auth }) => {
  const { token, otpPass, authUser } = auth;
  return { token, otpPass, authUser };
};

export default connect(mapStateToProps, { userSignOut, userSignIn, verifyOTP })(
  WrappedNormalLoginForm
);
