import { combineReducers } from "redux";
import fetchData from "./fetchData";
import {
  VMC_M_GET_DORSCON_LIST_REQUEST,
  VMC_M_GET_DORSCON_LIST_SUCCESS,
  VMC_M_GET_DORSCON_LIST_ERROR,
  VMC_M_GET_DORSCON_LIST_CLEAR
} from "constants/ActionTypes";

const vmcManager = combineReducers({
  dorsconListST: fetchData({
    types: [
      VMC_M_GET_DORSCON_LIST_REQUEST,
      VMC_M_GET_DORSCON_LIST_SUCCESS,
      VMC_M_GET_DORSCON_LIST_ERROR,
      VMC_M_GET_DORSCON_LIST_CLEAR
    ]
  })
});

export default vmcManager;
