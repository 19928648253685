import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = (props) => { 
  const match={url:"/"};
  return (
 
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}welcome`}
        component={asyncComponent(() => import("./WelcomePage"))}
      />
      <Route
        path={`${match.url}live-monitor/liveVisitorDash`}
        component={asyncComponent(() =>
          import("./live-monitor/LiveVisitorDashPage")
        )}
      />
      <Route
        path={`${match.url}staff/registration/:parRegId?`}
        component={asyncComponent(() => import("./staff/StaffRegPage"))}
      />
      <Route
        path={`${match.url}staff/search`}
        component={asyncComponent(() => import("./staff/StaffSearchPage"))}
      />
      <Route
        path={`${match.url}tenant/registration/:parRegId?`}
        component={asyncComponent(() => import("./tenant/TenantRegPage"))}
      />
      <Route
        path={`${match.url}tenant/search`}
        component={asyncComponent(() => import("./tenant/TenantSearchPage"))}
      />
      <Route
        path={`${match.url}tenant/uploadNew`}
        component={asyncComponent(() => import("./tenant/UploadNewPage"))}
      />
      <Route
        path={`${match.url}map-view/View-1`}
        component={asyncComponent(() => import("./map-view/View-1"))}
      />
      <Route
        path={`${match.url}visitor/registration/:parRegId?`}
        component={asyncComponent(() => import("./visitor/VisitorRegPage"))}
      />
      <Route
        path={`${match.url}visitor/search`}
        component={asyncComponent(() => import("./visitor/VisitorSearchPage"))}
      />
      <Route
        path={`${match.url}patient/registration/:parRegId?`}
        component={asyncComponent(() => import("./patient/PatientRegPage"))}
      />
      <Route
        path={`${match.url}patient/search`}
        component={asyncComponent(() => import("./patient/PatientSearchPage"))}
      />
      <Route
        path={`${match.url}user/userRoleEntry`}
        component={asyncComponent(() => import("./user/UserRoleEntryPage"))}
      />
      <Route
        path={`${match.url}user/profileMenuMapping`}
        component={asyncComponent(() =>
          import("./user/ProfileMenuMappingPage")
        )}
      />
      <Route
        path={`${match.url}user/userEntry/:id?`}
        component={asyncComponent(() => import("./user/UserEntryPage"))}
      />
      <Route
        path={`${match.url}user/search`}
        component={asyncComponent(() => import("./user/UserSearchPage"))}
      />
      <Route
        path={`${match.url}reports/visitorReg`}
        component={asyncComponent(() => import("./reports/VisitorRegPage"))}
      />
      <Route
        path={`${match.url}reports/visitorTran`}
        component={asyncComponent(() => import("./reports/VisitorTranPage"))}
      />
      <Route
        path={`${match.url}reports/staffReg`}
        component={asyncComponent(() => import("./reports/StaffRegPage"))}
      />
      <Route
        path={`${match.url}reports/staffTran`}
        component={asyncComponent(() => import("./reports/StaffTranPage"))}
      />
      <Route
        path={`${match.url}reports/tenantReg`}
        component={asyncComponent(() => import("./reports/TenantRegPage"))}
      />
      <Route
        path={`${match.url}reports/tenantTran`}
        component={asyncComponent(() => import("./reports/TenantTranPage"))}
      />
      <Route
        path={`${match.url}reports/tenantVisitationDetail`}
        component={asyncComponent(() => import("./reports/TenantVisitationDetailPage"))}
      />

      <Route
        path={`${match.url}reports/visitorVisitationDetail`}
        component={asyncComponent(() => import("./reports/VisitorVisitationDetailPage"))}
      />

      <Route
              path={`${match.url}reports/tenantVisitationSummary`}
              component={asyncComponent(() => import("./reports/TenantVisitationSummaryPage"))}
            />

      <Route
        path={`${match.url}reports/visitorVisitationSummary`}
        component={asyncComponent(() => import("./reports/VisitorVisitationSummaryPage"))}
      />


      <Route
        path={`${match.url}vmcm/dorsconSetting`}
        component={asyncComponent(() =>
          import("./vmc-manager/DorsconSettingPage")
        )}
      />
      <Route
        path={`${match.url}vmcm/hostList`}
        component={asyncComponent(() => import("./vmc-manager/HostListPage"))}
      />
      <Route
        path={`${match.url}vmcm/questionaireEntry`}
        component={asyncComponent(() =>
          import("./vmc-manager/QuestionaireEntryPage")
        )}
      />
      <Route
        path={`${match.url}vmcm/mobileCreditOverview`}
        component={asyncComponent(() =>
          import("./vmc-manager/MobileCreditOverviewPage")
        )}
      />
      <Route
        path={`${match.url}vmcm/towerEntry`}
        component={asyncComponent(() => import("./vmc-manager/TowerEntryPage"))}
      />

      <Route
        path={`${match.url}vmcm/lobbyEntry`}
        component={asyncComponent(() => import("./vmc-manager/LobbyEntryPage"))}
      />

      <Route
        path={`${match.url}vmcm/levelEntry`}
        component={asyncComponent(() => import("./vmc-manager/LevelEntryPage"))}
      />

      <Route
        path={`${match.url}vmcm/unitEntry`}
        component={asyncComponent(() => import("./vmc-manager/UnitEntryPage"))}
      />

      <Route
        path={`${match.url}vmcm/companyEntry/:id?`}
        component={asyncComponent(() =>
          import("./vmc-manager/CompanyEntryPage")
        )}
      />

      <Route
        path={`${match.url}vmcm/companyList`}
        component={asyncComponent(() =>
          import("./vmc-manager/CompanyListPage")
        )}
      />

      <Route
        path={`${match.url}smsm/smsAlerts`}
        component={asyncComponent(() => import("./sms-manager/SmsAlertsPage"))}
      />
      <Route
        path={`${match.url}gantry/template/list`}
        component={asyncComponent(() => import("./gantry/TemplatePage"))}
      />
      <Route
        path={`${match.url}gantry/template/templateDetail/:templateId`}
        component={asyncComponent(() => import("./gantry/TemplateDetailPage"))}
      />
      <Route
        path={`${match.url}kiosk/template/list`}
        component={asyncComponent(() => import("./kiosk/TemplatePage"))}
      />
      <Route
        path={`${match.url}kiosk/template/templateDetail/:templateId`}
        component={asyncComponent(() => import("./kiosk/TemplateDetailPage"))}
      />

      <Route
        path={`${match.url}kiosk/list`}
        component={asyncComponent(() => import("./kiosk/KioskListPage"))}
      />
      <Route
        path={`${match.url}kiosk/manageMediaItem`}
        component={asyncComponent(() => import("./kiosk/ManageMediaItemPage"))}
      />
      <Route
        path={`${match.url}kiosk/playlist/detail/:playlistId`}
        component={asyncComponent(() => import("./kiosk/PlaylistDetailPage"))}
      />
      <Route
        path={`${match.url}kiosk/playlist`}
        component={asyncComponent(() => import("./kiosk/PlaylistPage"))}
      />
      <Route
        path={`${match.url}vmcm/laneEdit`}
        component={asyncComponent(() => import("./vmc-manager/LaneEditPage"))}
      />
    </Switch>
    <Route
      path={`${match.url}kiosk/scrollingMessage`}
      component={asyncComponent(() => import("./kiosk/ScrollingMessagePage"))}
    />
    <Route
      path={`${match.url}kiosk/mapping`}
      component={asyncComponent(() => import("./kiosk/MappingPage"))}
    />
    <Route
      path={`${match.url}live-monitor/liveHostDash`}
      component={asyncComponent(() =>
        import("./live-monitor/LiveHostDashPage")
      )}
    />
    <Route
        path={`${match.url}staff/uploadNew`}
        component={asyncComponent(() => import("./staff/UploadNewPage"))}
      />
  </div>
)};

// export default App;
const MemoizedApp = React.memo(App);
export default MemoizedApp;
