import React, { Component, useState, useEffect } from "react";
import { Layout, Popover, Breadcrumb, Icon, Badge, Button } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { getSizeFromWidth } from "../../util/PageWidthUtil";
import {
  switchLanguage,
  toggleCollapsedSideNav
} from "../../appRedux/actions/Setting";
// import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
// import AppNotification from "components/AppNotification";
// import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";
import { useCustomSubscription } from "hooks";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
// import MyIcon from "../../util/MyIcon";
import uuid from "react-uuid";
import { getDorsconListAC } from "../../appRedux/actions/VmcManager";
import { SUCCESS, ERROR } from "../../constants";
import gql from "graphql-tag";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "./notifications.css";

const { Header } = Layout;

// const DORSCON_CHANGED = gql`
//   subscription dorsconChanged($userId: Int) {
//     data: dorsconChanged(userId: $userId) {
//       id
//       dorsconName
//       dorsconCode
//     }
//   }
// `;

const Topbar = props => {
  const [searchText, setSearchText] = useState("");
  const [initCount, setInitCount] = useState(0);
  const [selectedDorscon, setSelectedDorscon] = useState({});
  // const { data: subData } = useCustomSubscription(DORSCON_CHANGED, {
  //   userId: 1
  // });

  // useEffect(() => {
  //   console.log("sub data");
  //   console.dir(subData);
  //   if (subData && subData.data && subData.data.id) {
  //     props.getDorsconListAC();
  //     setTimeout(() => {
  //       createNotification(subData.data.dorsconCode)();
  //     }, 1000);
  //   }
  // }, [subData]);

  const createNotification = type => {
    console.log("createNotification", type);
    return () => {
      switch (type) {
        case "Y": // Yellow
          NotificationManager.info(
            "Dorscon changed to Yellow.  Please acknowledge by click me!",
            "",
            0
          );
          break;
        case "G": // Green
          NotificationManager.success(
            "Dorscon changed to Green.  Please acknowledge by click me!",
            "",
            0
          );
          break;
        case "O": // Orange
          NotificationManager.warning(
            "Dorscon changed to Orange.  Please acknowledge by click me!",
            "",
            0
          );
          break;
        case "R": // Red
          NotificationManager.error(
            "Dorscon changed to Red.  Please acknowledge by click me!",
            "",
            0
          );
          break;
        default:
          NotificationManager.info("Info Message");
          break;
      }
    };
  };

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const aboutMenu = () => (
    <div>
      <p>Version : {process.env.REACT_APP_SYS_VERSION}</p>
      <p>Release Date : {process.env.REACT_APP_SYS_RELEASE_DATE}</p>
    </div>
  );

  const updateSearchChatUser = evt => {
    setSearchText(evt.target.value);
  };

  const breadcrumbSetting = [
    {
      path: "roomConsole",
      content: <span>Room Console</span>
    },
    {
      path: "flowFresh",
      content: <span>Fresh Flow</span>
    },
    {
      path: "flowNoShow",
      content: <span>No Show Flow</span>
    },
    {
      path: "visitor",
      content: <span>Visitor Info</span>
    },
    {
      path: "staff",
      content: <span>Staff Info</span>
    },
    {
      path: "tenant",
      content: <span>Tenant Info</span>
    },
    {
      path: "registration",
      content: <span>Registration</span>
    },
    {
      path: "search",
      content: <span>Search</span>
    },
    {
      path: "live-monitor",
      content: <span>Live Monitor</span>
    },
    {
      path: "livedash",
      content: <span>Live Dash</span>
    },
    {
      path: "liveVisitorDash",
      content: <span>Live Visitor Dash</span>
    },
    {
      path: "liveHostDash",
      content: <span>Live Host Dash</span>
    },
    {
      path: "liveDashboardGm",
      content: (
        <span>
          L-Dash{" "}
          <sup>
            <i>GM</i>
          </sup>
        </span>
      )
    },
    {
      path: "liveDashboardTm",
      content: (
        <span>
          L-Dash{" "}
          <sup>
            <i>TM</i>
          </sup>
        </span>
      )
    },
    {
      path: "patient",
      content: <span>Patient Info</span>
    },
    {
      path: "user",
      content: <span>User Info</span>
    },
    {
      path: "userRoleEntry",
      content: <span>Profile Entry</span>
    },
    {
      path: "map-view",
      content: <span>Map View</span>
    },
    {
      path: "View-1",
      content: <span>View 1</span>
    },
    {
      path: "profileMenuMapping",
      content: <span>Profile Menu Mapping </span>
    },
    {
      path: "userEntry",
      content: <span>User Entry</span>
    },
    {
      path: "reports",
      content: <span>Reports</span>
    },
    {
      path: "visitorReg",
      content: <span>Visitor Registration</span>
    },
    {
      path: "visitorTran",
      content: <span>Visitor Transaction</span>
    },
    {
      path: "staffTran",
      content: <span>Staff Transaction</span>
    },
    {
      path: "staffReg",
      content: <span>Staff Registration</span>
    },
    {
      path: "tenantTran",
      content: <span>Tenant Transaction</span>
    },
    {
      path: "tenantReg",
      content: <span>Tenant Registration</span>
    },
    {
      path: "smsm",
      content: <span>Sms Manager</span>
    },
    {
      path: "smsAlerts",
      content: <span>Sms Alerts</span>
    },
    {
      path: "vmcm",
      content: <span>VMC Manager</span>
    },
    {
      path: "dorsconSetting",
      content: <span>Dorscon Setting</span>
    },
    {
      path: "hostList",
      content: <span>Host List</span>
    },
    {
      path: "questionaireEntry",
      content: <span>Questionaire Entry</span>
    },
    {
      path: "towerEntry",
      content: <span>Tower Entry</span>
    },
    {
      path: "lobbyEntry",
      content: <span>Lobby Entry</span>
    },
    {
      path: "levelEntry",
      content: <span>Level Entry</span>
    },
    {
      path: "unitEntry",
      content: <span>Unit Entry</span>
    },
    {
      path: "companyEntry",
      content: <span>Company Entry</span>
    },
    {
      path: "companyList",
      content: <span>Company List</span>
    },
    {
      path: "laneEdit",
      content: <span>Lane Edit</span>
    },
    {
      path: "tenantVisitationDetail",
      content: <span>Tenant Visitation Details</span>
    },
    {
      path: "tenantVisitationSummary",
      content: <span>Tenant Visitation Summary</span>
    },
    {
      path: "visitorVisitationDetail",
      content: <span>Visitor Visitation Details</span>
    },
    {
      path: "visitorVisitationSummary",
      content: <span>Visitor Visitation Summary</span>
    },

  ];

  const getBreadcrumb = () => {
    var paths = props.location.pathname.split("/");
    return paths.map((path, i) => {
      var setting = breadcrumbSetting.filter(setting => setting.path === path);
      if (setting.length > 0) {
        setting = setting[0];
        return (
          <Breadcrumb.Item key={uuid()}>
            <span className="gx-link">{setting.content}</span>
          </Breadcrumb.Item>
        );
      }
    });
  };

  // xs	<576px and also default setting, could be a span value or an object containing above props	number|object	-
  // sm	≥576px, could be a span value or an object containing above props	number|object	-
  // md	≥768px, could be a span value or an object containing above props	number|object	-
  // lg	≥992px, could be a span value or an object containing above props	number|object	-
  // xl	≥1200px, could be a span value or an object containing above props	number|object	-
  // xxl	≥1600px, could be a span value or an object containing
  const calcWidthForHeader = size => {
    switch (size) {
      case "xxl":
        return "60%";
        break;
      case "xl":
        // code block
        return "50%";
        break;
      default:
        return "";
      // code block
    }
  };

  const calcWidthForBadge = size => {
    switch (size) {
      case "xxl":
        return "200px";
        break;
      case "xl":
        return "150px";
        break;
      case "lg":
        return "70px";
        break;
      default:
        return "60px";
      // code block
    }
  };

  useEffect(() => {
    setInitCount(initCount + 1);
    if (initCount < 1) return;

    if (props.dorsconListST.status === SUCCESS) {
      //props.setSearchVisitorRegResultToDefault();
      const { data } = props.dorsconListST;
      console.log(" dorsconListST ");
      if (data.length > 0) {
        const activeDorscon = data.find(row => row.recordStatus === "A");
        console.log("activeDorscon", activeDorscon);
        setSelectedDorscon(activeDorscon);
      }
    }
  }, [props.dorsconListST]);

  useEffect(() => {
    props.getDorsconListAC();
  }, []);

  const { locale, width, navCollapsed, navStyle } = props;
  const size = getSizeFromWidth(width);
  const dorsconColor = { G: "green", Y: "yellow", O: "orange", R: "red" };
  const dorsconFontColor = { G: "white", Y: "black", O: "white", R: "white" };

  return (
    <Auxiliary>
      <Header>
        {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                props.toggleCollapsedSideNav(!navCollapsed);
              }}
            />
          </div>
        ) : null}

        <div className="gx-d-none gx-d-lg-block">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span className="gx-link">
                <Link to="/">
                  <Icon type="home" />
                </Link>
              </span>
            </Breadcrumb.Item>
            {getBreadcrumb()}
          </Breadcrumb>
        </div>
        {/* <h1
          className="gx-d-none gx-d-lg-block"
          style={{
            width: `${calcWidthForHeader(size)}`,
            padding: `${size === "lg" ? "0 10px" : "0 20px"}`,
            fontFamily: "Cinzel Decorative",
            marginBottom: 0,
            textAlign: "center",
            fontSize: `${size === "lg" ? "17px" : "22px"}`
          }}
        >
          Visitor Management Console
        </h1>
        <h1
          className="gx-d-inline-block gx-d-lg-none"
          style={{
            padding: "0 20px",
            fontFamily: "Cinzel Decorative",
            fontSize: 15,
            marginBottom: 0
          }}
        >
          Visitor Management Console
        </h1> */}

        <ul className="gx-header-notifications gx-ml-auto">
          <h1
            className="gx-d-none gx-d-lg-block"
            style={{
              //width: `${calcWidthForHeader(size)}`,
              padding: `${size === "lg" ? "0 10px" : "0 20px"}`,
              // fontFamily: "Cinzel Decorative",
              marginBottom: 0,
              textAlign: "center",
              textTransform: "uppercase",
              // fontSize: `${size === "lg" ? "17px" : "22px"}`
              fontSize: `${size === "lg" ? "17px" : "22px"}`
            }}
          >
            Visitor Management Console
          </h1>
          <h1
            className="gx-d-inline-block gx-d-lg-none"
            style={{
              padding: "0 20px",
              // fontFamily: "Cinzel Decorative",
              textTransform: "uppercase",
              fontSize: 15,
              marginBottom: 0
            }}
          >
            Visitor Management Console
          </h1>
          {/* {selectedDorscon.id ? (
            <Badge
              count={selectedDorscon.dorsconName}
              style={{
                width: `${calcWidthForBadge(size)}`,
                color: `${dorsconFontColor[selectedDorscon.dorsconCode]}`,
                height: "25px",
                backgroundColor: `${dorsconColor[selectedDorscon.dorsconCode]}`, //"#52c41a",
                paddingTop: "2px",
                fontSize: "16px"
              }}
            />
          ) : (
            <></>
          )} */}

          <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={aboutMenu()}
              trigger="click"
              title={
                <div className="gx-app-logo">
                  <img
                    alt="example"
                    style={{ width: 212, height: 84 }}
                    src={require("assets/images/logo-powered-by.png")}
                  />
                </div>
              }
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <span className="gx-pl-2 gx-language-name">About</span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li>

          {width >= TAB_SIZE ? null : (
            <Auxiliary>
              <li className="gx-user-nav">
                <UserInfo />
              </li>
            </Auxiliary>
          )}
        </ul>
      </Header>
      <NotificationContainer />
    </Auxiliary>
  );
};

const mapStateToProps = ({ settings, routing, vmcManager }) => {
  const { locale, navStyle, navCollapsed, width } = settings;
  const { location } = routing;
  const { dorsconListST } = vmcManager;
  return { locale, navStyle, navCollapsed, width, location, dorsconListST };
};

export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage,
  getDorsconListAC
})(Topbar);
