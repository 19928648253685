import React, { useRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = props => {
  let refScroll = useRef();

  useEffect(() => {
    refScroll && props.scrolltobottom === "true" && refScroll.scrollToBottom();
  }, [props.children]);

  return (
    <Scrollbars
      ref={scroll => (refScroll = scroll)}
      {...props}
      autoHide
      renderTrackHorizontal={props => (
        <div
          {...props}
          style={{ display: "none" }}
          className="track-horizontal"
        />
      )}
    />
  );
};

export default CustomScrollbars;
