import React, { Component } from "react";
import { getLandingPage } from "services/user.service";

class LandingPage extends Component {
  componentDidMount() {
    getLandingPage().then(landingPage => {
      console.log(landingPage);
      this.props.history.push(landingPage.menuUrl);
    });
  }

  render() {
    return <div>Loading...</div>;
  }
}

export default LandingPage;
