import {
  DB_CO_SURVICE_SUMMARY_REQUEST,
  DB_CO_SURVICE_SUMMARY_SUCCESS,
  DB_CO_SURVICE_SUMMARY_ERROR,
  DB_CO_SURVICE_SUMMARY_CLEAR,
  DB_CO_SURVICE_STATISTICS_REQUEST,
  DB_CO_SURVICE_STATISTICS_SUCCESS,
  DB_CO_SURVICE_STATISTICS_ERROR,
  DB_CO_SURVICE_STATISTICS_CLEAR,
  DB_CO_SURVICE_SUMMARY_TF_REQUEST,
  DB_CO_SURVICE_SUMMARY_TF_SUCCESS,
  DB_CO_SURVICE_SUMMARY_TF_ERROR,
  DB_CO_SURVICE_SUMMARY_TF_CLEAR
} from "constants/ActionTypes";
import gql from "graphql-tag";
import { store } from "../../NextApp";
import GraphqlCli from "util/GraphqlCli";
import constants from "../../constants";
import { CALL_API } from "../../middleware/api";

const apiAction = ({
  path = "dashboardConsole",
  method = "query",
  gql = null,
  variables = {},
  types = []
}) => ({
  [CALL_API]: {
    path: path,
    method: method,
    gql: gql,
    variables: variables,
    types: types
  }
});

export const getServiceSummaryAC = () => {
  const _gql = gql`
    query {
      data: getServiceSummary {
        status
        error {
          message
          stack
        }
        data {
          count
          accessType
        }
      }
    }
  `;
  return apiAction({
    gql: _gql,
    variables: {},
    types: [
      DB_CO_SURVICE_SUMMARY_REQUEST,
      DB_CO_SURVICE_SUMMARY_SUCCESS,
      DB_CO_SURVICE_SUMMARY_ERROR
    ]
  });
};

export const getServiceStatisticsAC = () => {
  const _gql = gql`
    query {
      data: getServiceStatistics {
        status
        error {
          message
          name
        }
        data {
          slotName
          entry
        }
      }
    }
  `;
  return apiAction({
    gql: _gql,
    variables: {},
    types: [
      DB_CO_SURVICE_STATISTICS_REQUEST,
      DB_CO_SURVICE_STATISTICS_SUCCESS,
      DB_CO_SURVICE_STATISTICS_ERROR
    ]
  });
};

export const getServiceSummaryTFAC = () => {
  const _gql = gql`
    query {
      data: getServiceSummaryTableFormat {
        status
        error {
          message
        }
        data {
          serviceName
          waiting
          serving
          closed
          noShow
          avgWaitingTime
          avgServingTime
          avgWaitingTimeForAllSevice
          avgServingTimeForAllSevice
        }
      }
    }
  `;
  return apiAction({
    gql: _gql,
    variables: {},
    types: [
      DB_CO_SURVICE_SUMMARY_TF_REQUEST,
      DB_CO_SURVICE_SUMMARY_TF_SUCCESS,
      DB_CO_SURVICE_SUMMARY_TF_ERROR
    ]
  });
};

export const clearServiceSummaryTFAC = () => ({
  type: DB_CO_SURVICE_SUMMARY_TF_CLEAR
});
