import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Icon } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1599962_z8e4is815te.js" // generated by iconfont.cn
});
class UserInfo extends Component {
  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        {/* <li>My Account</li>
        <li>Connections</li> */}
        <li onClick={() => this.props.userSignOut()}>Logout</li>
      </ul>
    );

    return (
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        {/* <Avatar
          src="https://via.placeholder.com/150x150"
          className="gx-avatar gx-pointer"
          alt=""
        /> */}
        <MyIcon
          type="icon-user"
          style={{ fontSize: "20px", paddingRight: "10px", color: "white" }}
        />
      </Popover>
    );
  }
}

export default connect(null, { userSignOut })(UserInfo);
