import {
  VMC_M_GET_DORSCON_LIST_REQUEST,
  VMC_M_GET_DORSCON_LIST_SUCCESS,
  VMC_M_GET_DORSCON_LIST_ERROR
} from "constants/ActionTypes";
import gql from "graphql-tag";
import { store } from "../../NextApp";
import GraphqlCli from "util/GraphqlCli";
import constants from "../../constants";
import { CALL_API } from "../../middleware/api";

const createFetchErrorPayload = action_name =>
  "( " + action_name + " ) Fetch error.";

const apiAction = ({
  path = "vmcm?hello",
  method = "query",
  gql = null,
  variables = {},
  types = []
}) => ({
  [CALL_API]: {
    path: path,
    method: method,
    gql: gql,
    variables: variables,
    types: types
  }
});

export const getDorsconListAC = () => (dispatch, getState) => {
  const _gql = gql`
    query getDorsconList($locationId: Int) {
      data: getDorsconList(locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          dorsconName
          dorsconDesc
          dorsconCode
          recordStatus
          createdBy
          createdDateTime
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      path: "vmcm?getDorsconList",
      gql: _gql,
      variables: {
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_M_GET_DORSCON_LIST_REQUEST,
        VMC_M_GET_DORSCON_LIST_SUCCESS,
        VMC_M_GET_DORSCON_LIST_ERROR
      ]
    })
  );
};
