import { Icon } from "antd";
import React from "react";

const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1598673_h9h1lf1j0m.js" // generated by iconfont.cn
});

export default function MyIcon2(props) {
  return (
    <MyIcon
      type={"icon-" + props.type}
      onClick={props.onClick}
      style={{ ...props.style }}
    ></MyIcon>
  );
}
