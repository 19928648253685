exports.SUCCESS = "SUCCESS";
exports.ERROR = "ERROR";
exports.PROFILE_VISITOR = 1;
exports.PROFILE_STAFF = 2;
exports.PROFILE_TENANT = 3;
exports.AC_ALLOW_ENTRY = 1;
exports.AC_ALLOW_EXIT = 2;
exports.AC_PERMANENT_OPEN = 3;
exports.AC_PERMANENT_CLOSE = 4;
exports.TFA_MODE_NONE = "NONE";
exports.TFA_MODE_SMS = "SMS";
exports.TFA_MODE_EMAIL = "EMAIL";
exports.SWITCH_CHECKED_COLOR = "#024F7A";

exports.SIO_EVENT= {
    // SUBSCRIBE_FIRE_ALARM_IN = "contractor:subscribe:fireAlarmIn", // by kioskId
    // FIRE_ALARM_IN = "contractor:event:fireAlarmIn", // { message | null }
  
    // Register kiosk to receive events, such as;
    // 1. Fire alarm message
    // 2. Resource change message
    // 3. Scrolling message change
    // ...
    REGISTERED_KIOSK:"KIOSK.REGISTERED_KIOSK",
    // This will trigger to dedicated kiosk only
    // KIOSK.REGISTERED_KIOSK:<<kioskId>> { message }
    FIRE_ALARM_IN: "KIOSK.FIRE_ALARM_IN",
    // This will broadcast to everywhere, io.emit(...)
    // { scrollingMessageId, title, message }
    SCROLLING_MESSAGE_CHANGED:"KIOSK.SCROLLING_MESSAGE_CHANGED",
  
    SCAN_OCR_IN:"KIOSK.SCAN_OCR_IN",
  
    GET_PRINTER_STATUS : "PRINTER.GET_PRINTER_STATUS",
    PRINT : "PRINTER.PRINT",
  }


  exports.REPORT_GEN_DATE_RANGE = process.env.REACT_APP_REPORT_GEN_DATE_RANGE ? parseInt(process.env.REACT_APP_REPORT_GEN_DATE_RANGE,10) :1;

  exports.STAFF_TENANT_EXPIRE_YEAR = process.env.REACT_APP_STAFF_TENANT_EXPIRE_YEAR ? parseInt(process.env.REACT_APP_STAFF_TENANT_EXPIRE_YEAR):2999;

  exports.VISITOR_IGNORE_CARD_TYPE= process.env.REACT_APP_VISITOR_IGNORE_CARD_TYPE ? process.env.REACT_APP_VISITOR_IGNORE_CARD_TYPE : '';
  exports.TENANT_IGNORE_CARD_TYPE= process.env.REACT_APP_TENANT_IGNORE_CARD_TYPE ? process.env.REACT_APP_TENANT_IGNORE_CARD_TYPE : '';
