import {
  DB_CO_SURVICE_SUMMARY_REQUEST,
  DB_CO_SURVICE_SUMMARY_SUCCESS,
  DB_CO_SURVICE_SUMMARY_ERROR,
  DB_CO_SURVICE_SUMMARY_CLEAR,
  DB_CO_SURVICE_STATISTICS_REQUEST,
  DB_CO_SURVICE_STATISTICS_SUCCESS,
  DB_CO_SURVICE_STATISTICS_ERROR,
  DB_CO_SURVICE_STATISTICS_CLEAR,
  DB_CO_SURVICE_SUMMARY_TF_REQUEST,
  DB_CO_SURVICE_SUMMARY_TF_SUCCESS,
  DB_CO_SURVICE_SUMMARY_TF_ERROR,
  DB_CO_SURVICE_SUMMARY_TF_CLEAR
} from "constants/ActionTypes";
import { combineReducers } from "redux";
import fetchData from "./fetchData";

const dashboardConsole = combineReducers({
  serviceSummaryST: fetchData({
    types: [
      DB_CO_SURVICE_SUMMARY_REQUEST,
      DB_CO_SURVICE_SUMMARY_SUCCESS,
      DB_CO_SURVICE_SUMMARY_ERROR,
      DB_CO_SURVICE_SUMMARY_CLEAR
    ]
  }),
  serviceStatisticsST: fetchData({
    types: [
      DB_CO_SURVICE_STATISTICS_REQUEST,
      DB_CO_SURVICE_STATISTICS_SUCCESS,
      DB_CO_SURVICE_STATISTICS_ERROR,
      DB_CO_SURVICE_STATISTICS_CLEAR
    ]
  }),
  serviceSummaryTFST: fetchData({
    types: [
      DB_CO_SURVICE_SUMMARY_TF_REQUEST,
      DB_CO_SURVICE_SUMMARY_TF_SUCCESS,
      DB_CO_SURVICE_SUMMARY_TF_ERROR,
      DB_CO_SURVICE_SUMMARY_TF_CLEAR
    ]
  })
});

export default dashboardConsole;
