import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import GraphqlCli from "./util/GraphqlCli";
import { ApolloProvider } from "@apollo/react-hooks";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";

export const store = configureStore();
window.store = store;

const queryClient = new QueryClient();

const NextApp = () => (
  <QueryClientProvider client={queryClient}>
  <ApolloProvider client={GraphqlCli.getCli()}>  
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
  </ApolloProvider>
  </QueryClientProvider>
);

export default NextApp;
