// xs	<576px and also default setting, could be a span value or an object containing above props	number|object	-
// sm	≥576px, could be a span value or an object containing above props	number|object	-
// md	≥768px, could be a span value or an object containing above props	number|object	-
// lg	≥992px, could be a span value or an object containing above props	number|object	-
// xl	≥1200px, could be a span value or an object containing above props	number|object	-
// xxl	≥1600px, could be a span value or an object containing

const getSizeFromWidth = width => {
  if (width < 576) return "xs";
  if (width >= 1600) return "xxl";
  if (width >= 1200) return "xl";
  if (width >= 992) return "lg";
  if (width >= 768) return "md";
  if (width >= 576) return "sm";
};

export { getSizeFromWidth };
