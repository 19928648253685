import React, { Component } from "react";
import { Button } from "antd";
import FlareComponent from "flare-react";
import failFlare from "assets/flr/fail.flr";

export default class ErrorPage extends Component {
  constructor(props) {
    super(props);
  }

  state = { loaded: false };
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push("/kioskConsole/selectService");
    }, 4000);
  }

  render() {
    const errorCode = this.props.match.params.errorCode;
    let errorMsg = "";
    if (errorCode === "PRN_ERR") {
      errorMsg = (
        <>
          Printer is offline.
          <br />
          Please use another kiosk.
        </>
      );
    } else {
      errorMsg =
        "Oops, unexpected error has occurred. Please approach to reception counter for further assistance.";
    }
    return (
      <div className="cc-kiosk">
        <div className="bg">
          <div className="body">
            {" "}
            <div style={{ marginLeft: "45%" }}>
              {!this.state.loaded && (
                <div style={{ width: 150, height: 155 }}></div>
              )}
              <FlareComponent
                width={150}
                height={150}
                animationName="pop"
                file={failFlare}
                transparent={true}
                onLoadedAnimations={() => this.setState({ loaded: true })}
                //require("assets/flr/success-tick-bin.flr")
              />
            </div>
            <div className="message">{errorMsg}</div>
            {/* <Button
              onClick={() =>
                this.props.history.push("/kioskConsole/selectService")
              }
            >
              Home
            </Button> */}
          </div>
        </div>
      </div>
    );
  }
}
