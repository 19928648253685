import gql from "graphql-tag";
import { authClientSecret } from "util/config";
import GraphqlCli from "util/GraphqlCli";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SELECT_LOCATION,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_OTP_SET,
  USER_TOKEN_SET,
  SELECT_DORSCON,
  SELECT_CONSOLE,
  USER_COMPANY_LIST,
  USER_CARD_TYPE_LIST
} from "../../constants/ActionTypes";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const verifyOTP = ({ userId, otp }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    const _gql = gql`
      query g {
        verifyTFA(userId : "${userId}", otp:"${otp}")
      }
    `;
    GraphqlCli.query("ap/oauth/token", _gql)
      .then(result => {
        var data = result.data;
        dispatch({ type: FETCH_SUCCESS });
        if (data.verifyTFA === "SUCCESS") {
          dispatch({ type: USER_OTP_SET, payload: true });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Invalid OTP." });
          //dispatch({ type: USER_OTP_SET, payload: true });
        }
      })
      .catch(err => {
        //  dispatch({ type: FETCH_ERROR, payload: "OTP error 2" });
        console.log(err);
      });
  };
};

export const userSignIn = ({ userName, password }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    const _gql = gql`mutation g {
      genToken(grantType : PASSWORD, userId:"${userName}", password: "${password}", clientSecret:"${authClientSecret}") {
          code
          accessToken
          refreshToken
          expiresIn
      }
    }`;
    GraphqlCli.mutate("ap/oauth/token", _gql)
      .then(result => {
        var data = result.data.genToken;
        if (data.code === 200) {
          // axios.defaults.headers.common["access-token"] =
          //   "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data });
          dispatch({ type: USER_COMPANY_LIST, payload: data });
          dispatch({ type: USER_CARD_TYPE_LIST, payload: data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: "Invalid user name or password."
          });
        }
      })
      .catch(err => {
        dispatch({ type: FETCH_ERROR, payload: "Auth error" });
        console.log(err);
      });
  };
};

export const getUser = () => {
  return (dispatch, getState) => {
    if (getState().auth.token) {
      dispatch({ type: FETCH_START });
      const _gql = gql`
        query {
          userDetail {
            userId
            userName
            fullName
            avatarFileName
            imageUrl
            preference
            color
            contactNo
            email
            id
            authModeCode
            tfaModeCode
            locations {
              locationId
              locationName
              userRoleId
              userRoleName
              userRoleCode
            }
          }
        }
      `;

      GraphqlCli.query("user", _gql)
        .then(result => {
          var data = result.data.userDetail;
          if (data.userId) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: USER_DATA, payload: data });

            dispatch({ type: SELECT_LOCATION, payload: data.locations[0] }); // top one location as default location
          } else {
            dispatch({ type: FETCH_ERROR, payload: "Fetch error" });
          }
        })
        .catch(err => {
          dispatch({ type: FETCH_ERROR, payload: "Fetch error" });
          console.log(err);
        });
    }
  };
};

export const userSignOut = () => {
  return (dispatch, getState) => {
    if (getState().auth.token) {
      dispatch({ type: FETCH_START });

      let refreshToken = getState().auth.token.refreshToken;
      GraphqlCli.mutate(
        "ap/oauth/token",
        gql`
          mutation g {
            genToken(grantType : INVALIDATE_TOKEN, refreshToken:"${refreshToken}", clientSecret:"${authClientSecret}") {
                code
                accessToken
                refreshToken
                expiresIn
            }
          }
        `
      )
        .then(result => {
          var data = result.data.genToken;
          if (data.code != 200) {
            console.error("INVALIDATE_TOKEN fail");
          }
        })
        .catch(err => {
          console.error("INVALIDATE_TOKEN fail");
        })
        .finally(() => {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER_SUCCESS });
          localStorage.setItem("_logout", new Date()); // to
        });
    } else {
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      localStorage.setItem("_logout", new Date()); // to force logout other tabs
    }
  };
};

export const selectLocation = location => {
  return dispatch => {
    dispatch({ type: SELECT_LOCATION, payload: location });
  };
};

export const setToken = token => {
  return dispatch => {
    dispatch({ type: USER_TOKEN_SET, payload: token });
  };
};

export const selectConsole = console => {
  return dispatch => {
    dispatch({ type: SELECT_CONSOLE, payload: console });
  };
};
