import GraphqlCli from "util/GraphqlCli";
import { SUCCESS, ERROR } from "constants/index";
export const CALL_API = "Call API";
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { path, method, gql, variables, types } = callAPI;

  if (typeof path !== "string") {
    throw new Error("Specify a string path URL.");
  }

  if (!method) {
    // query or mutation
    throw new Error("Specify one of the method.");
  }

  if (typeof method !== "string") {
    // query or mutation
    throw new Error("Expected method to be strings.");
  }

  const queryOrMutate = ["query", "mutate"].includes(method) ? method : "NMF";
  if (queryOrMutate === "NMF") {
    throw new Error(`Expected method to be("query" or "mutate" )`);
  }

  if (!gql) {
    // query or mutation
    throw new Error("Specify one gql.");
  }

  if (variables && typeof variables !== "object") {
    throw new Error("Expected variables to be object.");
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }

  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  return GraphqlCli[method](path, gql, variables)
    .then((result) => {
      var data = result.data.data;
      if (data && data.status === SUCCESS) {
        next(
          actionWith({
            payload: data,
            type: successType,
          })
        );
      } else {
        next(
          actionWith({
            type: failureType,
            payload: data ? data : { error: { message: "No data found!" } },
          })
        );
      }
    })
    .catch((err) => {
      next(
        actionWith({
          type: failureType,
          payload: { error: { message: err.message || "Something wrong" } },
        })
      );
      console.log(err);
    });
};
