import React, { useContext, useState, useEffect } from "react";
// import AppContext from "../../TemplateDetailPage/AppContext";
import ContentEditable from "react-contenteditable";
import { connect } from "react-redux";
import gql from "graphql-tag";
import GraphqlCli from "util/GraphqlCli";
import _ from "lodash";
import { mediaItemUri } from "util/config";
import CrossfadeImage from "react-crossfade-image";
import { forceScrollingMessageChange } from "appRedux/actions/KioskConsole";
import logo from "assets/images/logo.jpg";
import { CSSTransition } from "react-transition-group";

export const Title = ({ children }) => {
  return <div className="title-div">{children}</div>;
};

const mapStateToProps = ({ kioskConsole }) => {
  const { templateSetting } = kioskConsole;
  return { templateSetting };
};

export const ResourceText = connect(
  mapStateToProps,
  {}
)(({ templateSetting, lang = "en", page, k, preview, onResourceChange }) => {
  console.log(`*** #`, templateSetting);
  if (_.isEmpty(templateSetting)) return <>Loading...</>;

  useEffect(() => {
    console.log("$$$ template setting", templateSetting);
  }, [templateSetting]);

  return (
    <>
      {templateSetting === null ? (
        ""
      ) : lang === null ? (
        <></>
      ) : // templateSetting[lang][k]
      preview ? (
        <ContentEditable
          // innerRef={editableRefs.get(key)}
          html={templateSetting[page][lang][k]} // innerHTML of the editable div
          onChange={e => onResourceChange(lang, k, e.target.value)} // handle innerHTML change
          tagName="span" // Use a custom HTML tag (uses a div by default)
          style={{ border: "1px dashed #8e2c5e" }}
          // onBlur={editableBlur}
          // dataKey={k}
        />
      ) : (
        <>{templateSetting[page][lang][k]}</>
      )}
    </>
  );
});

let _mediaPlaylistSub = null;

export const MediaPlaylist = ({
  mediaPlaylistId,
  mediaItems: mediaItemsParam,
  defaultBg,
  style
}) => {
  const [mediaPlaylistCurrentIndex, setMediaPlaylistCurrentIndex] = useState(0);
  const [mediaItems, setMediaItems] = useState(mediaItemsParam);

  useEffect(() => {
    if (_mediaPlaylistSub) {
      _mediaPlaylistSub.unsubscribe();
      _mediaPlaylistSub = null;
    }

    if (mediaPlaylistId) {
      _mediaPlaylistSub = GraphqlCli.subscribe(
        gql`
        subscription {
          mediaPlaylistChanged(mediaPlaylistId: ${mediaPlaylistId}) {
            mediaPlaylistId
            mediaItems {
              duration
              mediaUUID
              mediaExtension
              mediaTypeCode
              playerType
              videoSourceType
            }
          }
        }
      `
      ).subscribe(r => {
        console.log(r);
        // setMediaItems(JSON.parse(r.data.mediaPlaylistChanged.mediaItems));
        setMediaItems(r.data.mediaPlaylistChanged.mediaItems);
        setMediaPlaylistCurrentIndex(0);
      });
    }
    return () => {
      if (_mediaPlaylistSub) {
        _mediaPlaylistSub.unsubscribe();
        _mediaPlaylistSub = null;
      }
    };
  }, [mediaPlaylistId]);

  useEffect(() => {
    setMediaItems(mediaItemsParam);
  }, [mediaItemsParam]);

  return _.isEmpty(mediaItems) || mediaItems.length === 0 ? (
    <div style={style}>
      {Object.is(defaultBg, true) && (
        <img
          src={require("assets/images/logo.jpg")}
          style={{ width: 400, height: 228 }}
        ></img>
        // <div
        //   className="default-media-bg"
        //   style={{
        //     border: "1px solid red",
        //     width: "100%",
        //     height: "100%"
        //   }}
        // ></div>
      )}
    </div>
  ) : (
    <MediaPlayer
      style={style}
      mediaItem={mediaItems[mediaPlaylistCurrentIndex]}
      // use loop attribute, if its single item
      singleLoop={mediaItems.length === 1}
      index={mediaPlaylistCurrentIndex}
      onEnded={index =>
        setMediaPlaylistCurrentIndex(
          mediaItems.length - 1 === index ? 0 : index + 1
        )
      }
    ></MediaPlayer>
  );
};

export const MediaPlayer = ({
  mediaItem,
  index,
  onEnded,
  singleLoop,
  style
}) => {
  // const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    return () => {};
  }, []);

  // const changeImage = () => {
  //   if (imageIndex === images.length - 1) {
  //     setImageIndex(0);
  //   } else {
  //     setImageIndex(imageIndex + 1);
  //   }
  // };
  if (_.isEmpty(mediaItem)) return <></>;

  const src = `${mediaItemUri}\\${mediaItem.mediaUUID}${mediaItem.mediaExtension}`;
  if (mediaItem.playerType === "VID") {
    return (
      <video
        id="video1"
        key={"video" + index}
        // ref="vidRef"
        preload="auto"
        autoPlay
        loop={singleLoop}
        muted // ! ##TEMP
        onEnded={() => !singleLoop && onEnded(index)}
        style={style}
      >
        <source src={src} type={mediaItem.videoSourceType} />
        Your browser does not support the video tag.
      </video>
    );
  } else if (mediaItem.playerType === "IMG") {
    setTimeout(() => onEnded(index), mediaItem.duration * 1000);
    return (
      <div>
        {/* <img src={src}></img> */}
        <CrossfadeImage
          src={src}
          // src={images[imageIndex]}
          // style={{ width: "100%", height: "100%" }}
          style={style}
          duration={1000}
        ></CrossfadeImage>
        {/* <button onClick={() => changeImage()}>Change Image</button> */}
      </div>
    );
  }
  return <></>;
};

let _templateChangedSub = null;
export const subscribeKioskTemplateChanged = onChange => {
  if (_templateChangedSub) {
    _templateChangedSub.unsubscribe();
    _templateChangedSub = null;
  }

  _scrollingMessageSub = GraphqlCli.subscribe(
    gql`
      subscription {
        templateChanged(templateCode: "KI") {
          page
          templateSetting
        }
      }
    `
  ).subscribe(r => {
    console.log(`templateChanged`, r);
    onChange(
      r.data.templateChanged.page,
      r.data.templateChanged.templateSetting
    );
  });
};

let _scrollingMessageSub = null;

export const ScrollingMessage = props => {
  useEffect(() => {
    if (props.id !== null) {
      if (_scrollingMessageSub) {
        _scrollingMessageSub.unsubscribe();
        _scrollingMessageSub = null;
      }
      console.log(`scrollingMessageChanged sub`, props.id);
      _scrollingMessageSub = GraphqlCli.subscribe(
        gql`
        subscription {
          scrollingMessageChanged(scrollingMessageId: ${props.id}) {
            scrollingMessageId
            message
          }
        }
      `
      ).subscribe(r => {
        console.log(`scrollingMessageChanged`, r);
        // setMessage(r.data.scrollingMessageChanged.message);
        props.hasChanges &&
          props.hasChanges(
            r.data.scrollingMessageChanged.scrollingMessageId,
            r.data.scrollingMessageChanged.message
          );
      });
    }
  }, [props.id]);

  return (
    <marquee
      width="100%"
      style={{ fontSize: 25, marginTop: 8, color: "#000000d6" }}
      direction="left"
    >
      {props.scrollingMessage}
    </marquee>
  );
};

/**
 * this Footer is use for preview only
 */
export const Footer = connect(
  ({ kioskConsole }) => {
    const { scrollingMessage } = kioskConsole;
    return { scrollingMessage };
  },
  { forceScrollingMessageChange }
)(({ scrollingMessage, forceScrollingMessageChange, bottom }) => {
  return (
    // <div className="cc-kiosk" style={{ width: 800 }}>
    <div className="footer" style={{ bottom }}>
      <div style={{ position: "absolute", left: 10, width: 630 }}>
        {scrollingMessage && (
          <ScrollingMessage
            // hasChanges={(id, message) =>
            //   forceScrollingMessageChange(id, message)
            // }
            {...scrollingMessage}
          ></ScrollingMessage>
        )}
      </div>
      <div style={{ position: "absolute", right: 20 }}>
        <img
          alt="example"
          style={{ height: 50 }}
          src={require("assets/images/logo-powered-by.png")}
        />
      </div>
    </div>
    // </div>
  );
});

let subEmergencyMessageALert = null;
export const EmergencyMessageAlert = ({ deviceId }) => {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (subEmergencyMessageALert) {
      subEmergencyMessageALert.unsubscribe();
      subEmergencyMessageALert = null;
    }
    subEmergencyMessageALert = GraphqlCli.subscribe(
      gql`
          subscription {
            emergencyMessageIn(deviceId: ${deviceId}) {
              message
            }
          }
        `
    ).subscribe(r => {
      console.log(r);
      setMessage(r.data.emergencyMessageIn.message);
    });
    return () => {};
  }, []);

  return (
    <CSSTransition
      in={message !== null}
      timeout={600}
      classNames={"cc-pop"}
      unmountOnExit
    >
      <div
        style={{
          border: "1px solid #E70037",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          background: "#E70037",
          zIndex: 2
        }}
      >
        <div style={{ height: 100, background: "rgba(255, 255, 255, 0.2)" }}>
          <div style={{ align: "center", textAlign: "center" }}>
            <img
              style={{ height: 80 }}
              src={require("assets/images/alert_sign.png")}
            ></img>
            <span style={{ fontSize: 20, fontWeight: 500 }}>
              Emergency Alert
            </span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ fontSize: 20, fontWeight: 500 }}>
            {message &&
              message.split("\n").map((m, i) => (
                <div
                  key={`emergencyMsg_${i}`}
                  style={{ textAlign: "center", lineHeight: 2 }}
                >
                  {m}
                </div>
              ))}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
