import axios from "axios";
import { emailServiceUrl } from "./config";

const http = axios.create({
  baseURL: emailServiceUrl,
  timeout: 17000,
  headers: { "Content-Type": "application/json" }
});

http.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token)
      config.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("token")).accessToken
      }`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default http;
