import GraphqlCli from "util/GraphqlCli";
import gql from "graphql-tag";
import _ from "lodash";

const _gql = gql`
  query {
    menuInfo {
      menuCode
      menuDesc
      menuUrl
      menuIconClass
      groupIconClass
      groupDesc
      groupDisplayFlag
      groupCode
      isLandingPage
      modeCode
      locationId
    }
  }
`;

export const getLandingPage = async () => {
  let menuInfo = (await GraphqlCli.query("user?getMenuInfo", _gql)).data
    .menuInfo;
  const landingPage = menuInfo.filter(m => m.isLandingPage === 1);
  return landingPage.length > 0 ? landingPage[0] : menuInfo[0];
};

export const getMenuInfo = async () => {
  let menuInfo = (await GraphqlCli.query("user?getMenuInfo", _gql)).data
    .menuInfo;
  var menuInfoByGroup = [];

  var prevGroupCode = "";
  var prevGroupLocationId = 0;
  menuInfo.forEach((menu, i) => {
    if (
      menu.groupCode !== prevGroupCode ||
      menu.locationId !== prevGroupLocationId
    ) {
      menuInfoByGroup.push({
        groupCode: menu.groupCode,
        groupDisplayFlag: menu.groupDisplayFlag,
        groupDesc: menu.groupDesc,
        groupIconClass: menu.groupIconClass,
        // groupIconClassType: menu.groupIconClassType,
        modeCode: menu.modeCode,
        locationId: menu.locationId,
        menuItems: []
      });
      prevGroupCode = menu.groupCode;
      prevGroupLocationId = menu.locationId;
    }
    menuInfoByGroup[menuInfoByGroup.length - 1].menuItems.push({
      menuCode: menu.menuCode,
      menuDesc: menu.menuDesc,
      menuUrl: menu.menuUrl,
      menuIconClass: menu.menuIconClass
    });
  });

  return menuInfoByGroup;
};
