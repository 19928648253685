import {
  F_KIOSK_SERVICE_LIST,
  F_LAUNCH_KIOSK_CONSOLE_ID,
  F_KIOSK_CONSOLE_INFO,
  F_KIOSK_SCROLLING_MESSAGE,
  F_KIOSK_MEDIA_PLAYLIST,
  F_KIOSK_TEMPLATE_SETTING
} from "../../constants/ActionTypes";

const getLS = (key, defaultValue) =>
  localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : defaultValue;

const getSS = (key, defaultValue) => {
  return sessionStorage.getItem(key)
    ? JSON.parse(sessionStorage.getItem(key))
    : defaultValue;
};

const INIT_STATE = {
  serviceList: [],
  launchConsoleId: getSS("launchKioskConsoleId", null),
  consoleInfo: {},
  mediaPlaylist: getSS("kioskConsole.mediaPlaylist", {}),
  scrollingMessage: getSS("kioskConsole.scrollingMessage", {}),
  templateSetting: getSS("kioskConsole.templateSetting", {})
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case F_LAUNCH_KIOSK_CONSOLE_ID: {
      sessionStorage.setItem("launchKioskConsoleId", action.payload);
      return { ...state, launchConsoleId: action.payload };
    }

    case F_KIOSK_SERVICE_LIST: {
      return { ...state, serviceList: action.payload };
    }

    case F_KIOSK_CONSOLE_INFO: {
      return { ...state, consoleInfo: action.payload };
    }

    case F_KIOSK_SCROLLING_MESSAGE: {
      localStorage.setItem(
        "kioskConsole.scrollingMessage",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );
      return { ...state, scrollingMessage: action.payload };
    }

    case F_KIOSK_MEDIA_PLAYLIST: {
      localStorage.setItem(
        "kioskConsole.mediaPlaylist",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );
      return { ...state, mediaPlaylist: action.payload };
    }

    case F_KIOSK_TEMPLATE_SETTING: {
      localStorage.setItem(
        "kioskConsole.templateSetting",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );
      return { ...state, templateSetting: action.payload };
    }

    default:
      return state;
  }
};
