import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { MobileView, DefaultView } from "util/MediaQueryWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import videoBgMp4 from "assets/videos/video-bg-720p.mp4";

const FormItem = Form.Item;

class SignIn extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        localStorage.setItem("tempUserName", values.userName);
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push("/");
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-bg-video">
          <video className="gx-app-login-bg-video__content" autoPlay muted loop>
            <source src={videoBgMp4} type="video/mp4" />
            {/* <source src={videoBgWebM} type="video/webm" /> */}
            Your browser is not supported!
          </video>
        </div>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <DefaultView>
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    bottom: 10,
                    background: "#9e9797c2",
                    height: 90,
                    width: "100%",
                    textAlign: "center"
                  }}
                >
                  <div>
                    <img
                      alt="71 robinson"
                      style={{ height: 90 , width: 150 }}
                      src={require("assets/images/71ROBINSON_LOGO.png")}
                    />
                  </div>
                </div>
              </DefaultView>
              <div className="gx-app-logo-content-bg">
                <img
                  src={require("assets/images/Data_center_in_the_dark.jpg")}
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signIn" />
                </h1>
                {/* <p>
                  <IntlMessages id="app.userAuth.bySigning" />
                </p>
                <p>
                  <IntlMessages id="app.userAuth.getAccount" />
                </p> */}
              </div>
              {/* <div className="gx-app-logo" >
                <img alt="example" src={require("assets/images/logo.png")} />
              </div> */}
            </div>
            <div className="gx-app-login-content">
              <div style={{ paddingBottom: 15 }}>
                <span
                  style={{
                    paddingBottom: 20,
                    // fontFamily: "Cinzel Decorative",
                    marginBottom: 0,
                    fontSize: 20,
                    textAlign: "center",
                    textTransform: "uppercase"
                  }}
                >
                  {"Visitor Management Console"}
                  <br />
                </span>
              </div>
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("userName", {
                    initialValue: "", // demo@example.com
                    rules: [
                      {
                        required: true,
                        message: "Please input your User Name!"
                      }
                    ]
                  })(<Input placeholder="User Name" autocomplete="off" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    initialValue: "", // demo#123
                    rules: [
                      { required: true, message: "Please input your Password!" }
                    ]
                  })(<Input type="password" placeholder="Password" />)}
                </FormItem>
                {/* <FormItem>
                  {getFieldDecorator("remember", {
                    valuePropName: "checked",
                    initialValue: true
                  })(
                    <Checkbox>
                      <IntlMessages id="appModule.iAccept" />
                    </Checkbox>
                  )}
                  <span className="gx-signup-form-forgot gx-link">
                    <IntlMessages id="appModule.termAndCondition" />
                  </span>
                </FormItem> */}
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  {/* <span>
                    <IntlMessages id="app.userAuth.or" />
                  </span>{" "}
                  <Link to="/signup">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Link> */}
                </FormItem>

                <div
                  className="gx-text-light gx-fs-sm"
                  style={{
                    textAlign: "right",
                    display: "block",
                    widht: "100%"
                  }}
                >
                  {" "}
                  Powered by CentriCore © {new Date().getFullYear()}  , Version{" "}
                  {process.env.REACT_APP_SYS_VERSION}{" "}
                </div>
                <div
                  className="gx-text-light gx-fs-sm"
                  style={{
                    textAlign: "right",
                    display: "block",
                    widht: "100%"
                  }}
                >
                  {" "}
                  Get to know more about us:{" "}
                  <a
                    href="https://www.linkedin.com/company/centricore-s-pte-ltd"
                    alt="Centricore at Linkedin"
                    title="Centricore at Linkedin"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={["fab", "linkedin"]}
                      style={{ fontSize: "18px", color: "#FFF" }}
                    />
                  </a>
                  &nbsp;
                  <a
                    href="https://www.facebook.com/Centricore-Pte-Ltd-106556751060631/"
                    alt="Centricore at Facebook"
                    title="Centricore at Facebook"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={["fab", "facebook-square"]}
                      style={{ fontSize: "18px", color: "#FFF" }}
                    />
                  </a>
                </div>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, { userSignIn })(WrappedNormalLoginForm);
