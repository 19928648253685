import { SUCCESS, ERROR } from "constants/index";

const fetchData = ({ types }) => {
  if (!Array.isArray(types) || types.length !== 4) {
    throw new Error("Expected types to be an array of four elements.");
  }

  if (!types.every(t => typeof t === "string")) {
    throw new Error("Expected types to be strings.");
  }

  const [requestType, successType, failureType, clearType] = types;

  const updateFetchData = (
    state = {
      isFetching: false,
      data: [],
      status: null,
      message: null
    },
    action
  ) => {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          isFetching: true
        };
      case successType:
        return {
          ...state,
          isFetching: false,
          data: action.payload.data,
          status: SUCCESS,
          message: null
        };
      case failureType:
        return {
          ...state,
          isFetching: false,
          data: [],
          status: ERROR,
          message: action.payload.error.message
        };
      case clearType:
        return {
          ...state,
          isFetching: false,
          data: [],
          status: SUCCESS,
          message: null
        };
      default:
        return state;
    }
  };

  return (state = {}, action) => {
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
      case clearType:
        return {
          ...state,
          ...updateFetchData(state, action)
        };
      default:
        return state;
    }
  };
};

export default fetchData;
