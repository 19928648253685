import {
  F_KIOSK_SERVICE_LIST,
  F_KIOSK_CONSOLE_INFO,
  F_KIOSK_SCROLLING_MESSAGE,
  F_KIOSK_MEDIA_PLAYLIST,
  F_KIOSK_TEMPLATE_SETTING
} from "../../constants/ActionTypes";
import GraphqlCli from "util/GraphqlCli";
import axios from "util/Api";
import { authClientSecret } from "util/config";
import gql from "graphql-tag";
import { store } from "NextApp";
import _ from "lodash";
import yaml from "js-yaml";

export const getTemplates = async templateCode => {
  const _gql = gql`
    query {
      templates: getTemplates(templateCode:"${templateCode}") {
        status
        data {
          templateCode
          templateSetting
        }
      }
    }
  `;

  let result = (await GraphqlCli.query("template?getTemplates", _gql)).data
    .templates.data;

  return result;
};

export const RESOURCE_TYPE = {
  SCROLLING_MESSAGE: "SCROLLING_MESSAGE",
  PLAYLIST: "PLAYLIST",
  TEMPLATE: "TEMPLATE"
};

const getResoruceBySchedule = (consoleInfo, rsourceType, state, serverTime) => {
  let arr = [];
  let oldId = -1;
  let key = "";
  if (RESOURCE_TYPE.SCROLLING_MESSAGE === rsourceType) {
    arr = consoleInfo.scrollingMessageBySchedules;
    oldId = state.kioskConsole.scrollingMessage.id;
    key = F_KIOSK_SCROLLING_MESSAGE;
  } else if (RESOURCE_TYPE.PLAYLIST === rsourceType) {
    arr = consoleInfo.mediaPlaylistBySchedules;
    oldId = state.kioskConsole.mediaPlaylist.id;
    key = F_KIOSK_MEDIA_PLAYLIST;
  }
  console.log(`rsourceType`, rsourceType);
  if (arr === null) return null;
  for (let i = 0; i < arr.length; i++) {
    const serverTimeInt = parseInt(serverTime.split(":").join(""), 10); // remove semicolon
    // console.log(`serverTimeInt`, serverTimeInt);
    if (
      serverTimeInt >= parseInt(arr[i].scheduleAt, 10) &&
      (_.isEmpty(arr[i + 1]) ||
        (!_.isEmpty(arr[i + 1]) &&
          serverTimeInt < parseInt(parseInt(arr[i].scheduleAt, 10))))
    ) {
      // compoare current scheduleAt and next scheduleAt
      // console.log(`oldId2`, arr[i].id, oldId);
      if (arr[i].id !== oldId) {
        return { type: key, payload: { ...arr[i] } };
      } else {
        // same id, no need to do anything
        return null;
      }
    }
  }
  // if the time is ealier than the schedule time, take the first time slot
  return arr.length > 0 && oldId !== arr[0].id
    ? { type: key, payload: { ...arr[0] } }
    : null;
};

export const fixResourceBySchedule = serverTime => {
  return async (dispatch, getState) => {
    // console.log(`fixResourceBySchedule2`, serverTime);
    const { consoleInfo } = getState().kioskConsole;
    if (_.isEmpty(consoleInfo)) {
      // if its not sign, ignore this function.
      return;
    }
    const scrollingMessageDispatch = getResoruceBySchedule(
      consoleInfo,
      RESOURCE_TYPE.SCROLLING_MESSAGE,
      getState(),
      serverTime
    );
    // console.log(`scrollingMessageDispatch`, scrollingMessageDispatch);
    scrollingMessageDispatch && dispatch(scrollingMessageDispatch);

    const mediaPlaylistDispatch = getResoruceBySchedule(
      consoleInfo,
      RESOURCE_TYPE.PLAYLIST,
      getState(),
      serverTime
    );
    mediaPlaylistDispatch && dispatch(mediaPlaylistDispatch);
  };
};

export const getMediaPlaylists = async playlistId => {
  const _gql = gql`
    query {
      mediaPlaylists: getMediaPlaylists(playlistId: ${playlistId}) {
        status
        data {
          mediaItems {
            mediaItemId
            mediaName
            mediaUUID
            mediaTypeId
            mediaTypeName
            mediaTypeCode
            playerType
            videoSourceType
            duration
            createdDt
            mediaExtension
          }
        }
      }
    }
  `;

  let result = (await GraphqlCli.query("kiosk?mediaPlaylists", _gql, {})).data
    .mediaPlaylists.data.mediaItems;

  return result;
};

export const getKioskDevice = () => {
  return async dispatch => {
    const { launchConsoleId } = store.getState().kioskConsole;
    console.log(`launchConsoleId`, launchConsoleId);
    let _gql = gql`
      query GetKioskDevice($deviceId: Int) {
        serverTime: getServerTime(format: "HH:mm")
        kioskDevice: getKioskDevice(deviceId: $deviceId) {
          data {
            id
            locationId
            scrollingMessageBySchedules {
              scheduleAt
              id
              scrollingMessage
            }
            mediaPlaylistBySchedules {
              scheduleAt
              id
              mediaItems {
                duration
                mediaUUID
                mediaTypeCode
                playerType
                videoSourceType
                mediaExtension
              }
            }
          }
        }
      }
    `;
    let result = (
      await GraphqlCli.query(
        "kiosk?getKioskDevice",
        _gql,
        { deviceId: launchConsoleId }
        // "cache-first"
      )
    ).data;

    console.log(`launchConsoleId2`, result);

    const consoleInfo = result.kioskDevice.data;
    getTemplates("KI").then(templates => {
      // convert [{templateCode:"KI.HOME", ...},{templateCode:"KI.HOME", ...} ] -> {HOME:{...}, SCAN:{...}}
      templates = templates.reduce((acc, cur) => {
        const templateCode = cur.templateCode.split(".")[1];
        acc[templateCode] = yaml.safeLoad(cur.templateSetting);
        return acc;
      }, {});

      dispatch({ type: F_KIOSK_TEMPLATE_SETTING, payload: templates });
      dispatch({ type: F_KIOSK_CONSOLE_INFO, payload: consoleInfo });

      // force to ignore oldId check, for the first load.
      dispatch({ type: F_KIOSK_MEDIA_PLAYLIST, payload: {} });
      dispatch({ type: F_KIOSK_SCROLLING_MESSAGE, payload: {} });

      const serverTime = result.serverTime;
      // console.log(`serverTime: ${serverTime}`, result);
      dispatch(fixResourceBySchedule(serverTime));
    });
  };
};

export const updateTemplate = templateSetting => {
  return async (dispatch, getState) => {
    dispatch({ type: F_KIOSK_TEMPLATE_SETTING, payload: templateSetting });
  };
};

export const updateTemplateForPage = (page, template) => {
  return async (dispatch, getState) => {
    const { templateSetting } = getState().kioskConsole;
    templateSetting[page] = template;

    console.log(templateSetting, getState().kioskConsole.templateSetting);
    dispatch({
      type: F_KIOSK_TEMPLATE_SETTING,
      payload: { ...templateSetting }
    });
  };
};

export const getServiceList = () => {
  return async (dispatch, getState) => {
    const { launchConsoleId } = getState().kioskConsole;

    // let _gql = gql`
    //   query GetServiceList($consoleId: Int) {
    //     serviceList: getServiceList(consoleId: $consoleId) {
    //       status
    //       error {
    //         message
    //       }
    //       data {
    //         serviceId
    //         serviceName
    //       }
    //     }
    //   }
    // `;

    // let serviceList = (
    //   await GraphqlCli.query(
    //     "kioskConsole?getServiceList",
    //     _gql,
    //     {
    //       consoleId: launchConsoleId
    //     },
    //     "cache-first"
    //   )
    // ).data.serviceList.data;

    // dispatch({ type: F_KIOSK_SERVICE_LIST, payload: serviceList });
    dispatch({
      type: F_KIOSK_SERVICE_LIST,
      payload: [
        { serviceId: 1, serviceName: "Service A" },
        { serviceId: 2, serviceName: "Service B" },
        { serviceId: 3, serviceName: "Service C" }
      ]
    });
  };
};

export const forceScrollingMessageChange = (id, scrollingMessage) => {
  return async (dispatch, getState) => {
    const scrollingMessageNew = { ...getState().kioskConsole.scrollingMessage };
    scrollingMessageNew.id = id;
    scrollingMessageNew.scrollingMessage = scrollingMessage;
    dispatch({ type: F_KIOSK_SCROLLING_MESSAGE, payload: scrollingMessageNew });
  };
};
