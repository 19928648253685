import {
  C_IS_CHAT_PANEL_OPEN,
  C_CHAT_ADDRESSES,
  C_SELCTED_CHAT_ADDRESS,
  C_CHAT_MESSAGES,
  C_ADD_CHAT_MESSAGE,
  C_CHAT_UNREADS
} from "../../constants/ActionTypes";
import GraphqlCli from "util/GraphqlCli";
import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";

export const openChatPanel = () => {
  return async dispatch => {
    dispatch({ type: C_IS_CHAT_PANEL_OPEN, payload: true });
  };
};

export const closeChatPanel = () => {
  return async dispatch => {
    dispatch({ type: C_SELCTED_CHAT_ADDRESS, payload: {} });
    dispatch({ type: C_IS_CHAT_PANEL_OPEN, payload: false });
  };
};

export const getGroupChatAddresses = async groupId => {
  const result = await GraphqlCli.query(
    `chat?getGroupChatAddresses`,
    gql`
        query {
          groupChatAddresses: getGroupChatAddresses(groupId: ${groupId}) {
            status
            data {
              fullName
              photoUUID
            }
          }
        }
      `
  );
  return result.data.groupChatAddresses.data;
};

/**
 * selectChatAddress will cause this
 * isChatPanelOpen set to true, will cause this
 *
 * if selectedChatAddress is empty, will do nothing.
 * if checkUnreadFlag is true, and fomulated unreadKey is not in chatUnreads, will do nothing.
 *
 * @param checkUnreadFlag
 *  this will be false when message is in for engaging conversaion
 *  system will not be putting unreadKey to chatUnreads, hence, need to mark as read at server silently.
 */
export const markChatRead = (checkUnreadFlag = true) => {
  return async (dispatch, getState) => {
    const state = getState();

    console.log(`markChatRead2`, state.chat.selectedChatAddress);

    if (_.isEmpty(state.chat.selectedChatAddress)) {
      console.log(`markChatRead, rejected, no conversaion`);
      return;
    }

    const { id } = state.auth.authUser;
    const { userOrGroup, addressId } = state.chat.selectedChatAddress;
    const unreadKey = `${id}_${userOrGroup}_${addressId}`;

    if (checkUnreadFlag && state.chat.chatUnreads.indexOf(unreadKey) < 0) {
      console.log(`markChatRead, rejected, no chatUnreads`);
      return;
    }

    const _gql = gql`
          mutation {
            status: markChatRead(unreadKey:"${unreadKey}") 
          }
        `;

    let status = (await GraphqlCli.mutate("chat?markChatRead", _gql)).data
      .status;

    if (status === "OK") {
      const chatUnreadsNew = state.chat.chatUnreads.filter(
        o => o !== unreadKey
      );
      dispatch({ type: C_CHAT_UNREADS, payload: chatUnreadsNew });
    }
  };
};

export const addChatMessage = chatMessage => {
  return async (dispatch, getState) => {
    console.log(`chatMessageIn2`, chatMessage);
    const { selectedChatAddress, isChatPanelOpen } = getState().chat;
    console.log(
      `chatMessageIn2.0`,
      chatMessage.sentToAddressId === selectedChatAddress.addressId,
      chatMessage.sentToUserOrGroup === selectedChatAddress.userOrGroup
    );

    // myUserId: 2

    // selectedChatAddress
    //    addressId(pin):3
    //    userOrGroup(pin):0
    // chatMessage
    //    sentByUserId: 2
    //    sentToAddressId: 3
    //    sentToUserOrGroup: 0
    const sentToOther =
      chatMessage.sentToAddressId === selectedChatAddress.addressId &&
      chatMessage.sentToUserOrGroup === selectedChatAddress.userOrGroup;

    // myUserId: 2

    // selectedChatAddress
    //    addressId(pin):3
    //    userOrGroup(pin):0
    // chatMessage
    //    sentByUserId: 3
    //    sentToAddressId: 2
    //    sentToUserOrGroup: 0
    const sentFromOther =
      chatMessage.sentByUserId === selectedChatAddress.addressId &&
      chatMessage.sentToUserOrGroup === selectedChatAddress.userOrGroup;

    if (sentToOther || sentFromOther) {
      // for selected conversaion
      dispatch({ type: C_ADD_CHAT_MESSAGE, payload: chatMessage });
      if (!isChatPanelOpen) {
        // for selected conversaion, but chat windows is closed
        dispatch(getChatUnreads());
      } else {
        // for selected conversaion, chat window open and engaging
        dispatch(markChatRead(false /* checkUnreadFlag */));
      }
    } else {
      // not for selected coversation or no conversation is selected
      dispatch(getChatUnreads());
    }
  };
};

export const getChatUnreads = () => {
  return async (dispatch, getState) => {
    const _gql = gql`
      query {
        chatUnreads: getChatUnreads
      }
    `;

    let chatUnreads = (await GraphqlCli.query("chat?getChatUnreads", _gql)).data
      .chatUnreads;

    dispatch({ type: C_CHAT_UNREADS, payload: chatUnreads });
  };
};

export const getChatMessages = () => {
  return async (dispatch, getState) => {
    const { selectedChatAddress } = getState().chat;
    const _gql = gql`
        query  {
          chatMessages: getChatMessages(offset: 0, chatAddress: {userOrGroup:${selectedChatAddress.userOrGroup}, addressId: ${selectedChatAddress.addressId}} ) {
            status
            data {
              id
              sentByUserId
              sentByDisplayName
              sentByPhotoUUID
              sentByShortCode
              chatMessage
              sentDt
            }
          }
        }
      `;

    let chatMessages = (await GraphqlCli.mutate("chat?getChatMessages", _gql))
      .data.chatMessages.data;

    // group by same minute from same person consecutively
    const chatMessagesInGroup = [];
    let x = -1;
    let prevRec = null;
    chatMessages.forEach(cm => {
      const curRec = `${cm.sentByUserId}_${moment(new Date(cm.sentDt)).format(
        "DDMMYYYYHHmm"
      )}`;
      if (curRec !== prevRec) {
        // diff
        chatMessagesInGroup[++x] = cm;
        prevRec = curRec;
      } else {
        // same
        chatMessagesInGroup[x].chatMessage += "\n" + cm.chatMessage;
      }
    });

    dispatch({ type: C_CHAT_MESSAGES, payload: chatMessagesInGroup });
  };
};

export const sendChat = chatMessage => {
  return async (dispatch, getState) => {
    console.log(`sendChat1`, chatMessage);
    const { selectedChatAddress } = getState().chat;
    const _gql = gql`
       mutation {
        result: sendChat(sentToChatAddress:{addressId:${selectedChatAddress.addressId}, userOrGroup: ${selectedChatAddress.userOrGroup}}, chatMessage:"${chatMessage}") {
          status
          error {
            code
          }
          data
        }
      }
      `;

    let status = (await GraphqlCli.mutate("chat?sendChat", _gql)).data.result
      .status;

    console.log(`status`, status);
  };
};

export const selectChatAddress = address => {
  return async (dispatch, getState) => {
    console.log(`C_SELCTED_CHAT_ADDRESS`, address);
    dispatch({ type: C_SELCTED_CHAT_ADDRESS, payload: address });
    dispatch(getChatMessages());
    dispatch(markChatRead());
  };
};

export const getChatAddresses = () => {
  return async dispatch => {
    const _gql = gql`
      query {
        chatAddresses: getChatAddresses {
          status
          data {
            addressId
            userOrGroup
            displayName
            shortCode
            photoUUID
          }
        }
      }
    `;

    let chatAddresses = (await GraphqlCli.mutate("chat?getChatAddresses", _gql))
      .data.chatAddresses.data;

    dispatch({ type: C_CHAT_ADDRESSES, payload: chatAddresses });
  };
};
