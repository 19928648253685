import axios from "axios";
import { masterQuotaUrl } from "./config";

console.log(masterQuotaUrl);

const CancelToken = axios.CancelToken;
const AxioSource = CancelToken.source();

const axioApi = axios.create({
  baseURL: masterQuotaUrl,
  timeout: 17000,
  headers: { "Content-Type": "application/json" },
  cancelToken: AxioSource.token,
});

axioApi.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token)
      config.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("token")).accessToken
      }`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export { axioApi , AxioSource , axios };
