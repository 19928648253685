import {
  C_IS_CHAT_PANEL_OPEN,
  C_CHAT_ADDRESSES,
  C_SELCTED_CHAT_ADDRESS,
  C_CHAT_MESSAGES,
  C_ADD_CHAT_MESSAGE,
  C_CHAT_UNREADS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  isChatPanelOpen: false,
  chatAddresses: [],
  selectedChatAddress: {},
  chatMessages: [],
  chatUnreads: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case C_IS_CHAT_PANEL_OPEN: {
      return { ...state, isChatPanelOpen: action.payload };
    }

    case C_CHAT_ADDRESSES: {
      return { ...state, chatAddresses: action.payload };
    }

    case C_SELCTED_CHAT_ADDRESS: {
      return { ...state, selectedChatAddress: action.payload };
    }

    case C_CHAT_MESSAGES: {
      return { ...state, chatMessages: action.payload };
    }

    case C_CHAT_UNREADS: {
      return { ...state, chatUnreads: action.payload };
    }

    case C_ADD_CHAT_MESSAGE: {
      console.log(`chatMessageIn3`, action.payload);
      let chatMessagesNew = [...state.chatMessages];
      if (
        chatMessagesNew.filter(
          chatMessage => chatMessage.id === action.payload.id
        ).length === 0
      ) {
        chatMessagesNew.push(action.payload);
      }
      console.log(`chatMessageIn4`, chatMessagesNew);
      return { ...state, chatMessages: chatMessagesNew };
    }

    default:
      return state;
  }
};
