import React, { useState } from "react";
import { store } from "../NextApp";
import { F_LAUNCH_KIOSK_CONSOLE_ID } from "../constants/ActionTypes";
import { Redirect } from "react-router-dom";

/**
 * For flow, this will be used to launch the specific terminal.
 * @param {*} props
 */
export default function LaunchKioskConsole(props) {
  let launchConsoleId = parseInt(props.match.params.consoleId);
  let psCheck = parseInt(props.match.params.psCheck);
  console.log(launchConsoleId);
  sessionStorage.setItem("__BY_PASS__", true);
  localStorage.setItem("__kio_printer_service_check", psCheck);
  store.dispatch({ type: F_LAUNCH_KIOSK_CONSOLE_ID, payload: launchConsoleId });
  return (
    <Redirect
      to={{
        pathname: "/kioskConsole/selectService",
        state: { from: props.location }
      }}
    />
  );
}
