import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";

const getUseMutation=(query,path)=>{
  const [
    mutation,
    {
      data,
      loading,
      error
    }
  ] = useMutation(query, {
    context: { path: path}
  });
  return {
      mutation,
      data,
      loading,
      error
    };
  
}

const getUseQuery=(query,path,variables,fetchPolicy)=>{
    return useQuery(query, {
        context: {
          path: path
        },
        variables: variables,
        fetchPolicy: fetchPolicy
      });
}

const getUseLazyQuery=(query,path,fetchPolicy)=>{
  const [
    lazyQuery,
    { loading, error, data }
  ] = useLazyQuery(query, {
    context: {
      path: path
    },
    fetchPolicy: fetchPolicy
  });

  return {lazyQuery,loading,error,data};
}



export {
    getUseQuery,
    getUseLazyQuery,
    getUseMutation
}