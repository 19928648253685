import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { Button, Icon, Input } from "antd";
import GraphqlCli from "util/GraphqlCli";
import { axioApi, AxioSource , axios } from "../util/MasterQuotaApi";

const resultStatus ={
  SUCCESS:"OK",
  ERROR:"ER",
  LOCKED:"LK",
  INVALID_QUOTA:"IQ",
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    // want to keep function in the muitable  closure variable and can change callback function eventhoud setInterval not resect
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // want to put in the error function because when setInterval don't want to run current() immediate,
    // wanted to cal only interval time.
    const tick = () => {
      savedCallback.current();
    };
    console.log("create ::: useInterval ", delay);
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
        console.log("Clear Interval useInterval ", id);
      };
    }
  }, [delay]);
};

const useCustomSubscription = (query, variables) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const setUpdatedData = data_ => {
      console.log(`setUpdatedData`, data_);
      setData(data_);
    };

    let subscribeObj = GraphqlCli.subscribe(query, variables).subscribe(r => {
      console.log(`chatMessageIn1`, r);
      setUpdatedData(r.data);
    });

    return () => subscribeObj.unsubscribe();
  }, []);

  return { data };
};

const AddCharWithColor = props => {
  return (
    <>
      &nbsp;{"{"}
      <span style={{ color: props.color }}>{props.char}</span>
      {"}"}
    </>
  );
};

const userColumnSearchProps = (dataIndex, placeholder) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const refSearchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSearchReset = clearFilters => {
    clearFilters();
    setSearchText("");
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => (refSearchInput.current = node)}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8, marginBottom: 3 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleSearchReset(clearFilters)}
          size="small"
          style={{ width: 90, marginBottom: 3 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      console.log("visible", visible);
      if (visible) {
        setTimeout(() => refSearchInput.current.focus(), 100);
      }
    },
    render: text =>
      text && searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  };
};


// const data = {
//   to: toEmail,
//   qrcode: passNo,
//   regdate: regDate.format("YYYY-MM-DD HH:mm:ss")
// };
// try {
//   const result = await axios.post("/sendMail", data);
//   console.dir(result);
//   if (result.data.status === SUCCESS) {
//     return true;
//   } else {
//     return false;
//   }
// } catch (error) {
//   return false;
// }

const useMasterQuota=(props)=>{
  const {url} = props;
  const [data,setData]=useState([]);
  const [error,setError]=useState(null);
  const [loading,setLoading]=useState(false);

  useEffect(()=>{

   return ()=>{
    // clear all when object remove from the page
    if(loading) AxioSource.cancel('Request canceled by user');
   } 
  },[]);

  const lazyQuery=async (body)=>{
      try {
        setLoading(true);
        const result = await axioApi.post(`${url}`, body?body:{});
        setLoading(false);
        if (result.data.status === resultStatus.SUCCESS) {
          setData(result.data.data?result.data.data:[resultStatus.SUCCESS]);
        } else {
          setError(true);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // The request was canceled
          console.log('useMasterQuota Request canceled', err.message);
        } else {
          console.error('useMasterQuota Error:', err);
        }
        setError(true);
      }
  }

  return {data,error,loading,lazyQuery};

}

export {
  useInterval,
  AddCharWithColor,
  userColumnSearchProps,
  useCustomSubscription,
  useMasterQuota
};
