import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { ApolloLink, concat } from "apollo-link";
import { store } from "../NextApp";
import { userSignOut, setToken } from "../appRedux/actions/Auth";
import { gqlBaseUrl, gqlWebSocketBaseUrl, authClientSecret } from "util/config";
import { USER_TOKEN_SET } from "../constants/ActionTypes";
import gql from "graphql-tag";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getMainDefinition } from "apollo-utilities";
import { WebSocketLink } from "apollo-link-ws";
import log from "util/LogUtil";
import { setContext } from "apollo-link-context";
import { func } from "prop-types";

class GraphqlCli {
  constructor() {}

  // defaultOptions = {
  //   // watchQuery: {
  //   //   fetchPolicy: "no-cache",
  //   //   errorPolicy: "ignore"
  //   // },
  //   query: {
  //     fetchPolicy: "no-cache",
  //     errorPolicy: "all"
  //   }
  // };

  logoutLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError && networkError.statusCode === 401) {
      store.dispatch(userSignOut());
    }
    if (
      graphQLErrors &&
      graphQLErrors.filter(e => e.message === "not authorized").length > 0
    ) {
      store.dispatch(userSignOut());
    }
  });

  _isRefreshing = false;

  authLink = setContext(async (_, context) => {
    const { headers, path } = context;

    if (path.indexOf("ap/oauth/token") != 0) {
      if (
        store.getState() &&
        store.getState().auth &&
        store.getState().auth.token
      ) {
        //refresh token if required

        if (
          !this._isRefreshing &&
          (new Date(store.getState().auth.token.expiresAt) - new Date() < 0 ||
            (new Date(store.getState().auth.token.expiresAt) - new Date()) /
              1000 <
              60 * 15)
        ) {
          //if (!this._isRefreshing && localStorage.getItem("go") === "1") {
          //localStorage.setItem("go", 2);
          this._isRefreshing = true;
          // 10 minutes before exp
          // access token going to be expired, refresh
          log(
            "*** localStorage token is",
            JSON.parse(localStorage.getItem("token")).refreshToken
          );
          log("*** state token is", store.getState().auth.token.refreshToken);
          let refreshToken = JSON.parse(localStorage.getItem("token"))
            .refreshToken;
          log("*** going to refresh token with this", refreshToken);

          let result = await this.mutate(
            "ap/oauth/token",
            gql`
                mutation g {
                  genToken(grantType : REFRESH_TOKEN, refreshToken:"${refreshToken}", clientSecret:"${authClientSecret}") {
                      code
                      accessToken
                      refreshToken
                      expiresIn
                  }
                }`
          );

          log("*** result of refresh token", JSON.stringify(result));
          var data = result.data.genToken;

          if (data.code === 200) {
            log("*** refresh token OK");
            //store.dispatch({ type: USER_TOKEN_SET, payload: data });
            store.dispatch(setToken(data));
            this._isRefreshing = false;
          } else {
            this._isRefreshing = false;
            log("*** refresh token FAIL");
            // refresh fail, logout

            // alert("going to signout - fail at token refresh:" + data.code);
            // !todo temp
            store.dispatch(userSignOut());
          }
        }
      } // store.getState().auth.token

      return {
        headers: {
          ...headers,
          Authorization:
            "Bearer " +
            (store.getState().auth.token
              ? store.getState().auth.token.accessToken
              : // : JSON.parse(localStorage.getItem("token")).accessToken)
                sessionStorage.getItem("__BY_PASS__") || // kiosk will use this
                JSON.parse(localStorage.getItem("token")).accessToken) // idea to igore by pass string at server side is not possble, as some operation requied jwt to value to extract user credential.
        }
      };
    }
  });

  getLink = operation => {
    const definition = getMainDefinition(operation.query);
    const path = operation.getContext().path;
    // const wspath = operation.variables ? operation.variables.wspath : "";
    const link =
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
        ? new WebSocketLink({
            uri: gqlWebSocketBaseUrl,
            options: {
              reconnect: true
            }
          })
        : this.authLink.concat(
            this.logoutLink.concat(
              new HttpLink({
                uri: gqlBaseUrl + path
              })
            )
          );
    // concat(
    //     this.authMiddleware,
    //     this.logoutLink.concat(
    //       new HttpLink({
    //         uri: gqlBaseUrl + path
    //       })
    //     )
    //   );
    return link.request(operation);
  };

  _cli = null;

  getCli() {
    if (this._cli == null) {
      this._cli = new ApolloClient({
        link: ApolloLink.split(
          () => true,
          operation => this.getLink(operation),
          null
        ),
        cache: new InMemoryCache()
        //defaultOptions: this.defaultOptions
      });
    }
    return this._cli;
  }

  mutate(path, query, variables) {
    return this.getCli().mutate({
      mutation: query,
      context: { path: path },
      variables: variables
    });
  }

  query(path, query, variables, fetchPolicy) {
    fetchPolicy = fetchPolicy || "no-cache";
    return this.getCli().query({
      query: query,
      context: { path: path },
      fetchPolicy: fetchPolicy,
      variables: variables
    });
  }

  subscribe(query, variables) {
    return this.getCli().subscribe({
      query: query,
      // api not support context in subscribe in apollo-client version 2.6 ,
      // so using variable to pass different path for subscriptoin
      variables
    });
  }
}

export default new GraphqlCli();
