import React, { Component, useState } from "react";
import s from "./index.module.css";
import { Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";

/**
 * General utlity component class
 */
export class U extends Component {
  static r = { border: "1px solid red" };
  static g = { border: "1px solid green" };
  static b = { border: "1px solid blue" };

  /**
   *
   * @param w:Width, h:Height, c:Color
   */
  static Box(props) {
    return (
      <div
        style={{
          border: "1px solid " + props.c,
          width: props.w + "px",
          height: props.h + "px"
        }}
      >
        {props.children}
      </div>
    );
  }

  static Circle(props) {
    return (
      <div
        className={s.circle}
        style={{
          display: "inline-block",
          width: props.size,
          height: props.size,
          backgroundColor: props.color,
          ...props.style
        }}
      >
        <div>{props.children}</div>
      </div>
    );
  }

  render() {
    return <></>;
  }
}

export function ScreenSize(props) {
  const { width } = props;
  if (width < 576) return "xs";
  if (width >= 1600) return "xxl";
  if (width >= 1200) return "xl";
  if (width >= 992) return "lg";
  if (width >= 768) return "md";
  if (width >= 576) return "sm";
}

export const getSearchTableProps = (dataIndex, dataLabel) => {
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: "" });
  };

  let searchInput = null;
  const [state, setState] = useState({});

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataLabel}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  };
};
