import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import SelectServicePage from "./SelectServicePage/index";
import SuccessPage from "./SuccessPage/index";
import ErrorPage from "./ErrorPage/index";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";
// import bgImg from "assets/images/bgb.png";
import bgImg from "assets/images/wave.jpg";

class KioskRoute extends Component {
  render() {
    console.log("*** location 2", this.props.location);
    return (
      <Wrapper>
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={this.props.location.key}
            timeout={{ enter: 300, exit: 300 }}
            classNames="fade"
          >
            <section className="route-section">
              <Switch location={this.props.location}>
                <Route
                  path={`${this.props.match.url}/selectService`}
                  component={SelectServicePage}
                />
                <Route
                  path={`${this.props.match.url}/success`}
                  component={SuccessPage}
                />
                <Route
                  path={`${this.props.match.url}/error/:errorCode`}
                  component={ErrorPage}
                />
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  // background: url("https://picsum.photos/1920/1080") no-repeat center center
  background: url(${bgImg}) no-repeat center center fixed !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  /* div.transition-group {
    position: relative;
  } */
  /* 
  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  } */
`;

export default KioskRoute;
