import { combineReducers } from "redux";
import fetchData from "./fetchData";
import {
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_REQUEST,
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_SUCCESS,
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_ERROR,
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_CLEAR,
  VMC_USER_GET_ALL_USER_ROLE_REQUEST,
  VMC_USER_GET_ALL_USER_ROLE_SUCCESS,
  VMC_USER_GET_ALL_USER_ROLE_ERROR,
  VMC_USER_GET_ALL_USER_ROLE_CLEAR,
  VMC_USER_REG_LIST_REQUEST,
  VMC_USER_REG_LIST_SUCCESS,
  VMC_USER_REG_LIST_ERROR,
  VMC_USER_REG_LIST_CLEAR
} from "constants/ActionTypes";

const userInfo = combineReducers({
  userRoleByuserRoleCodeST: fetchData({
    types: [
      VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_REQUEST,
      VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_SUCCESS,
      VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_ERROR,
      VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_CLEAR
    ]
  }),
  userRoleListST: fetchData({
    types: [
      VMC_USER_GET_ALL_USER_ROLE_REQUEST,
      VMC_USER_GET_ALL_USER_ROLE_SUCCESS,
      VMC_USER_GET_ALL_USER_ROLE_ERROR,
      VMC_USER_GET_ALL_USER_ROLE_CLEAR
    ]
  }),
  userRegListST: fetchData({
    types: [
      VMC_USER_REG_LIST_REQUEST,
      VMC_USER_REG_LIST_SUCCESS,
      VMC_USER_REG_LIST_ERROR,
      VMC_USER_REG_LIST_CLEAR
    ]
  })
});

export default userInfo;
