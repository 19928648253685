// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const FETCH_SERVER_TIME = "FETCH_SERVER_TIME";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const USER_OTP_SET = "user_otp_set";
export const SELECT_LOCATION = "select_location";
export const SELECT_CONSOLE = "select_console";
export const USER_COMPANY_LIST = "user_company_list";
export const USER_CARD_TYPE_LIST = "user_card_type_list";

// VMC
// for visitor type
export const VMC_VR_VISITOR_TYPE_REQUEST = "VMC_VR_VISITOR_TYPE_REQUEST";
export const VMC_VR_VISITOR_TYPE_SUCCESS = "VMC_VR_VISITOR_TYPE_SUCCESS";
export const VMC_VR_VISITOR_TYPE_ERROR = "VMC_VR_VISITOR_TYPE_ERROR";
export const VMC_VR_VISITOR_TYPE_CLEAR = "VMC_VR_VISITOR_TYPE_CLEAR";

// for patient type
export const VMC_VR_PATIENT_TYPE_REQUEST = "VMC_VR_PATIENT_TYPE_REQUEST";
export const VMC_VR_PATIENT_TYPE_SUCCESS = "VMC_VR_PATIENT_TYPE_SUCCESS";
export const VMC_VR_PATIENT_TYPE_ERROR = "VMC_VR_PATIENT_TYPE_ERROR";
export const VMC_VR_PATIENT_TYPE_CLEAR = "VMC_VR_PATIENT_TYPE_CLEAR";

// for visitor reg search
export const VMC_VR_SEARCH_VISITOR_REG_REQUEST =
  "VMC_VR_SEARCH_VISITOR_REG_REQUEST";
export const VMC_VR_SEARCH_VISITOR_REG_SUCCESS =
  "VMC_VR_SEARCH_VISITOR_REG_SUCCESS";
export const VMC_VR_SEARCH_VISITOR_REG_ERROR =
  "VMC_VR_SEARCH_VISITOR_REG_ERROR";
export const VMC_VR_SEARCH_VISITOR_REG_CLEAR =
  "VMC_VR_SEARCH_VISITOR_REG_CLEAR";

// for patient reg search
export const VMC_VR_SEARCH_PATIENT_REG_REQUEST =
  "VMC_VR_SEARCH_PATIENT_REG_REQUEST";
export const VMC_VR_SEARCH_PATIENT_REG_SUCCESS =
  "VMC_VR_SEARCH_PATIENT_REG_SUCCESS";
export const VMC_VR_SEARCH_PATIENT_REG_ERROR =
  "VMC_VR_SEARCH_PATIENT_REG_ERROR";
export const VMC_VR_SEARCH_PATIENT_REG_CLEAR =
  "VMC_VR_SEARCH_PATIENT_REG_CLEAR";

// for staff reg search
export const VMC_VR_SEARCH_STAFF_REG_REQUEST =
  "VMC_VR_SEARCH_STAFF_REG_REQUEST";
export const VMC_VR_SEARCH_STAFF_REG_SUCCESS =
  "VMC_VR_SEARCH_STAFF_REG_SUCCESS";
export const VMC_VR_SEARCH_STAFF_REG_ERROR = "VMC_VR_SEARCH_STAFF_REG_ERROR";
export const VMC_VR_SEARCH_STAFF_REG_CLEAR = "VMC_VR_SEARCH_STAFF_REG_CLEAR";

// for tenant reg search
export const VMC_VR_SEARCH_TENANT_REG_REQUEST =
  "VMC_VR_SEARCH_TENANT_REG_REQUEST";
export const VMC_VR_SEARCH_TENANT_REG_SUCCESS =
  "VMC_VR_SEARCH_TENANT_REG_SUCCESS";
export const VMC_VR_SEARCH_TENANT_REG_ERROR = "VMC_VR_SEARCH_TENANT_REG_ERROR";
export const VMC_VR_SEARCH_TENANT_REG_CLEAR = "VMC_VR_SEARCH_TENANT_REG_CLEAR";

//for get visitor reg by id
export const VMC_VR_GET_VISITOR_REG_BY_ID_REQUEST =
  "GET_VISITOR_REG_BY_ID__REQUEST";
export const VMC_VR_GET_VISITOR_REG_BY_ID_SUCCESS =
  "GET_VISITOR_REG_BY_ID_SUCCESS";
export const VMC_VR_GET_VISITOR_REG_BY_ID_ERROR = "GET_VISITOR_REG_BY_ID_ERROR";
export const VMC_VR_GET_VISITOR_REG_BY_ID_CLEAR = "GET_VISITOR_REG_BY_ID_CLEAR";

//for get patient reg by id
export const VMC_VR_GET_PATIENT_REG_BY_ID_REQUEST =
  "GET_PATIENT_REG_BY_ID__REQUEST";
export const VMC_VR_GET_PATIENT_REG_BY_ID_SUCCESS =
  "GET_PATIENT_REG_BY_ID_SUCCESS";
export const VMC_VR_GET_PATIENT_REG_BY_ID_ERROR = "GET_PATIENT_REG_BY_ID_ERROR";
export const VMC_VR_GET_PATIENT_REG_BY_ID_CLEAR = "GET_PATIENT_REG_BY_ID_CLEAR";

//for get staff reg by id
export const VMC_VR_GET_STAFF_REG_BY_ID_REQUEST =
  "GET_STAFF_REG_BY_ID__REQUEST";
export const VMC_VR_GET_STAFF_REG_BY_ID_SUCCESS = "GET_STAFF_REG_BY_ID_SUCCESS";
export const VMC_VR_GET_STAFF_REG_BY_ID_ERROR = "GET_STAFF_REG_BY_ID_ERROR";
export const VMC_VR_GET_STAFF_REG_BY_ID_CLEAR = "GET_STAFF_REG_BY_ID_CLEAR";

//for save/update visitorReg
export const VMC_VR_SAVE_VISITOR_REG_REQUEST =
  "VMC_VR_SAVE_VISITOR_REG_REQUEST";
export const VMC_VR_SAVE_VISITOR_REG_SUCCESS =
  "VMC_VR_SAVE_VISITOR_REG_SUCCESS";
export const VMC_VR_SAVE_VISITOR_REG_ERROR = "VMC_VR_SAVE_VISITOR_REG_ERROR";
export const VMC_VR_SAVE_VISITOR_REG_CLEAR = "VMC_VR_SAVE_VISITOR_REG_CLEAR";

//for save/update staffReg
export const VMC_VR_SAVE_STAFF_REG_REQUEST = "VMC_VR_SAVE_STAFF_REG_REQUEST";
export const VMC_VR_SAVE_STAFF_REG_SUCCESS = "VMC_VR_SAVE_STAFF_REG_SUCCESS";
export const VMC_VR_SAVE_STAFF_REG_ERROR = "VMC_VR_SAVE_STAFF_REG_ERROR";
export const VMC_VR_SAVE_STAFF_REG_CLEAR = "VMC_VR_SAVE_STAFF_REG_CLEAR";

//for save/update patientReg
export const VMC_VR_SAVE_PATIENT_REG_REQUEST =
  "VMC_VR_SAVE_PATIENT_REG_REQUEST";
export const VMC_VR_SAVE_PATIENT_REG_SUCCESS =
  "VMC_VR_SAVE_PATIENT_REG_SUCCESS";
export const VMC_VR_SAVE_PATIENT_REG_ERROR = "VMC_VR_SAVE_PATIENT_REG_ERROR";
export const VMC_VR_SAVE_PATIENT_REG_CLEAR = "VMC_VR_SAVE_PATIENT_REG_CLEAR";

//for save/update staffReg
export const VMC_VR_SAVE_TENANT_REG_REQUEST = "VMC_VR_SAVE_TENANT_REG_REQUEST";
export const VMC_VR_SAVE_TENANT_REG_SUCCESS = "VMC_VR_SAVE_TENANT_REG_SUCCESS";
export const VMC_VR_SAVE_TENANT_REG_ERROR = "VMC_VR_SAVE_TENANT_REG_ERROR";
export const VMC_VR_SAVE_TENANT_REG_CLEAR = "VMC_VR_SAVE_TENANT_REG_CLEAR";

//for company data
export const VMC_VR_COMPANY_DATA_REQUEST = "VMC_VR_COMPANY_DATA_REQUEST";
export const VMC_VR_COMPANY_DATA_SUCCESS = "VMC_VR_COMPANY_DATA_SUCCESS";
export const VMC_VR_COMPANY_DATA_ERROR = "VMC_VR_COMPANY_DATA_ERROR";
export const VMC_VR_COMPANY_DATA_CLEAR = "VMC_VR_COMPANY_DATA_CLEAR";

//for visitor reg list
export const VMC_VR_VISITOR_REG_LIST_REQUEST =
  "VMC_VR_VISITOR_REG_LIST_REQUEST";
export const VMC_VR_VISITOR_REG_LIST_SUCCESS =
  "VMC_VR_VISITOR_REG_LIST_SUCCESS";
export const VMC_VR_VISITOR_REG_LIST_ERROR = "VMC_VR_VISITOR_REG_LIST_ERROR";
export const VMC_VR_VISITOR_REG_LIST_CLEAR = "VMC_VR_VISITOR_REG_LIST_CLEAR";

//for patient reg list
export const VMC_VR_PATIENT_REG_LIST_REQUEST =
  "VMC_VR_PATIENT_REG_LIST_REQUEST";
export const VMC_VR_PATIENT_REG_LIST_SUCCESS =
  "VMC_VR_PATIENT_REG_LIST_SUCCESS";
export const VMC_VR_PATIENT_REG_LIST_ERROR = "VMC_VR_PATIENT_REG_LIST_ERROR";
export const VMC_VR_PATIENT_REG_LIST_CLEAR = "VMC_VR_PATIENT_REG_LIST_CLEAR";

//for Qr Code
export const VMC_VR_GET_QR_REQUEST = "VMC_VR_GET_QR_REQUEST";
export const VMC_VR_GET_QR_SUCCESS = "VMC_VR_GET_QR_SUCCESS";
export const VMC_VR_GET_QR_ERROR = "VMC_VR_GET_QR_ERROR";
export const VMC_VR_GET_QR_CLEAR = "VMC_VR_GET_QR_CLEAR";

//DASHBOARD CONSOLE
export const DB_CO_SURVICE_SUMMARY_REQUEST = "DB_CO_SURVICE_SUMMARY_REQUEST";
export const DB_CO_SURVICE_SUMMARY_SUCCESS = "DB_CO_SURVICE_SUMMARY_SUCCESS";
export const DB_CO_SURVICE_SUMMARY_ERROR = "DB_CO_SURVICE_SUMMARY_ERROR";
export const DB_CO_SURVICE_SUMMARY_CLEAR = "DB_CO_SURVICE_SUMMARY_CLEAR";

export const DB_CO_SURVICE_STATISTICS_REQUEST =
  "DB_CO_SURVICE_STATISTICS_REQUEST";
export const DB_CO_SURVICE_STATISTICS_SUCCESS =
  "DB_CO_SURVICE_STATISTICS_SUCCESS";
export const DB_CO_SURVICE_STATISTICS_ERROR = "DB_CO_SURVICE_STATISTICS_ERROR";
export const DB_CO_SURVICE_STATISTICS_CLEAR = "DB_CO_SURVICE_STATISTICS_CLEAR";

export const DB_CO_SURVICE_SUMMARY_TF_REQUEST =
  "DB_CO_SURVICE_SUMMARY_TF_REQUEST";
export const DB_CO_SURVICE_SUMMARY_TF_SUCCESS =
  "DB_CO_SURVICE_SUMMARY_TF_SUCCESS";
export const DB_CO_SURVICE_SUMMARY_TF_ERROR = "DB_CO_SURVICE_SUMMARY_TF_ERROR";
export const DB_CO_SURVICE_SUMMARY_TF_CLEAR = "DB_CO_SURVICE_SUMMARY_TF_CLEAR";

// visitor reg list for live monitor
export const VMC_VR_VISITOR_REG_LIST_LM_REQUEST =
  "VMC_VR_VISITOR_REG_LIST_LM_REQUEST";
export const VMC_VR_VISITOR_REG_LIST_LM_SUCCESS =
  "VMC_VR_VISITOR_REG_LIST_LM_SUCCESS";
export const VMC_VR_VISITOR_REG_LIST_LM_ERROR =
  "VMC_VR_VISITOR_REG_LIST_LM_ERROR";
export const VMC_VR_VISITOR_REG_LIST_LM_CLEAR =
  "VMC_VR_VISITOR_REG_LIST_LM_CLEAR";

export const VMC_VR_STAFF_REG_LIST_REQUEST = "VMC_VR_STAFF_REG_LIST_REQUEST";
export const VMC_VR_STAFF_REG_LIST_SUCCESS = "VMC_VR_STAFF_REG_LIST_SUCCESS";
export const VMC_VR_STAFF_REG_LIST_ERROR = "VMC_VR_STAFF_REG_LIST_ERROR";
export const VMC_VR_STAFF_REG_LIST_CLEAR = "VMC_VR_STAFF_REG_LIST_CLEAR";

export const VMC_VR_GET_TENANT_REG_BY_ID_REQUEST =
  "GET_TENANT_REG_BY_ID__REQUEST";
export const VMC_VR_GET_TENANT_REG_BY_ID_SUCCESS =
  "GET_TENANT_REG_BY_ID_SUCCESS";
export const VMC_VR_GET_TENANT_REG_BY_ID_ERROR = "GET_TENANT_REG_BY_ID_ERROR";
export const VMC_VR_GET_TENANT_REG_BY_ID_CLEAR = "GET_TENANT_REG_BY_ID_CLEAR";

export const VMC_VR_TENANT_REG_LIST_REQUEST = "VMC_VR_TENANT_REG_LIST_REQUEST";
export const VMC_VR_TENANT_REG_LIST_SUCCESS = "VMC_VR_TENANT_REG_LIST_SUCCESS";
export const VMC_VR_TENANT_REG_LIST_ERROR = "VMC_VR_TENANT_REG_LIST_ERROR";
export const VMC_VR_TENANT_REG_LIST_CLEAR = "VMC_VR_TENANT_REG_LIST_CLEAR";

export const VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_REQUEST =
  "VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_REQUEST";
export const VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_SUCCESS =
  "VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_SUCCESS";
export const VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_ERROR =
  "VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_ERROR";
export const VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_CLEAR =
  "VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_CLEAR";

export const VMC_USER_SAVE_USER_ROLE_REQUEST =
  "VMC_USER_SAVE_USER_ROLE_REQUEST";
export const VMC_USER_SAVE_USER_ROLE_SUCCESS =
  "VMC_USER_SAVE_USER_ROLE_SUCCESS";
export const VMC_USER_SAVE_USER_ROLE_ERROR = "VMC_USER_SAVE_USER_ROLE_ERROR";
export const VMC_USER_SAVE_USER_ROLE_CLEAR = "VMC_USER_SAVE_USER_ROLE_CLEAR";

export const VMC_USER_GET_ALL_USER_ROLE_REQUEST =
  "VMC_USER_GET_ALL_USER_ROLE_REQUEST";
export const VMC_USER_GET_ALL_USER_ROLE_SUCCESS =
  "VMC_USER_GET_ALL_USER_ROLE_SUCCESS";
export const VMC_USER_GET_ALL_USER_ROLE_ERROR =
  "VMC_USER_GET_ALL_USER_ROLE_ERROR";
export const VMC_USER_GET_ALL_USER_ROLE_CLEAR =
  "VMC_USER_GET_ALL_USER_ROLE_CLEAR";

export const VMC_USER_REG_LIST_REQUEST = "VMC_USER_REG_LIST_REQUEST";
export const VMC_USER_REG_LIST_SUCCESS = "VMC_USER_REG_LIST_SUCCESS";
export const VMC_USER_REG_LIST_ERROR = "VMC_USER_REG_LIST_ERROR";
export const VMC_USER_REG_LIST_CLEAR = "VMC_USER_REG_LIST_CLEAR";

// CHAT
export const C_IS_CHAT_PANEL_OPEN = "c_is_chat_panel_open";
export const C_CHAT_ADDRESSES = "c_chat_addresses";
export const C_SELCTED_CHAT_ADDRESS = "c_selcted_chat_address";
export const C_CHAT_MESSAGES = "c_chat_messages";
export const C_ADD_CHAT_MESSAGE = "c_add_chat_message";
export const C_CHAT_UNREADS = "c_chat_unreads";

// FLOW Kiosk Console
export const F_KIOSK_SERVICE_LIST = "f_kiosk_service_list";
export const F_LAUNCH_KIOSK_CONSOLE_ID = "f_launch_kiosk_console_id";
export const F_KIOSK_CONSOLE_INFO = "f_kiosk_console_info";
export const F_KIOSK_TEMPLATE_SETTING = "f_kiosk_template_setting";
export const F_KIOSK_SCROLLING_MESSAGE = "f_kiosk_scrolling_message";
export const F_KIOSK_MEDIA_PLAYLIST = "f_kiosk_media_playlist";

// dorscon
export const SELECT_DORSCON = "select_dorscon";

// vmc m
export const VMC_M_GET_DORSCON_LIST_REQUEST = "VMC_M_GET_DORSCON_LIST_REQUEST";
export const VMC_M_GET_DORSCON_LIST_SUCCESS = "VMC_M_GET_DORSCON_LIST_SUCCESS";
export const VMC_M_GET_DORSCON_LIST_ERROR = "VMC_M_GET_DORSCON_LIST_ERROR";
export const VMC_M_GET_DORSCON_LIST_CLEAR = "VMC_M_GET_DORSCON_LIST_CLEAR";
