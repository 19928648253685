import React, { Component , useEffect,useState } from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Icon ,Form , Modal,Button,Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userSignOut } from "appRedux/actions/Auth";
import gql from "graphql-tag";
import {
  NAV_STYLE_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import {
  getUseQuery,
  getUseLazyQuery,
  getUseMutation
} from "../../util/gqlUtil";
// import validator  from "validator";
import custom_style from "./up.module.less";
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1599962_z8e4is815te.js" // generated by iconfont.cn
});


const UPDATE_PASSWORD=gql`
mutation updatePassword($data: UpdatePasswordInput){
  data:updatePassword(data:$data){
      status
      message
  }
}`

const icon_style = { fontSize: "24px", color: "#08c" };
function AddCharWithColor(props) {
  return (
    <>
      <span style={{ color: props.color }}>{props.char}</span>
    </>
  );
}

// const manipulateMessage=(psp)=>
// {
//   // Password must be at least 12 characters, and must include one uppercase letter, one number and one special character.
//   let message = [];
//   if(psp.minLength)  message.push("Password must be at least "+psp.minLength+" characters");
//   if(psp.minUppercase === 1)  message.push("one uppercase letter");
//   if(psp.minLowercase === 1)  message.push("one lowercase letter");
//   if(psp.minNumbers === 1)  message.push("one number");
//   if(psp.minSymbols === 1)  message.push("one special character");

//   let secondToEnd="";
//   if(message.length === 1){
//     return message[0]+".";
//   }else
//   {

//     for(let i=0;i<message.length;i++){
//       if(i===0){
//         secondToEnd=message[i]+" and must include ";
//       }else
//       if(i===message.length-1)
//       {
//         if(i!==1){
//           secondToEnd=secondToEnd+" and "+message[i]+".";
//         }else
//         {
//           secondToEnd=secondToEnd+message[i]+".";
//         }
//       }else
//       {
//         secondToEnd=secondToEnd+(i!==1?" ,"+message[i]:message[i]);
//       }
//     }

//     return secondToEnd;
//   }
// }

const UpdatePasswordModal = Form.create({ name: "service_form"})(props => {
  const { handleOk, handleCancel,form , passwordStrengthPolicy} =props
  const { getFieldDecorator } = form;

  const defaultPSP ={
    minLength: 12,
    minUppercase: 1, minNumbers: 1, minSymbols: 1
  };


  const validateConfirmPassword = (rule, value, callback) => {
    if (value) {
      if (props.form.getFieldValue("newPassword") !== value) {
        callback("New Password & Confirm password not match.");
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  // const validateIsStrongPassword = (rule, value, callback) => {
  //   if (value) {

  //     if (props.form.getFieldValue("currentPassword") === value) {
  //       callback("Should not be same as current password.");
  //     }else
  //     if (!validator.isStrongPassword(value, passwordStrengthPolicy?passwordStrengthPolicy:defaultPSP)) {
  //       const curPSP = passwordStrengthPolicy?passwordStrengthPolicy:defaultPSP;
  //       callback(manipulateMessage(curPSP));
  //     } else {
  //       callback();
  //     }
  //   } else {
  //     callback();
  //   }
  // }


  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      !err && props.onSubmit && props.onSubmit(values);
    });
  };

  return (<Modal

    className={custom_style["custom-modal"]}
    visible={true}
    title="Change Password"
    onOk={handleOk}
    onCancel={(e)=>{
      e.stopPropagation();
      handleCancel()}
  }
    footer={[
      <Button key="back" onClick={(e)=>handleCancel(e)}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" loading={false} onClick={(e)=>{
        handleSubmit(e);

      }}>
        Submit
      </Button>,
    ]}
  >
    <Form
      labelAlign="left"
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      autoComplete="off"
    >
      {/* <Form.Item
        required={false}
        label={<span>
          <FontAwesomeIcon
            fixedWidth
            icon="file-signature"
            size="lg"
            style={icon_style}
          />
          &nbsp;Current Password&nbsp;
          <AddCharWithColor char="*" color="red" />
        </span>}
        name="currentPassword"
      >
        {getFieldDecorator("currentPassword", {
          rules: [{ required: true, message: `Current Password is required!` }]
        })(<Input.Password placeholder="Current Password" />)}

      </Form.Item> */}
      <Form.Item
        required={false}
        label={<span>
          <FontAwesomeIcon
            fixedWidth
            icon="file-signature"
            size="lg"
            style={icon_style}
          />
          &nbsp;New Password&nbsp;
          <AddCharWithColor char="*" color="red" />
        </span>}
        name="password"
      >
        {getFieldDecorator("newPassword", {
          rules: [
           // { validator: validateIsStrongPassword},
            { required: true, message: `New Password is required!` }]
        })(<Input.Password placeholder="New Password" />)}

      </Form.Item>
      <Form.Item
        required={false}
        label={<span>
          <FontAwesomeIcon
            fixedWidth
            icon="file-signature"
            size="lg"
            style={icon_style}
          />
          &nbsp;Confirm Password&nbsp;
          <AddCharWithColor char="*" color="red" />
        </span>}
        name="verifyPassword"
      >
         {getFieldDecorator("confirmPassword", {
                rules: [
                  {
                    validator: validateConfirmPassword
                  },
                  {
                    required:true,
                    message: "Confirm Password is required!"
                  }
                ]
              })(
                <Input.Password
                  placeholder="Confirm Password"
                />
              )}
      </Form.Item>
    </Form>
  </Modal>);
});

const UserProfile=(props)=> {


  const [visible, setVisible] = useState(false);
  const [updatePasswordModalVisible, setUpdatePasswordModalVisible] = useState(false);
  const updatePasswordUM =getUseMutation(UPDATE_PASSWORD,"ap/oauth/token?updatePassword","no-cache");
  // const getInstitutionUQ =getUseQuery(GET_INSTITUTION,"vfmSettings?getInstitution",{
  //   institutionId: props.selectedInstitution.institutionId
  // },"no-cache");

  const icon_style_normal = {
    width: 40,
    height: 40,
    lineHeight: 40,
    fontSize: 20
    //backgroundColor: "#FFF"
  };

  const icon_style_mini_side_bar = {
    width: 30,
    height: 30,
    lineHeight: 30,
    paddinLeft: 15,
    fontSize: 20
    //backgroundColor: "#FFF"
  };

  const updateIconColourByTheme = (style, current_theme) => {
    const icon_style = { ...style };
    if (current_theme === THEME_TYPE_LITE) {
      icon_style.color = "#545454";
    }
    return icon_style;
  };
  const isMiniSideBar = navStyle => {
    if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      return true;
    }
    return false;
  };


  const showUpdatePasswordModal=(value)=>{
    setVisible(false);
    setTimeout(()=>{
      setUpdatePasswordModalVisible(value);

    },500);
  }

  useEffect(() => {
    if(updatePasswordUM.data){
      if(updatePasswordUM.data.data.status === "SUCCESS"){
      Modal.success({
        title: "Success",
        content: "Password updated successfully.",
        onOk(){ showUpdatePasswordModal(false); }});
      }else{
        Modal.error({
          title: "Error",
          content: updatePasswordUM.data.data.message});
      }
    }
  },[updatePasswordUM.data]);



  const onSubmit=(values)=> {
    console.log("submit",values);
    const { authUser } = props;
    updatePasswordUM.mutation({
     variables:{
       data:{
          newPassword: values.newPassword,
          //currentPassword:values.currentPassword
          userId: authUser.userId
       }
     }
    });
  }

  const handleVisibleChange = value => {
    // setState({ visible });
    console.log("visible",value)
     setVisible(value);
   };




    const { authUser, navStyle, themeType } = props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => props.userSignOut()}>
          <FontAwesomeIcon icon="door-open" style={{ fontSize: "20px" }} />{" "}
          Logout
        </li>
        {authUser.authModeCode!=='AD' && <li onClick={()=>{showUpdatePasswordModal(true)}} className={custom_style["custom-ahref"]}>
          Change Password
        </li>}
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-avatar-row">
        {updatePasswordModalVisible && <UpdatePasswordModal
        //  passwordStrengthPolicy={passwordStrengthPolicy}
        handleCancel={(e)=>{
          console.log("okie");
          showUpdatePasswordModal(false);
        }}

        onSubmit={onSubmit}

        />}

        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          {/* <Avatar
            src="https://via.placeholder.com/150x150"
            className="gx-size-40 gx-pointer gx-mr-3"
            alt=""
          /> */}

          {/* <Avatar
            size={40}
            icon="user"
            style={{ backgroundColor: "#87d068" }}
            className="gx-size-40 gx-pointer gx-mr-3"
          /> */}
          {/* <MyIcon
            type="icon-user"
            style={{ fontSize: "33px", paddingRight: "10px", color: "white" }}
          /> */}
          <span className="ant-avatar gx-size-40 gx-pointer gx-mr-3 ant-avatar-circle ant-avatar-image">

            {authUser.imageUrl
            ? <Avatar
              className="gx-size-40 gx-pointer gx-mr-3"
              alt="user icon"
              style={
                isMiniSideBar(navStyle)
                  ? updateIconColourByTheme(
                      icon_style_mini_side_bar,
                      themeType
                    )
                  : updateIconColourByTheme(
                      icon_style_normal,
                      themeType
                    )
              }
              src={authUser.imageUrl}
            />
            :<FontAwesomeIcon
              icon="user-circle"
              style={
                isMiniSideBar(navStyle)
                  ? updateIconColourByTheme(
                      icon_style_mini_side_bar,
                      themeType
                    )
                  : updateIconColourByTheme(
                      icon_style_normal,
                      themeType
                    )
              }
            />
          }
          </span>
          <span className="gx-avatar-name">
            {authUser ? authUser.userName : "Loading"}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </Popover>
      </div>
    );

}

const mapStateToProps = ({ auth, settings }) => {
  const { navStyle, themeType } = settings;
  const { authUser } = auth;
  return { authUser, navStyle, themeType };
};

export default connect(mapStateToProps, { userSignOut })(UserProfile);
