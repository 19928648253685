import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import FlareComponent from "flare-react";
import successFlare from "assets/flr/success-tick-bin.flr";
import { ResourceText, Footer } from "../Common";
import _ from "lodash";
import { getKioskDevice } from "appRedux/actions/KioskConsole";
import { connect } from "react-redux";

export default connect(() => {}, { getKioskDevice })(
  ({ history, preview, onResourceChange, getKioskDevice }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      if (!preview) {
        setTimeout(() => {
          history.push("/kioskConsole/selectService");
        }, 4000 * 10000);
      } else {
        // preview
        sessionStorage.setItem(
          "launchKioskConsoleId",
          process.env.REACT_APP_KIOSK_ID
        );
        getKioskDevice();
      }
    }, []);

    return (
      <div className="cc-kiosk">
        <div className="body">
          {" "}
          <div style={{ marginLeft: "45%", paddingTop: "28%" }}>
            {!loaded && <div style={{ width: 150, height: 155 }}></div>}
            <FlareComponent
              width={120}
              height={120}
              animationName="success"
              file={successFlare}
              transparent={true}
              onLoadedAnimations={() => setLoaded(true)}
              //require("assets/flr/success-tick-bin.flr")
            />
          </div>
          <div className="message">
            {/* Please take the ticket and proceed to the gantry. */}
            <ResourceText
              page={"SUCCESS"}
              k={"proceed"}
              preview={preview}
              onResourceChange={onResourceChange}
            ></ResourceText>
          </div>
          {/* <Button
              onClick={() =>
                this.props.history.push("/kioskConsole/selectService")
              }
            >
              Home
            </Button> */}
        </div>

        {preview && <Footer bottom={70}></Footer>}
      </div>
    );
  }
);
