import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_OTP_SET,
  SELECT_LOCATION,
  SELECT_CONSOLE,
  USER_CARD_TYPE_LIST,
  USER_COMPANY_LIST
} from "../../constants/ActionTypes";
import log from "util/LogUtil";
import jwt_decode from "jwt-decode";

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem("token")),
  initURL: "",
  authUser: JSON.parse(localStorage.getItem("user")),
  otpPass: JSON.parse(localStorage.getItem("otpPass")),
  selectedLocation: JSON.parse(localStorage.getItem("selectedLocation")),
  selectedConsole: JSON.parse(localStorage.getItem("selectedConsole")),
  companyList: localStorage.getItem("companyList")? JSON.parse(localStorage.getItem("companyList")):[],
  cardTypeList: localStorage.getItem("cardTypeList")? JSON.parse(localStorage.getItem("cardTypeList")):[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      localStorage.removeItem("token");
      localStorage.removeItem("otpPass");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedLocation");
      return {
        ...state,
        token: null,
        authUser: null,
        // otpPass: true,
        otpPass: null,
        initURL: "",
        selectedLocation: null
      };
    }

    case USER_DATA: {
      localStorage.setItem(
        "user",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );

      return {
        ...state,
        authUser: action.payload
      };
    }

    case SELECT_LOCATION: {
      localStorage.setItem(
        "selectedLocation",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );

      return {
        ...state,
        selectedLocation: action.payload
      };
    }

    case USER_TOKEN_SET: {
      if (action.payload && action.payload.expiresIn) {
        let t = new Date();
        t.setSeconds(t.getSeconds() + action.payload.expiresIn);
        // JSON.stringify to have consistency with reading back from localStorage
        // format required is "2019-12-20T04:52:24.308Z"
        action.payload.expiresAt = JSON.parse(JSON.stringify({ d: t })).d;
      }
      localStorage.setItem(
        "token",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );
      // log("*** USER_TOKEN_SET", JSON.stringify(action.payload));
      return {
        ...state,
        token: action.payload
      };
    }

    case USER_COMPANY_LIST: {

      var decoded = jwt_decode(action.payload.accessToken);

      localStorage.setItem(
        "companyList",
        decoded.companyList ? JSON.stringify(decoded.companyList) : decoded.companyList
      );
      // log("*** USER_TOKEN_SET", JSON.stringify(action.payload));
      return {
        ...state,
        companyList: decoded.companyList
      };
    }

    case USER_CARD_TYPE_LIST: {

      var decoded = jwt_decode(action.payload.accessToken);

      localStorage.setItem(
        "cardTypeList",
        decoded.cardTypeList ? JSON.stringify(decoded.cardTypeList) : decoded.cardTypeList
      );
      // log("*** USER_TOKEN_SET", JSON.stringify(action.payload));
      return {
        ...state,
        cardTypeList: decoded.cardTypeList
      };
    }

    case USER_OTP_SET: {
      localStorage.setItem(
        "otpPass",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );
      return {
        ...state,
        otpPass: action.payload
      };
    }

    case SELECT_CONSOLE: {
      localStorage.setItem(
        "selectedConsole",
        action.payload ? JSON.stringify(action.payload) : action.payload
      );

      return {
        ...state,
        selectedConsole: action.payload
      };
    }

    default:
      return state;
  }
};
