import React, { useEffect, useState, useContext, useRef } from "react";
import { connect } from "react-redux";
import {
  closeChatPanel,
  getChatAddresses,
  selectChatAddress,
  sendChat,
  markChatRead,
  getGroupChatAddresses
} from "appRedux/actions/Chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Avatar, Button, Drawer, Input, Tabs, Row, Col, Popover } from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import CircularProgress from "components/CircularProgress/index";
import _ from "lodash";
import MyIcon2 from "util/MyIcon2";
import moment from "moment";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const MainContext = React.createContext({
  selectedChatAddress: {},
  searchChatAddress: ""
});

const ChatAddress = ({ chatAddress }) => {
  const {
    onSelectAddress,
    selectedChatAddress,
    loginUserId,
    chatUnreads
  } = useContext(MainContext);

  // console.log(
  //   `mainContext.selectedChatAddress`,
  //   mainContext.selectedChatAddress
  // );
  const activeFontColor =
    selectedChatAddress.addressId === chatAddress.addressId
      ? { color: "#FA8C15" }
      : {};
  return (
    <div
      style={{ padding: 5 }}
      className={`gx-chat-user-item ${
        selectedChatAddress.addressId === chatAddress.addressId ? "active" : ""
      }`}
      onClick={() => {
        onSelectAddress(chatAddress);
      }}
    >
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            <Avatar
              src={require(`assets/images/users/${
                chatAddress.photoUUID ? chatAddress.photoUUID : "default"
              }.png`)}
              className="gx-size-40"
              alt={chatAddress.displayName}
            />
            {chatUnreads.findIndex(
              unread =>
                unread ===
                `${loginUserId}_${chatAddress.userOrGroup}_${chatAddress.addressId}`
            ) > -1 && <span className="gx-status gx-online" />}
            {/* <span className={`gx-status gx-small gx-${chat.status}`} /> */}
          </div>
        </div>

        <div className="gx-chat-info">
          <span style={{ ...activeFontColor }} className="gx-name h4">
            {chatAddress.displayName}
          </span>
          {/* <div className="gx-chat-info-des gx-text-truncate">
            {chat.lastMessage.substring(0, 25) + "..."}
          </div> */}
          {/* <div className="gx-last-message-time">{chat.lastMessageTime}</div> */}
        </div>

        {/* {chat.unreadMessage > 0 ? (
          <div className="gx-chat-date">
            <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">
              {chat.unreadMessage}
            </div>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

const ChatAddressList = ({ chatAddresses }) => {
  const {} = useContext(MainContext);
  return (
    <div className="gx-chat-user">
      {chatAddresses &&
        chatAddresses.map((chatAddress, index) => (
          <ChatAddress
            key={index}
            chatAddress={chatAddress}
            // selectedSectionId={selectedSectionId}
          />
        ))}
    </div>
  );
};

const SearchBox = ({ styleName, placeholder, onChange, value }) => {
  return (
    <div className={`gx-search-bar ${styleName}`} style={{ height: "100%" }}>
      <div className="gx-form-group">
        <input
          className="ant-input"
          type="search"
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
          style={{ height: "100%" }}
        />
        <span className="gx-search-icon gx-pointer" style={{ paddingTop: 5 }}>
          <i className="icon icon-search" />
        </span>
      </div>
    </div>
  );
};

const SentMessageCell = ({ conversation }) => {
  return (
    <div className="gx-chat-item gx-flex-row-reverse">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src={require(`assets/images/users/${conversation.userPhotoUUID}.png`)}
        alt={conversation.name}
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">
            <pre style={{ whiteSpace: "pre-wrap", marginBottom: 5 }}>
              {conversation.message}
            </pre>
          </div>
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
            <span style={{ fontSize: 12 }}>
              {conversation.fullName},{" "}
              {moment(new Date(conversation.sentAt)).format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReceivedMessageCell = ({ conversation }) => {
  return (
    <div className="gx-chat-item">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src={require(`assets/images/users/${conversation.userPhotoUUID}.png`)}
        alt=""
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">
            <pre style={{ whiteSpace: "pre-wrap", marginBottom: 5 }}>
              {conversation.message}
            </pre>
          </div>
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
            <span style={{ fontSize: 12 }}>
              {conversation.fullName},{" "}
              {moment(new Date(conversation.sentAt)).format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatMessageList = ({ chatMessages }) => {
  const { selectedChatAddress, loginUserId } = useContext(MainContext);

  return (
    // <div style={{ height: "100%", overflowY: "scroll !important" }}>
    <div>
      {/* <TransitionGroup className="chat-list"> */}
      {chatMessages.map((chatMessage, index) => {
        return chatMessage.sentByUserId === loginUserId ? (
          // <CSSTransition
          //   key={`${index}`}
          //   timeout={500}
          //   classNames="cc-list-item"
          // >
          <SentMessageCell
            key={index}
            conversation={{
              name: chatMessage.sentByDisplayName,
              message: chatMessage.chatMessage,
              sentAt: chatMessage.sentDt,
              userPhotoUUID: chatMessage.sentByPhotoUUID,
              fullName: chatMessage.sentByShortCode
            }}
          />
        ) : (
          // </CSSTransition>
          // <CSSTransition
          //   key={`${index}`}
          //   timeout={500}
          //   classNames="cc-list-item"
          // >
          <ReceivedMessageCell
            key={index}
            conversation={{
              name: chatMessage.sentByDisplayName,
              message: chatMessage.chatMessage,
              sentAt: chatMessage.sentDt,
              userPhotoUUID: chatMessage.sentByPhotoUUID,
              fullName: chatMessage.sentByShortCode
            }}
          />
          // </CSSTransition>
        );
      })}
      {/* </TransitionGroup> */}
    </div>
  );
};

const Communication = ({ chatMessages, sendChat }) => {
  const { selectedChatAddress, closeChatPanel } = useContext(MainContext);
  const [typeChatMessage, setTypeChatMessage] = useState("");
  const [groupChatAddresses, setGroupChatAddresses] = useState([]);

  let refChatInput = useRef();
  // let refChatMessageListDiv = useRef();

  const send = () => {
    console.log(`sendChat2`, typeChatMessage);
    sendChat(typeChatMessage);

    setTypeChatMessage("");
    // refChatInput.focus();
  };

  useEffect(() => {
    if (selectedChatAddress && selectedChatAddress.userOrGroup === 1) {
      getGroupChatAddresses(selectedChatAddress.addressId).then(result =>
        setGroupChatAddresses(result)
      );
    }
  }, [selectedChatAddress]);

  useEffect(() => {
    typeof refChatInput.focus === "function" && refChatInput.focus();
  }, [chatMessages]);

  // useEffect(() => {
  //   refChatMessageListDiv &&
  //     console.log(`refChatMessageListDiv`, refChatMessageListDiv);
  //   // refChatMessageListDiv.current.scrollToBottom();
  // }, [refChatMessageListDiv]);

  const getGroupChatAddressesContent = () => {
    return groupChatAddresses
      ? groupChatAddresses.map(address => (
          <div style={{ marginBottom: 10 }}>
            <Avatar
              src={require(`assets/images/users/${
                address.photoUUID ? address.photoUUID : "default"
              }.png`)}
              className="gx-size-40"
              style={{ marginLeft: 10 }}
            />
            <div
              style={{ marginLeft: 5, marginRight: 5, display: "inline-block" }}
            >
              {address.fullName}
            </div>
          </div>
        ))
      : "";
  };

  return (
    <div
      style={{
        height: "100%"
      }}
    >
      <div style={{ height: "10%", marginLeft: 1 }} className="cc-bb">
        <Col style={{ height: "100%", display: "flex", alignItems: "center" }}>
          {!_.isEmpty(selectedChatAddress) && (
            <>
              {" "}
              {selectedChatAddress.userOrGroup === 1 && (
                <Popover
                  placement="left"
                  content={getGroupChatAddressesContent()}
                >
                  <Avatar
                    src={require(`assets/images/users/${
                      selectedChatAddress.photoUUID
                        ? selectedChatAddress.photoUUID
                        : "default"
                    }.png`)}
                    className="gx-size-40"
                    alt={selectedChatAddress.displayName}
                    style={{ marginLeft: 10, cursor: "pointer" }}
                  />
                </Popover>
              )}
              {selectedChatAddress.userOrGroup === 0 && (
                <Avatar
                  src={require(`assets/images/users/${
                    selectedChatAddress.photoUUID
                      ? selectedChatAddress.photoUUID
                      : "default"
                  }.png`)}
                  className="gx-size-40"
                  alt={selectedChatAddress.displayName}
                  style={{ marginLeft: 10 }}
                />
              )}
              <div
                className="gx-chat-contact-name"
                style={{ display: "inline-block", paddingLeft: 10 }}
              >
                {selectedChatAddress.displayName}
              </div>
            </>
          )}
        </Col>
        <Col></Col>
        <Col></Col>
      </div>
      <div style={{ height: "80%" }}>
        {_.isEmpty(selectedChatAddress) && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div className="gx-fs-80" style={{ textAlign: "center" }}>
              <i className="icon icon-chat gx-text-muted" />
              <h3 className="gx-text-muted">
                Select User or Group to start Chat
              </h3>
            </div>
          </div>
        )}
        {!_.isEmpty(selectedChatAddress) && (
          <CustomScrollbars
            scrolltobottom={"true"}
            scroll_bottom_margin_fix={"true"}
            style={{ height: 370 }}
          >
            <ChatMessageList chatMessages={chatMessages} />
          </CustomScrollbars>
        )}
      </div>
      <div
        style={{
          height: "10%",
          paddingTop: 5,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        {!_.isEmpty(selectedChatAddress) && (
          <Input
            autoComplete="off"
            ref={input => {
              refChatInput = input;
            }}
            value={typeChatMessage}
            name="typeChatMessage"
            addonAfter={
              <div onClick={() => send()} style={{ cursor: "pointer" }}>
                <MyIcon2 type="send"></MyIcon2>
              </div>
            }
            onKeyDown={e => e.keyCode == 13 && send()}
            onChange={e => setTypeChatMessage(e.target.value)}
          />
        )}
      </div>
    </div>
  );
  // return (
  //   <div className="gx-chat-box">
  //     {selectedUser === null ? (
  //       <div className="gx-comment-box">
  //         <div className="gx-fs-80">
  //           <i className="icon icon-chat gx-text-muted" />
  //         </div>
  //         <h1 className="gx-text-muted">Select User to start Chat</h1>
  //       </div>
  //     ) : (
  //       <div className="gx-chat-main">
  //         <div className="gx-chat-main-header">
  //           <span className="gx-d-block gx-d-lg-none gx-chat-btn">
  //             <i
  //               className="gx-icon-btn icon icon-chat"
  //               // onClick={this.onToggleDrawer.bind(this)}
  //             />
  //           </span>
  //           <div className="gx-chat-main-header-info">
  //             <div className="gx-chat-avatar gx-mr-2">
  //               <div className="gx-status-pos">
  //                 <Avatar
  //                   // src={selectedUser.thumb}
  //                   className="gx-rounded-circle gx-size-60"
  //                   alt=""
  //                 />

  //                 <span className={`gx-status gx-${selectedUser.status}`} />
  //               </div>
  //             </div>

  //             <div className="gx-chat-contact-name">{selectedUser.name}</div>
  //           </div>
  //         </div>

  //         <CustomScrollbars className="gx-chat-list-scroll">
  //           <Conversation conversationData={[]} selectedUser={selectedUser} />
  //         </CustomScrollbars>

  //         <div className="gx-chat-main-footer">
  //           <div
  //             className="gx-flex-row gx-align-items-center"
  //             style={{ maxHeight: 51 }}
  //           >
  //             <div className="gx-col">
  //               <div className="gx-form-group">
  //                 <textarea
  //                   id="required"
  //                   className="gx-border-0 ant-input gx-chat-textarea"
  //                   // onKeyUp={this._handleKeyPress.bind(this)}
  //                   // onChange={this.updateMessageValue.bind(this)}
  //                   value={""}
  //                   placeholder="Type and hit enter to send message"
  //                 />
  //               </div>
  //             </div>
  //             <i
  //               className="gx-icon-btn icon icon-sent"
  //               // onClick={this.submitComment.bind(this)}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
};

const ChatAddressPanel = ({ chatAddresses }) => {
  const { searchChatAddress, chgSearchChatAddress } = useContext(MainContext);
  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "10%" }} className={"cc-bb cc-br"}>
        <SearchBox
          styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"
          placeholder="Search user or group"
          onChange={v => chgSearchChatAddress(v)}
          value={searchChatAddress}
        />
      </div>
      <div style={{ height: "90%" }} className={"cc-br"}>
        {/* <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1"> */}
        <CustomScrollbars scroll_bottom_margin_fix={"true"}>
          {chatAddresses && chatAddresses.length === 0 ? (
            <div className="gx-p-5">No user or group found</div>
          ) : (
            <ChatAddressList
              chatAddresses={chatAddresses}
              // selectedSectionId={this.state.selectedSectionId}
            />
          )}
        </CustomScrollbars>
      </div>
    </div>
    // <div className="gx-chat-sidenav-main">
    //   <div className="gx-chat-sidenav-header">
    //     <div className="gx-chat-search-wrapper">
    //       <SearchBox
    //         styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"
    //         placeholder="Search or start new chat"
    //         // onChange={this.updateSearchChatUser.bind(this)}
    //         // value={this.state.searchChatUser}
    //       />
    //     </div>
    //   </div>

    //   <div className="gx-chat-sidenav-content">
    //     {/*<AppBar position="static" className="no-shadow chat-tabs-header">*/}

    //     <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
    //       {chatAddresses && chatAddresses.length === 0 ? (
    //         <div className="gx-p-5">No user or group found</div>
    //       ) : (
    //         <ChatAddressList
    //           chatAddresses={chatAddresses}
    //           // selectedSectionId={this.state.selectedSectionId}
    //         />
    //       )}
    //     </CustomScrollbars>
    //   </div>
    // </div>
  );
};

const mapStateToProps = ({ chat, auth }) => {
  const {
    isChatPanelOpen,
    chatUnreads,
    chatAddresses,
    selectedChatAddress,
    chatMessages
  } = chat;
  const { authUser } = auth;
  return {
    isChatPanelOpen,
    chatUnreads,
    chatAddresses,
    selectedChatAddress,
    chatMessages,
    authUser
  };
};

export default connect(mapStateToProps, {
  selectChatAddress,
  getChatAddresses,
  closeChatPanel,
  sendChat,
  markChatRead
})(
  ({
    getChatAddresses,
    selectChatAddress,
    chatMessages,
    chatAddresses,
    selectedChatAddress,
    closeChatPanel,
    sendChat,
    authUser,
    chatUnreads,
    isChatPanelOpen,
    markChatRead
  }) => {
    const [loading, setLoading] = useState(false);
    const [searchChatAddress, setSearchChatAddress] = useState("");

    // return <div className={"cc-chat-panel"}>Chat</div>;

    useEffect(() => {
      setLoading(true);
      getChatAddresses();

      // return () => {
      //   // unSubChatMessageIn();
      // };
    }, []);

    useEffect(() => {
      if (chatAddresses.length > 0) {
        setLoading(false);
      }
    }, [chatAddresses]);

    useEffect(() => {
      console.log(`chatMessageIn5`, chatMessages);
    }, [chatMessages]);

    useEffect(() => {
      if (isChatPanelOpen) {
        console.log(`markChatRead1`);
        markChatRead();
      }
    }, [isChatPanelOpen]);

    const onSelectAddress = address => {
      console.log(address);
      selectChatAddress(address);
    };

    const chgSearchChatAddress = value => {
      console.log(`chgSearchChatAddress`, value, chatAddresses);
      setSearchChatAddress(value);
    };

    return (
      <div style={{ display: isChatPanelOpen ? "block" : "none" }}>
        <MainContext.Provider
          value={{
            onSelectAddress,
            selectedChatAddress,
            closeChatPanel,
            searchChatAddress,
            chgSearchChatAddress,
            loginUserId: authUser.id,
            chatUnreads
          }}
        >
          <div className="cc-chat-panel">
            <Row style={{ height: "8%" }}>
              <Col span={24}>
                <div
                  className="cc-bb"
                  style={{ height: 39, display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{ paddingLeft: 15, fontWeight: 500, fontSize: 20 }}
                  >
                    VMC Chat
                  </div>
                  <div style={{ position: "absolute", right: 25 }}>
                    <a onClick={() => closeChatPanel()}>
                      <FontAwesomeIcon
                        style={{ fontSize: 20 }}
                        icon="times"
                      ></FontAwesomeIcon>
                    </a>
                  </div>
                </div>{" "}
              </Col>
            </Row>
            <Row style={{ height: "92%" }}>
              <Col span={10} style={{ paddingRight: 0 }}>
                <ChatAddressPanel
                  chatAddresses={chatAddresses.filter(
                    chatAddress =>
                      chatAddress.displayName
                        .toLowerCase()
                        .indexOf(searchChatAddress.toLowerCase()) > -1
                  )}
                ></ChatAddressPanel>
              </Col>
              <Col
                span={14}
                style={{
                  paddingLeft: 0
                }}
              >
                {loading ? (
                  <div className="gx-loader-view">
                    <CircularProgress />
                  </div>
                ) : (
                  <Communication
                    chatMessages={chatMessages}
                    sendChat={msg => {
                      console.log(`sendChat3`, msg);
                      sendChat(msg);
                    }}
                  ></Communication>
                )}
              </Col>
            </Row>
          </div>
        </MainContext.Provider>
      </div>
    );
  }
);
