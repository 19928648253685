module.exports = {
  footerText: `Copyright CentriCore © ${new Date().getFullYear()}`,
  authClientSecret: "anyhowtype32432rewrjewlrew808979324&&((3432423",
  gqlBaseUrl: process.env.REACT_APP_GQL_BASE_URL,
  imageUploadUrl: process.env.REACT_APP_IMAGE_UPLOAD_URL,
  emailServiceUrl: process.env.REACT_APP_EMAIL_SERVICE_URL,
  imageUrl: process.env.REACT_APP_IMAGE_URL,
  gqlWebSocketBaseUrl: process.env.REACT_APP_GQL_SOCKET_BASE_URL,
  mediaItemUri: process.env.REACT_APP_MEDIA_ITEM_URI,
  mediaItemUploadUrl: process.env.REACT_APP_MEDIA_ITEM_UPLOAD_URL,
  restApiUrl: process.env.REACT_APP_REST_URL,
  mailFormatQrCode: process.env.REACT_APP_MAIL_FORMAT_QR_CODE,
  masterQuotaUrl: process.env.REACT_APP_MASTER_QUOTA_URL,
  cardTypes:{
    CSN: {
        id: "0",
        type: "1"
    },
    CSN_MOBILE:{
        id: "4",
        type: "4"
    },
    WIEGAND:{
        id: "1",
        type: "10"
    },
    QR_CODE:{
        id: "6",
        type: "6"
    }
}
};
