import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import GraphqlCli from "util/GraphqlCli";

import { connect } from "react-redux";
import {
  addChatMessage,
  getChatUnreads,
  openChatPanel
} from "appRedux/actions/Chat";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = ({ auth, chat }) => {
  const { authUser } = auth;
  const { chatUnreads } = chat;
  return { authUser, chatUnreads };
};

export default connect(mapStateToProps, {
  addChatMessage,
  getChatUnreads,
  openChatPanel
})(
  ({
    addChatMessage,
    authUser,
    chatUnreads,
    getChatUnreads,
    style,
    iconFontSize = 16,
    openChatPanel
  }) => {
    let _subChatMessageIn = null;

    const subChatMessageIn = () => {
      unSubChatMessageIn();
      console.log("subChatMessageIn", authUser.id);

      _subChatMessageIn = GraphqlCli.subscribe(
        gql`
      subscription {
        chatMessageIn(userId: ${authUser.id}) {
          id
          chatMessage
          sentDt
          sentByUserId
          sentByDisplayName
          sentByShortCode
          sentByPhotoUUID
          sentToAddressId
          sentToUserOrGroup
        }
      }
    `
      ).subscribe(r => {
        console.log(`chatMessageIn1`, r);
        addChatMessage(r.data.chatMessageIn);
      });
    };

    const unSubChatMessageIn = () => {
      console.log("unSubChatMessageIn");
      if (_subChatMessageIn) {
        _subChatMessageIn.unsubscribe();
        _subChatMessageIn = null;
      }
    };

    useEffect(() => {
      subChatMessageIn();
      getChatUnreads();
    }, []);

    return (
      <div
        className="gx-status-pos"
        style={{ ...style }}
        onClick={() => openChatPanel()}
      >
        <FontAwesomeIcon
          style={{ fontSize: iconFontSize }}
          icon={faComments}
        ></FontAwesomeIcon>
        {chatUnreads.length > 0 && (
          <span className="gx-status gx-online" style={{ top: -3, left: 13 }} />
        )}
      </div>
    );
  }
);
