import {
  VMC_VR_VISITOR_TYPE_REQUEST,
  VMC_VR_VISITOR_TYPE_SUCCESS,
  VMC_VR_VISITOR_TYPE_ERROR,
  // VMC_VR_VISITOR_TYPE_CLEAR,
  VMC_VR_SEARCH_VISITOR_REG_REQUEST,
  VMC_VR_SEARCH_VISITOR_REG_SUCCESS,
  VMC_VR_SEARCH_VISITOR_REG_ERROR,
  VMC_VR_GET_VISITOR_REG_BY_ID_REQUEST,
  VMC_VR_GET_VISITOR_REG_BY_ID_SUCCESS,
  VMC_VR_GET_VISITOR_REG_BY_ID_ERROR,
  VMC_VR_SAVE_VISITOR_REG_REQUEST,
  VMC_VR_SAVE_VISITOR_REG_SUCCESS,
  VMC_VR_SAVE_VISITOR_REG_ERROR,
  VMC_VR_SAVE_PATIENT_REG_REQUEST,
  VMC_VR_SAVE_PATIENT_REG_SUCCESS,
  VMC_VR_SAVE_PATIENT_REG_ERROR,
  VMC_VR_SAVE_STAFF_REG_REQUEST,
  VMC_VR_SAVE_STAFF_REG_SUCCESS,
  VMC_VR_SAVE_STAFF_REG_ERROR,
  VMC_VR_SAVE_TENANT_REG_REQUEST,
  VMC_VR_SAVE_TENANT_REG_SUCCESS,
  VMC_VR_SAVE_TENANT_REG_ETENANT,
  VMC_VR_COMPANY_DATA_REQUEST,
  VMC_VR_COMPANY_DATA_SUCCESS,
  VMC_VR_COMPANY_DATA_ERROR,
  VMC_VR_VISITOR_REG_LIST_REQUEST,
  VMC_VR_VISITOR_REG_LIST_SUCCESS,
  VMC_VR_VISITOR_REG_LIST_ERROR,
  VMC_VR_VISITOR_REG_LIST_CLEAR,
  VMC_VR_GET_QR_REQUEST,
  VMC_VR_GET_QR_SUCCESS,
  VMC_VR_GET_QR_ERROR,
  VMC_VR_SEARCH_STAFF_REG_REQUEST,
  VMC_VR_SEARCH_STAFF_REG_SUCCESS,
  VMC_VR_SEARCH_STAFF_REG_ERROR,
  VMC_VR_SEARCH_TENANT_REG_REQUEST,
  VMC_VR_SEARCH_TENANT_REG_SUCCESS,
  VMC_VR_SEARCH_TENANT_REG_ERROR,
  VMC_VR_GET_STAFF_REG_BY_ID_REQUEST,
  VMC_VR_GET_STAFF_REG_BY_ID_SUCCESS,
  VMC_VR_GET_STAFF_REG_BY_ID_ERROR,
  VMC_VR_GET_STAFF_REG_BY_ID_CLEAR,
  VMC_VR_STAFF_REG_LIST_REQUEST,
  VMC_VR_STAFF_REG_LIST_SUCCESS,
  VMC_VR_STAFF_REG_LIST_ERROR,
  VMC_VR_STAFF_REG_LIST_CLEAR,
  VMC_VR_GET_TENANT_REG_BY_ID_REQUEST,
  VMC_VR_GET_TENANT_REG_BY_ID_SUCCESS,
  VMC_VR_GET_TENANT_REG_BY_ID_ERROR,
  VMC_VR_TENANT_REG_LIST_REQUEST,
  VMC_VR_TENANT_REG_LIST_SUCCESS,
  VMC_VR_TENANT_REG_LIST_ERROR,
  VMC_VR_TENANT_REG_LIST_CLEAR,
  VMC_VR_SAVE_TENANT_REG_ERROR,
  VMC_VR_GET_PATIENT_REG_BY_ID_REQUEST,
  VMC_VR_GET_PATIENT_REG_BY_ID_SUCCESS,
  VMC_VR_GET_PATIENT_REG_BY_ID_ERROR,
  VMC_VR_SEARCH_PATIENT_REG_REQUEST,
  VMC_VR_SEARCH_PATIENT_REG_SUCCESS,
  VMC_VR_SEARCH_PATIENT_REG_ERROR,
  VMC_VR_PATIENT_TYPE_REQUEST,
  VMC_VR_PATIENT_TYPE_SUCCESS,
  VMC_VR_PATIENT_TYPE_ERROR,
  VMC_VR_PATIENT_REG_LIST_REQUEST,
  VMC_VR_PATIENT_REG_LIST_SUCCESS,
  VMC_VR_PATIENT_REG_LIST_ERROR,
  VMC_VR_PATIENT_REG_LIST_CLEAR
} from "constants/ActionTypes";
import gql from "graphql-tag";
import { store } from "../../NextApp";
import GraphqlCli from "util/GraphqlCli";
import constants from "../../constants";
import { CALL_API } from "../../middleware/api";

const createFetchErrorPayload = action_name =>
  "( " + action_name + " ) Fetch error.";

const apiAction = ({
  path = "visitorReg?hello",
  method = "query",
  gql = null,
  variables = {},
  types = []
}) => ({
  [CALL_API]: {
    path: path,
    method: method,
    gql: gql,
    variables: variables,
    types: types
  }
});

export const fetchVisitorTypesAC = locationId => {
  const _gql = gql`
    query($locationId: Int) {
      data: getVisitorType(locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          visitorTypeName
          visitorTypeCode
        }
      }
    }
  `;

  // return {
  //   [CALL_API]: {
  //     path: "visitorReg",
  //     method: "query",
  //     gql: _gql,
  //     variables: {
  //       locationId: locationId
  //     },
  //     types: [
  //       VMC_VR_VISITOR_TYPE_REQUEST,
  //       VMC_VR_VISITOR_TYPE_SUCCESS,
  //       VMC_VR_VISITOR_TYPE_ERROR
  //     ]
  //   }
  // };
  return apiAction({
    path: "visitorReg?getVisitorType",
    gql: _gql,
    variables: {
      locationId: locationId
    },
    types: [
      VMC_VR_VISITOR_TYPE_REQUEST,
      VMC_VR_VISITOR_TYPE_SUCCESS,
      VMC_VR_VISITOR_TYPE_ERROR
    ]
  });
};

export const fetchPatientTypesAC = locationId => {
  const _gql = gql`
    query($locationId: Int) {
      data: getPatientType(locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          patientTypeName
          patientTypeCode
        }
      }
    }
  `;
  return apiAction({
    path: "visitorReg?getPatientType",
    gql: _gql,
    variables: {
      locationId: locationId
    },
    types: [
      VMC_VR_PATIENT_TYPE_REQUEST,
      VMC_VR_PATIENT_TYPE_SUCCESS,
      VMC_VR_PATIENT_TYPE_ERROR
    ]
  });
};

export const loadVisitorTypesAC = () => (dispatch, getState) => {
  const { visitorType } = getState();
  if (visitorType && visitorType.data && visitorType.data.length > 0) {
    return null;
  }

  return dispatch(
    fetchVisitorTypesAC(getState().auth.selectedLocation.locationId)
  );
};

export const loadPatientTypesAC = () => (dispatch, getState) => {
  const { patientType } = getState();
  if (patientType && patientType.data && patientType.data.length > 0) {
    return null;
  }

  return dispatch(
    fetchPatientTypesAC(getState().auth.selectedLocation.locationId)
  );
};

export const searchVisitorRegAC = regId => (dispatch, getState) => {
  const _gql = gql`
    query($regId: String, $locationId: Int) {
      data: searchVisitorReg(regId: $regId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          visId
          visName
          visIdentity
          contactNo
          vehicleNo
          visTypeId
          locationId
          visRegId
          companyDisplayName
          towerDisplayName
          lobbyDisplayName
          levelUnitDesc
          passNo
          regGroup
          imageUrl
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      path: "visitorReg?searchVisitorReg",
      gql: _gql,
      variables: {
        regId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_SEARCH_VISITOR_REG_REQUEST,
        VMC_VR_SEARCH_VISITOR_REG_SUCCESS,
        VMC_VR_SEARCH_VISITOR_REG_ERROR
      ]
    })
  );
};

export const getVisitorRegByIdAC = visRegId => (dispatch, getState) => {
  const _gql = gql`
    query($visRegId: Int, $locationId: Int) {
      data: getVisitorReg(visRegId: $visRegId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          visId
          passNo
          passType
          dateOfExpire
          regDate
          remark
          locationId
          recordStatus
          isBlackList
          blackListRemark
          antiPassback
          regId
          personnel {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            visTypeId
            locationId
            recordStatus
            imageUrl
            nationalityId
          }
          hostDetails {
            id
            visRegId
            purposeOfVisit
            recordStatus
            companyId
            companyDisplayName
            towerId
            towerDisplayName
            lobbyId
            lobbyDisplayName
            levelUnitDesc
            unitIds
          }
          questionaireList {
            id
            visRegId
            questionaireId
            answerId
            weight
            createdId
            updatedId
            questionaireTypeId
            questionaireTypeTitleEn
            questionaireTitleEn
            questionaireEn
            answerEn
            answerWeight
          }
        }
      }
    }
  `;

  return dispatch(
    apiAction({
      path: "visitorReg?getVisitorReg",
      gql: _gql,
      variables: {
        visRegId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_GET_VISITOR_REG_BY_ID_REQUEST,
        VMC_VR_GET_VISITOR_REG_BY_ID_SUCCESS,
        VMC_VR_GET_VISITOR_REG_BY_ID_ERROR
      ]
    })
  );
};

export const getStaffRegByIdAC = staRegId => (dispatch, getState) => {
  const _gql = gql`
    query($staRegId: Int, $locationId: Int) {
      data: getStaffReg(staRegId: $staRegId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          #personnelId
          passNo
          passType
          #dateOfBirth
          dateOfExpire
          regDate
          remark
          locationId
          recordStatus
          isBlackList
          blackListRemark
          antiPassback
          regId
          departmentId
          personnel {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            visTypeId
            locationId
            recordStatus
            imageUrl
            dateOfBirth
            visitorEmail
          }
          hostDetails {
            id
            staRegId
            purposeOfVisit
            recordStatus
            companyId
            companyDisplayName
            towerId
            towerDisplayName
            lobbyId
            lobbyDisplayName
            levelUnitDesc
            unitIds
          }
        }
      }
    }
  `;

  return dispatch(
    apiAction({
      path: "visitorReg?getStaffReg",
      gql: _gql,
      variables: {
        staRegId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_GET_STAFF_REG_BY_ID_REQUEST,
        VMC_VR_GET_STAFF_REG_BY_ID_SUCCESS,
        VMC_VR_GET_STAFF_REG_BY_ID_ERROR
      ]
    })
  );
};

export const saveVisitorRegAC = visitor_reg => {
  const _gql = gql`
    mutation VisitorRegistration($post: VisitorRegInput) {
      data: visitorRegistration(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          visitor {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            visTypeId
            locationId
            recordStatus
            imageUrl
            nationalityId
          }
          reg {
            id
            visId
            passNo
            passType
            startDate
            endDate
            remark
            locationId
            recordStatus
            isBlackList
            blackListRemark
            antiPassback
            regId
            regDate
            temperature
          }
          hostDetails {
            id
            visRegId
            companyId
            towerId
            lobbyId
            unitIds
            levelUnitDesc
            purposeOfVisit
            recordStatus
            locationId
            companyDisplayName
            towerDisplayName
            lobbyDisplayName
          }
          questionaireList {
            id
            visRegId
            questionaireId
            answerId
            weight
            createdId
            updatedId
            questionaireTypeId
            questionaireTypeTitleEn
            questionaireTitleEn
            questionaireEn
            answerEn
            answerWeight
          }
        }
      }
    }
  `;

  const { visitor, reg, hostDetails, questionaireList } = visitor_reg;

  return apiAction({
    path: "visitorReg?visitorRegistration",
    method: "mutate",
    gql: _gql,
    variables: {
      post: {
        visitor: {
          id: visitor.id,
          visName: visitor.visName,
          visIdentity: visitor.visIdentity,
          contactNo: visitor.contactNo,
          vehicleNo: visitor.vehicleNo,
          visTypeId: visitor.visTypeId,
          locationId: visitor.locationId,
          recordStatus: visitor.recordStatus,
          createdId: visitor.createdId,
          updatedId: visitor.updatedId,
          imageUrl: visitor.imageUrl,
          nationalityId: visitor.nationalityId
        },
        reg: {
          id: reg.id,
          visId: visitor.id,
          passNo: reg.passNo,
          oldPassNo: reg.oldPassNo,
          passType: reg.passType,
          startDate: reg.startDate.format("YYYY-MM-DD HH:mm:ss.SSS"),
          endDate: reg.endDate.format("YYYY-MM-DD HH:mm:ss.SSS"),
          remark: reg.remark,
          locationId: reg.locationId,
          recordStatus: reg.recordStatus,
          createdId: reg.createdId,
          updatedId: reg.updatedId,
          isBlackList: reg.isBlackList,
          blackListRemark: reg.blackListRemark,
          antiPassback: reg.antiPassback,
          regId: reg.regId,
          regDate: reg.regDate,
          prevIsBlackList: reg.prevIsBlackList,
          temperature: reg.temperature
        },
        hostDetails: hostDetails.map(row => ({
          id: row.id,
          visRegId: reg.id,
          companyId: row.companyId,
          purposeOfVisit: row.purposeOfVisit,
          recordStatus: row.recordStatus,
          createdId: row.createdId,
          updatedId: row.updatedId,
          locationId: row.locationId,
          towerId: row.towerId,
          lobbyId: row.lobbyId,
          unitIds: row.unitIds,
          levelUnitDesc: row.levelUnitDesc,
          companyDisplayName: row.companyDisplayName,
          towerDisplayName: row.towerDisplayName,
          lobbyDisplayName: row.lobbyDisplayName
        })),
        questionaireList: questionaireList.map(row => ({
          id: row.id,
          visRegId: row.visRegId,
          questionaireId: row.questionaireId,
          answerId: row.answerId,
          weight: row.weight,
          createdId: row.createdId,
          updatedId: row.updatedId
        }))
      }
    },
    types: [
      VMC_VR_SAVE_VISITOR_REG_REQUEST,
      VMC_VR_SAVE_VISITOR_REG_SUCCESS,
      VMC_VR_SAVE_VISITOR_REG_ERROR
    ]
  });
};

export const savePatientRegAC = visitor_reg => {
  const _gql = gql`
    mutation PatientRegistration($post: PatientRegInput) {
      data: patientRegistration(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          visitor {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            patTypeId
            locationId
            recordStatus
            imageUrl
            nationalityId
          }
          reg {
            id
            patId
            passNo
            passType
            startDate
            endDate
            remark
            locationId
            recordStatus
            isBlackList
            blackListRemark
            antiPassback
            regId
            regDate
            temperature
          }
          hostDetails {
            id
            patRegId
            companyId
            towerId
            lobbyId
            unitIds
            levelUnitDesc
            purposeOfVisit
            recordStatus
            locationId
            companyDisplayName
            towerDisplayName
            lobbyDisplayName
          }
        }
      }
    }
  `;

  const { visitor, reg, hostDetails } = visitor_reg;

  return apiAction({
    path: "visitorReg?patientRegistration",
    method: "mutate",
    gql: _gql,
    variables: {
      post: {
        visitor: {
          id: visitor.id,
          visName: visitor.visName,
          visIdentity: visitor.visIdentity,
          contactNo: visitor.contactNo,
          vehicleNo: visitor.vehicleNo,
          patTypeId: visitor.patTypeId,
          locationId: visitor.locationId,
          recordStatus: visitor.recordStatus,
          createdId: visitor.createdId,
          updatedId: visitor.updatedId,
          imageUrl: visitor.imageUrl,
          nationalityId: visitor.nationalityId
        },
        reg: {
          id: reg.id,
          patId: visitor.id,
          passNo: reg.passNo,
          passType: reg.passType,
          startDate: reg.startDate.format("YYYY-MM-DD HH:mm:ss.SSS"),
          endDate: reg.endDate.format("YYYY-MM-DD HH:mm:ss.SSS"),
          remark: reg.remark,
          locationId: reg.locationId,
          recordStatus: reg.recordStatus,
          createdId: reg.createdId,
          updatedId: reg.updatedId,
          isBlackList: reg.isBlackList,
          blackListRemark: reg.blackListRemark,
          antiPassback: reg.antiPassback,
          regId: reg.regId,
          regDate: reg.regDate,
          prevIsBlackList: reg.prevIsBlackList,
          temperature: reg.temperature
        },
        hostDetails: hostDetails.map(row => ({
          id: row.id,
          patRegId: reg.id,
          companyId: row.companyId,
          purposeOfVisit: row.purposeOfVisit,
          recordStatus: row.recordStatus,
          createdId: row.createdId,
          updatedId: row.updatedId,
          locationId: row.locationId,
          towerId: row.towerId,
          lobbyId: row.lobbyId,
          unitIds: row.unitIds,
          levelUnitDesc: row.levelUnitDesc,
          companyDisplayName: row.companyDisplayName,
          towerDisplayName: row.towerDisplayName,
          lobbyDisplayName: row.lobbyDisplayName
        }))
      }
    },
    types: [
      VMC_VR_SAVE_PATIENT_REG_REQUEST,
      VMC_VR_SAVE_PATIENT_REG_SUCCESS,
      VMC_VR_SAVE_PATIENT_REG_ERROR
    ]
  });
};

export const getPatientRegByIdAC = patRegId => (dispatch, getState) => {
  const _gql = gql`
    query($patRegId: Int, $locationId: Int) {
      data: getPatientReg(patRegId: $patRegId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          personnelId
          passNo
          passType
          startDate
          endDate
          regDate
          remark
          locationId
          recordStatus
          isBlackList
          blackListRemark
          antiPassback
          regId
          temperature
          visitor {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            patTypeId
            locationId
            recordStatus
            imageUrl
            nationalityId
          }
          hostDetails {
            id
            patRegId
            purposeOfVisit
            recordStatus
            companyId
            companyDisplayName
            towerId
            towerDisplayName
            lobbyId
            lobbyDisplayName
            levelUnitDesc
            unitIds
          }
        }
      }
    }
  `;

  return dispatch(
    apiAction({
      path: "visitorReg?getPatientReg",
      gql: _gql,
      variables: {
        patRegId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_GET_PATIENT_REG_BY_ID_REQUEST,
        VMC_VR_GET_PATIENT_REG_BY_ID_SUCCESS,
        VMC_VR_GET_PATIENT_REG_BY_ID_ERROR
      ]
    })
  );
};

export const getCompanyGroupByTowerAndLobbyAC = profile => (
  dispatch,
  getState
) => {
  const _gql = gql`
    query($locationId: Int, $profile: Int) {
      data: getCompanyGroupByTowerAndLobby(
        locationId: $locationId
        profile: $profile
      ) {
        status
        error {
          message
          stack
          name
        }
        data {
          companyId
          companyDisplayName
          towerId
          towerDisplayName
          lobbyId
          lobbyDisplayName
          levelUnitDesc
          unitIds
        }
      }
    }
  `;

  return dispatch(
    apiAction({
      path: "visitorReg?getCompanyGroupByTowerAndLobby",
      gql: _gql,
      variables: {
        locationId: getState().auth.selectedLocation.locationId,
        profile
      },
      types: [
        VMC_VR_COMPANY_DATA_REQUEST,
        VMC_VR_COMPANY_DATA_SUCCESS,
        VMC_VR_COMPANY_DATA_ERROR
      ]
    })
  );
};

export const getVisitorRegListAC = (
  regId,
  contactNo,
  visName,
  regDate,
  dateOfExpire,
  cardId,
  locationId,
  workPermitNo,
  inOut,
  visCompanyName,
  isBlackList,
  userRoleId
) => {
  const _gql = gql`
    query($post: SearchVisitorInput) {
      data: getVisitorRegListV3(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          visRegId
          visName
          visIdentity
          regId
          vehicleNo
          visTypeId
          locationId
          visId
          purposeOfVisit
          passNo
          passType
          dateOfExpire
          recordStatus
          regDt
          createdBy
          updatedBy
          dateOfExpire
          controlFlag
          createdDt
          updatedDt
          cardId
          cardType
          isBlackList
        }
      }
    }
  `;

  return apiAction({
    path: "visitorReg?getVisitorRegList",
    gql: _gql,
    variables: {
      post: {
        regId: regId,
        contactNo: contactNo,
        visName: visName,
        regDate: regDate,
        dateOfExpire: dateOfExpire,
        cardId: cardId,
        locationId: locationId,
        workPermitNo: workPermitNo,
        inOut: inOut,
        visCompanyName: visCompanyName,
        isBlackList: isBlackList,
        userRoleId: userRoleId
      }
    },
    types: [
      VMC_VR_VISITOR_REG_LIST_REQUEST,
      VMC_VR_VISITOR_REG_LIST_SUCCESS,
      VMC_VR_VISITOR_REG_LIST_ERROR
    ]
  });
};

export const getPatientRegListAC = (
  regId,
  contactNo,
  visName,
  startDate,
  endDate,
  locationId
) => {
  const _gql = gql`
    query($post: SearchPatientInput) {
      data: getPatientRegList(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          visRegId
          visName
          visIdentity
          regId
          vehicleNo
          visTypeId
          locationId
          visId
          purposeOfVisit
          passNo
          passType
          startDate
          endDate
          recordStatus
          createdId
          updatedId
          regDate
          regTime
          deviceName
          tranDate
          tranTime
          tranDesc
          tranCode
        }
      }
    }
  `;

  return apiAction({
    path: "visitorReg?getPatientRegList",
    gql: _gql,
    variables: {
      post: {
        regId: regId,
        contactNo: contactNo,
        visName: visName,
        startDate: startDate,
        endDate: endDate,
        locationId: locationId
      }
    },
    types: [
      VMC_VR_PATIENT_REG_LIST_REQUEST,
      VMC_VR_PATIENT_REG_LIST_SUCCESS,
      VMC_VR_PATIENT_REG_LIST_ERROR
    ]
  });
};

export const getQRAC = () => (dispatch, getState) => {
  const _gql = gql`
    query($locationId: Int) {
      data: getQrNumber(locationId: $locationId) {
        status
        error {
          message
          stack
          name
        }
        data {
          sequenceAt
          affectedRow
          qrCode
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      path: "visitorReg?getQrNumber",
      gql: _gql,
      variables: {
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [VMC_VR_GET_QR_REQUEST, VMC_VR_GET_QR_SUCCESS, VMC_VR_GET_QR_ERROR]
    })
  );
};

export const clearVisitorRegListAC = () => ({
  type: VMC_VR_VISITOR_REG_LIST_CLEAR
});

export const clearPatientRegListAC = () => ({
  type: VMC_VR_PATIENT_REG_LIST_CLEAR
});

export const saveStaffRegAC = visitor_reg => {
  const _gql = gql`
    mutation StaffRegistration($post: StaffRegInput) {
      data: staffRegistration(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          personnel {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            visTypeId
            locationId
            recordStatus
            imageUrl
            dateOfBirth
            visitorEmail
          }
          reg {
            id
            personnelId
            passNo
            passType
            dateOfResign
            remark
            locationId
            recordStatus
            isBlackList
            blackListRemark
            antiPassback
            regId
            regDate
            departmentId
          }
          hostDetails {
            id
            staRegId
            companyId
            towerId
            lobbyId
            unitIds
            levelUnitDesc
            purposeOfVisit
            recordStatus
            locationId
            companyDisplayName
            towerDisplayName
            lobbyDisplayName
          }
        }
      }
    }
  `;

  console.dir(visitor_reg);
  const { personnel, reg, hostDetails } = visitor_reg;

  return apiAction({
    path: "visitorReg?staffRegistration",
    method: "mutate",
    gql: _gql,
    variables: {
      post: {
        personnel: {
          id: personnel.id,
          visName: personnel.visName,
          visIdentity: personnel.visIdentity,
          contactNo: personnel.contactNo,
          vehicleNo: personnel.vehicleNo,
          visTypeId: personnel.visTypeId,
          locationId: personnel.locationId,
          recordStatus: personnel.recordStatus,
          createdId: personnel.createdId,
          updatedId: personnel.updatedId,
          imageUrl: personnel.imageUrl,
          dateOfBirth: personnel.dateOfBirth
            ? personnel.dateOfBirth.format("YYYY-MM-DD HH:mm:ss.SSS")
            : null,
          visitorEmail: personnel.visitorEmail
        },
        reg: {
          id: reg.id,
          personnelId: personnel.id,
          passNo: reg.passNo,
          oldPassNo: reg.oldPassNo,
          passType: reg.passType,
          //dateOfBirth: reg.dateOfBirth.format("YYYY-MM-DD HH:mm:ss.SSS"),
          // dateOfResign: reg.dateOfResign
          //   ? `${reg.dateOfResign.format("YYYY-MM-DD")} 23:59:59.000`
          //   : null,
          dateOfResign: reg.dateOfResign
            ? reg.dateOfResign.format("YYYY-MM-DD  HH:mm:ss.sss")
            : null,
          remark: reg.remark,
          locationId: reg.locationId,
          recordStatus: reg.recordStatus,
          createdId: reg.createdId,
          updatedId: reg.updatedId,
          isBlackList: reg.isBlackList,
          blackListRemark: reg.blackListRemark,
          antiPassback: reg.antiPassback,
          regId: reg.regId,
          regDate: reg.regDate,
          prevIsBlackList: reg.prevIsBlackList,
          departmentId: reg.departmentId
        },
        hostDetails: hostDetails.map(row => ({
          id: row.id,
          staRegId: reg.id,
          companyId: row.companyId,
          purposeOfVisit: row.purposeOfVisit,
          recordStatus: row.recordStatus,
          createdId: row.createdId,
          updatedId: row.updatedId,
          locationId: row.locationId,
          towerId: row.towerId,
          lobbyId: row.lobbyId,
          unitIds: row.unitIds,
          levelUnitDesc: row.levelUnitDesc,
          companyDisplayName: row.companyDisplayName,
          towerDisplayName: row.towerDisplayName,
          lobbyDisplayName: row.lobbyDisplayName
        }))
      }
    },
    types: [
      VMC_VR_SAVE_STAFF_REG_REQUEST,
      VMC_VR_SAVE_STAFF_REG_SUCCESS,
      VMC_VR_SAVE_STAFF_REG_ERROR
    ]
  });
};

export const searchStaffRegAC = regId => (dispatch, getState) => {
  const _gql = gql`
    query($regId: String, $locationId: Int) {
      data: searchStaffReg(regId: $regId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          visId
          visName
          visIdentity
          contactNo
          vehicleNo
          visTypeId
          locationId
          staRegId
          companyDisplayName
          towerDisplayName
          lobbyDisplayName
          levelUnitDesc
          passNo
          regGroup
          imageUrl
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      path: "visitorReg?searchStaffReg",
      gql: _gql,
      variables: {
        regId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_SEARCH_STAFF_REG_REQUEST,
        VMC_VR_SEARCH_STAFF_REG_SUCCESS,
        VMC_VR_SEARCH_STAFF_REG_ERROR
      ]
    })
  );
};

export const searchTenantRegAC = regId => (dispatch, getState) => {
  const _gql = gql`
    query($regId: String, $locationId: Int) {
      data: searchTenantReg(regId: $regId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          visId
          visName
          visIdentity
          contactNo
          vehicleNo
          visTypeId
          locationId
          tenRegId
          companyDisplayName
          towerDisplayName
          lobbyDisplayName
          levelUnitDesc
          passNo
          regGroup
          imageUrl
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      path: "visitorReg?searchTenantReg",
      gql: _gql,
      variables: {
        regId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_SEARCH_TENANT_REG_REQUEST,
        VMC_VR_SEARCH_TENANT_REG_SUCCESS,
        VMC_VR_SEARCH_TENANT_REG_ERROR
      ]
    })
  );
};

export const getStaffRegListAC = (
  regId,
  contactNo,
  visName,
  regDate,
  dateOfExpire,
  cardId,
  locationId,
  userRoleId,
  staffId
) => {
  const _gql = gql`
    query($post: SearchStaffInput) {
      data: getStaffRegList(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          staRegId
          visName
          visIdentity
          regId
          vehicleNo
          visTypeId
          locationId
          personnelId
          purposeOfVisit
          passNo
          passType
          dateOfExpire
          recordStatus
          createdBy
          updatedBy
          createdDt
          updatedDt
          regDt
          deptName
          cardId
          cardType
          # laneIn
          # dateIn
          # timeIn
          # laneOut
          # dateOut
          # timeOut
        }
      }
    }
  `;

  return apiAction({
    path: "visitorReg?getStaffRegList",
    gql: _gql,
    variables: {
      post: {
        regId: regId,
        contactNo: contactNo,
        visName: visName,
        regDate: regDate,
        dateOfExpire: dateOfExpire,
        cardId: cardId,
        locationId: locationId,
        userRoleId: userRoleId,
        staffId: staffId
      }
    },
    types: [
      VMC_VR_STAFF_REG_LIST_REQUEST,
      VMC_VR_STAFF_REG_LIST_SUCCESS,
      VMC_VR_STAFF_REG_LIST_ERROR
    ]
  });
};

export const getTenantRegListAC = (
  regId,
  contactNo,
  visName,
  regDate,
  dateOfExpire,
  cardId,
  locationId,
  userRoleId
) => {
  const _gql = gql`
    query($post: SearchTenantInput) {
      data: getTenantRegList(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          tenRegId
          visName
          visIdentity
          regId
          vehicleNo
          visTypeId
          locationId
          personnelId
          purposeOfVisit
          passNo
          passType
          dateOfExpire
          recordStatus
          createdBy
          updatedBy
          createdDt
          updatedDt
          regDt
          deptName
          cardId
          cardType
          # laneIn
          # dateIn
          # timeIn
          # laneOut
          # dateOut
          # timeOut
        }
      }
    }
  `;

  return apiAction({
    path: "visitorReg?getTenantRegList",
    gql: _gql,
    variables: {
      post: {
        regId: regId,
        contactNo: contactNo,
        visName: visName,
        regDate: regDate,
        dateOfExpire: dateOfExpire,
        cardId:cardId,
        locationId: locationId,
        userRoleId: userRoleId
      }
    },
    types: [
      VMC_VR_TENANT_REG_LIST_REQUEST,
      VMC_VR_TENANT_REG_LIST_SUCCESS,
      VMC_VR_TENANT_REG_LIST_ERROR
    ]
  });
};

export const clearStaffRegListAC = () => ({
  type: VMC_VR_STAFF_REG_LIST_CLEAR
});

export const clearTenantRegListAC = () => ({
  type: VMC_VR_TENANT_REG_LIST_CLEAR
});

// export const searchTenantRegAC = regId => (dispatch, getState) => {
//   const _gql = gql`
//     query($regId: String, $locationId: Int) {
//       data: searchTenantReg(regId: $regId, locationId: $locationId) {
//         status
//         error {
//           message
//         }
//         data {
//           visId
//           visName
//           visIdentity
//           contactNo
//           vehicleNo
//           visTypeId
//           locationId
//           tenRegId
//           companyDisplayName
//           towerDisplayName
//           lobbyDisplayName
//           levelUnitDesc
//           passNo
//           regGroup
//           imageUrl
//         }
//       }
//     }
//   `;
//   return dispatch(
//     apiAction({
//       path: "visitorReg?searchTenantReg",
//       gql: _gql,
//       variables: {
//         regId,
//         locationId: getState().auth.selectedLocation.locationId
//       },
//       types: [
//         VMC_VR_SEARCH_TENANT_REG_REQUEST,
//         VMC_VR_SEARCH_TENANT_REG_SUCCESS,
//         VMC_VR_SEARCH_TENANT_REG_ERROR
//       ]
//     })
//   );
// };

export const saveTenantRegAC = visitor_reg => {
  const _gql = gql`
    mutation TenantRegistration($post: TenantRegInput) {
      data: tenantRegistration(post: $post) {
        status
        error {
          message
          stack
          name
        }
        data {
          personnel {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            locationId
            recordStatus
            imageUrl
            visitorEmail
          }
          reg {
            id
            personnelId
            passNo
            passType
            dateOfBirth
            dateOfExpire
            remark
            locationId
            recordStatus
            isBlackList
            blackListRemark
            antiPassback
            regId
            regDate
            departmentId
          }
          hostDetails {
            id
            tenRegId
            companyId
            towerId
            lobbyId
            unitIds
            levelUnitDesc
            purposeOfVisit
            recordStatus
            locationId
            companyDisplayName
            towerDisplayName
            lobbyDisplayName
          }
        }
      }
    }
  `;

  console.dir(visitor_reg);
  const { personnel, reg, hostDetails } = visitor_reg;

  return apiAction({
    path: "visitorReg?tenantRegistration",
    method: "mutate",
    gql: _gql,
    variables: {
      post: {
        personnel: {
          id: personnel.id,
          visName: personnel.visName,
          visIdentity: personnel.visIdentity,
          contactNo: personnel.contactNo,
          vehicleNo: personnel.vehicleNo,
          locationId: personnel.locationId,
          recordStatus: personnel.recordStatus,
          createdId: personnel.createdId,
          updatedId: personnel.updatedId,
          imageUrl: personnel.imageUrl,
          visitorEmail: personnel.visitorEmail
        },
        reg: {
          id: reg.id,
          personnelId: personnel.id,
          passNo: reg.passNo,
          oldPassNo: reg.oldPassNo,
          passType: reg.passType,
          dateOfBirth: reg.dateOfBirth
            ? reg.dateOfBirth.format("YYYY-MM-DD HH:mm:ss.SSS")
            : null,
          // dateOfExpire: reg.dateOfExpire
          //   ? `${reg.dateOfExpire.format("YYYY-MM-DD")} 23:59:59.000`
          //   : null,
          dateOfExpire: reg.dateOfExpire
            ? reg.dateOfExpire.format("YYYY-MM-DD HH:mm:ss.SSS")
            : null,
          remark: reg.remark,
          locationId: reg.locationId,
          recordStatus: reg.recordStatus,
          createdId: reg.createdId,
          updatedId: reg.updatedId,
          isBlackList: reg.isBlackList,
          blackListRemark: reg.blackListRemark,
          antiPassback: reg.antiPassback,
          regId: reg.regId,
          regDate: reg.regDate,
          prevIsBlackList: reg.prevIsBlackList,
          departmentId: reg.departmentId
        },
        hostDetails: hostDetails.map(row => ({
          id: row.id,
          tenRegId: reg.id,
          companyId: row.companyId,
          purposeOfVisit: row.purposeOfVisit,
          recordStatus: row.recordStatus,
          createdId: row.createdId,
          updatedId: row.updatedId,
          locationId: row.locationId,
          towerId: row.towerId,
          lobbyId: row.lobbyId,
          unitIds: row.unitIds,
          levelUnitDesc: row.levelUnitDesc,
          companyDisplayName: row.companyDisplayName,
          towerDisplayName: row.towerDisplayName,
          lobbyDisplayName: row.lobbyDisplayName
        }))
      }
    },
    types: [
      VMC_VR_SAVE_TENANT_REG_REQUEST,
      VMC_VR_SAVE_TENANT_REG_SUCCESS,
      VMC_VR_SAVE_TENANT_REG_ERROR
    ]
  });
};

export const getTenantRegByIdAC = tenRegId => (dispatch, getState) => {
  const _gql = gql`
    query($tenRegId: Int, $locationId: Int) {
      data: getTenantReg(tenRegId: $tenRegId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          personnelId
          passNo
          passType
          dateOfBirth
          dateOfExpire
          regDate
          remark
          locationId
          recordStatus
          isBlackList
          blackListRemark
          antiPassback
          regId
          departmentId
          personnel {
            id
            visName
            visIdentity
            contactNo
            vehicleNo
            visTypeId
            locationId
            recordStatus
            imageUrl
            visitorEmail
          }
          hostDetails {
            id
            tenRegId
            purposeOfVisit
            recordStatus
            companyId
            companyDisplayName
            towerId
            towerDisplayName
            lobbyId
            lobbyDisplayName
            levelUnitDesc
            unitIds
          }
        }
      }
    }
  `;

  return dispatch(
    apiAction({
      path: "visitorReg?getTenantReg",
      gql: _gql,
      variables: {
        tenRegId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_GET_TENANT_REG_BY_ID_REQUEST,
        VMC_VR_GET_TENANT_REG_BY_ID_SUCCESS,
        VMC_VR_GET_TENANT_REG_BY_ID_ERROR
      ]
    })
  );
};

export const searchPatientRegAC = regId => (dispatch, getState) => {
  const _gql = gql`
    query($regId: String, $locationId: Int) {
      data: searchPatientReg(regId: $regId, locationId: $locationId) {
        status
        error {
          message
        }
        data {
          visId
          visName
          visIdentity
          contactNo
          vehicleNo
          patTypeId
          locationId
          visRegId
          companyDisplayName
          towerDisplayName
          lobbyDisplayName
          levelUnitDesc
          passNo
          regGroup
          imageUrl
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      path: "visitorReg?searchPatientReg",
      gql: _gql,
      variables: {
        regId,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_VR_SEARCH_PATIENT_REG_REQUEST,
        VMC_VR_SEARCH_PATIENT_REG_SUCCESS,
        VMC_VR_SEARCH_PATIENT_REG_ERROR
      ]
    })
  );
};
