import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import VisitorReg from "./VisitorReg";
import UserInfo from "./User";
import DashboardConsole from "./DashboardConsole";
import Chat from "./Chat";
import KioskConsole from "./KioskConsole";
import VmcManager from "./VmcManager";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  commonData: Common,
  visitorRegV2: VisitorReg,
  dashboardConsole: DashboardConsole,
  UserInfo,
  chat: Chat,
  kioskConsole: KioskConsole,
  vmcManager: VmcManager
});

export default reducers;
