import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getServiceList, getKioskDevice } from "appRedux/actions/KioskConsole";
import { Button, Row, Col, Spin } from "antd";
import { generate, getPrinterStatus } from "services/kiosk_console.service";
import { getQuestionnaire } from "services/kiosk.service";
import { autoPick } from "appRedux/actions/index";
import _ from "lodash";
import { ResourceText, MediaPlaylist, Footer } from "../Common";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Checkbox from "components/kiosk/Checkbox";
import { CSSTransition } from "react-transition-group";

const QuestionnaireCheckBox = ({ question, onChecked }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    onChecked(checked);
  }, [checked]);

  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
  };
  return (
    <div style={{ fontFamily: "system-ui" }}>
      <label>
        <Checkbox checked={checked} onChange={handleCheckboxChange} />
        <span style={{ marginLeft: 8 }}>{question}</span>
      </label>
    </div>
  );
};

const QuestionnaireList = ({
  locationId,
  language = "en",
  onQuestionComplete
}) => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [checkCount, setCheckCount] = useState(1);

  useEffect(() => {
    getQuestionnaire(locationId, language).then(r => {
      let questionnairesWithChecked = r.map(q => {
        q.checked = false;
        return q;
      });
      setQuestionnaires(questionnairesWithChecked);
    });
  }, []);

  useEffect(() => {
    console.log(checkCount, questionnaires.length);
    if (questionnaires.length > 0 && checkCount === questionnaires.length) {
      onQuestionComplete();
    }
  }, [checkCount]);

  if (questionnaires.length === 0) return <>Loading...</>;

  console.log(`questionnaires`, questionnaires);

  return (
    <div className="question-bg">
      <div className="question-title">{questionnaires[0].title}</div>
      {questionnaires.map(questionnaire => (
        <div className="question" key={questionnaire.id}>
          {/* <Checkbox>{questionnaire.question}</Checkbox> */}
          <QuestionnaireCheckBox
            question={questionnaire.question}
            onChecked={checked =>
              setCheckCount(checked ? checkCount + 1 : checkCount - 1)
            }
          />
          {/* <Checkbox
            checked={questionnaire.checked}
            onChange={e => {
              // questionnaires.find(q => q.id == questionnaire.id).checked =
              //   e.target.checked;
              // setQuestionnaires([...questionnaires]);

              console.log(`questionnaires`, questionnaires);
            }}
          />
          <span style={{ marginLeft: 8 }}>{questionnaire.question}</span> */}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = ({ kioskConsole }) => {
  const {
    launchConsoleId,
    serviceList,
    consoleInfo,
    templateSetting,
    mediaPlaylist
  } = kioskConsole;
  return {
    mediaPlaylist,
    launchConsoleId,
    serviceList,
    consoleInfo,
    templateSetting
  };
};

export default connect(mapStateToProps, { getServiceList, getKioskDevice })(
  ({
    consoleInfo,
    mediaPlaylist,
    templateSetting,
    serviceList,
    getServiceList,
    getKioskDevice,
    history,
    preview,
    onResourceChange
  }) => {
    const [loadingServiceId, setLoadingServiceId] = useState(-1);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [selectedService, setSelectedService] = useState(-1);

    useEffect(() => {
      sessionStorage.setItem("__BY_PASS__", true);
      console.log(
        "** process.env.REACT_APP_KIOSK_ID",
        process.env.REACT_APP_KIOSK_ID
      );
      sessionStorage.setItem(
        "launchKioskConsoleId",
        process.env.REACT_APP_KIOSK_ID
      );
      getKioskDevice();
      getServiceList();
    }, []);

    useEffect(() => {
      console.log(`getQuestionnaire1`);
      if (!_.isEmpty(consoleInfo)) {
        console.log(`getQuestionnaire2`);
        getQuestionnaire(consoleInfo.locationId, "en").then(questionnaires =>
          setQuestionnaires(questionnaires)
        );
      }
    }, [consoleInfo]);

    // useEffect(() => {}, [mediaPlaylist]);

    const selectService = serviceId => {
      setSelectedService(serviceId);
    };
    // async generate(serviceId) {
    //   if (this.state.loadingServiceId === -1) {
    //     this.setState({ loadingServiceId: serviceId });

    //     getPrinterStatus(async status => {
    //       console.log(status);
    //       if (status.status === "ONLINE") {
    //         let result = await generate(this.props.launchConsoleId, serviceId);
    //         console.log(`result`, result);
    //         if (result.status != "OK") {
    //           this.props.history.push(
    //             `/kioskConsole/success/${result.flowNumber}`
    //           );
    //         } else {
    //           this.props.history.push("/kioskConsole/error/SYS_ERR");
    //         }
    //       } else {
    //         this.props.history.push("/kioskConsole/error/PRN_ERR");
    //       }
    //     });
    //   }
    // }

    if (_.isEmpty(templateSetting)) return <>Loading...</>;

    return (
      <>
        <div className="cc-kiosk">
          <div>
            <Row style={{ paddingTop: 25, marginLeft: 0, marginRight: 0 }}>
              <Col
                span={24}
                style={{ padding: 0, marginLeft: 0, marginRight: 0 }}
              >
                <div className="header">
                  &nbsp;
                  {/* Welcome */}
                  <ResourceText
                    page={"HOME"}
                    k={"welcome"}
                    preview={preview}
                    onResourceChange={onResourceChange}
                  ></ResourceText>
                  <br />
                  {/* Please Select Your Preferred Choice */}
                  <ResourceText
                    page={"HOME"}
                    k={"select"}
                    preview={preview}
                    onResourceChange={onResourceChange}
                  ></ResourceText>
                  &nbsp;&nbsp;
                </div>
              </Col>
            </Row>

            {/* <div className="body" style={{ margin: 0, top: "50%" }}> */}
            <Row style={{ paddingTop: 8, marginLeft: 0, marginRight: 0 }}>
              <Col span={12} style={{ padding: 0, margin: 0 }}>
                <MediaPlaylist
                  mediaPlaylistId={mediaPlaylist.id}
                  mediaItems={mediaPlaylist.mediaItems}
                  defaultBg={true}
                  // style={{ width: "100%" }}
                  style={{ width: 400, height: 228, objectFit: "cover" }}
                ></MediaPlaylist>
              </Col>
              <Col span={12} style={{ padding: 0, margin: 0 }}>
                {selectedService === -1 && serviceList && (
                  <div style={{ marginTop: 10 }}>
                    {serviceList.map(service => (
                      <Row key={service.serviceId} style={{ marginLeft: 0 }}>
                        {" "}
                        <Col span={24} style={{ paddingLeft: 0 }}>
                          <div style={{ textAlign: "center" }}>
                            {loadingServiceId === service.serviceId && <Spin />}
                            {loadingServiceId !== service.serviceId && (
                              <div
                                className="button-b"
                                onClick={() => selectService(service.serviceId)}
                              >
                                {service.serviceName}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
                {/* {selectedService !== -1 && (
                <QuestionnaireList
                  onQuestionComplete={() => history.push("./success")}
                  locationId={consoleInfo.locationId}
                />
              )} */}
                {/* {selectedService !== -1 && ( */}
                <CSSTransition
                  in={selectedService !== -1}
                  timeout={1000}
                  classNames="question-pop"
                  // unmountOnExit
                  // onEnter={() => setShowButton(false)}
                  // onExited={() => setShowButton(true)}
                >
                  <div>
                    {/* <h1>hello world</h1> */}
                    {selectedService !== -1 && (
                      <QuestionnaireList
                        onQuestionComplete={() =>
                          !preview && history.push("./success")
                        }
                        locationId={consoleInfo.locationId}
                      />
                    )}
                  </div>
                </CSSTransition>
                {/* )} */}
              </Col>
            </Row>
            {preview && <Footer bottom={70}></Footer>}
            {/* </div> */}
          </div>
        </div>
      </>
    );
  }
);
