import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import Otp from "../Otp";
import LandingPage from "../LandingPage";

import SignUp from "../SignUp";
import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "appRedux/actions/Setting";
import axios from "util/Api";

import KioskMainApp from "routes/kioskConsole/KioskMainApp";
import LaunchKioskConsole from "containers/LaunchKioskConsole";

// import LaunchConsole from "containers/LaunchConsole";
// import LaunchKioskConsole from "containers/LaunchKioskConsole";
// import LaunchSignConsole from "containers/LaunchSignConsole";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import { TFA_MODE_NONE } from "../../constants";
import { getUser } from "../../appRedux/actions/Auth";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor() {
    super();
    if (window.addEventListener) {
      window.addEventListener("storage", this._localStorageListener);
    }
  }

  _localStorageListener = e => {
    // to listen the logout event, which happend by an of other tabs.
    if (e.key === "_logout") {
      this.props.history.push("/signin");
    }
  };

  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  //componentWillMount() {
  componentDidMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log("nextProps--start");
    // console.dir(nextProps);
    // console.log("nextProps---end");

    if (!nextProps.authUser) {
      this.props.getUser();
    }
  }

  render() {
    const {
      match,
      location,
      themeType,
      layoutType,
      navStyle,
      locale,
      initURL,
      authUser,
      otpPass
    } = this.props;
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
    }
    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/signin"} />;
      } else if (authUser.tfaModeCode !== TFA_MODE_NONE && !otpPass) {
        return <Redirect to={"/otp"} />;
      } else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/signin" ||
        initURL === "/otp"
      ) {
        return <Redirect to={"/landing"} />; // todo ##TEMP change back to welcome
      } else {
        return <Redirect to={initURL} />;
      }
    }
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route
              exact
              path="/launchKioskConsole/:consoleId/:psCheck"
              component={LaunchKioskConsole}
            />
            <Route path="/kioskConsole/" component={KioskMainApp} />
            <Route exact path="/landing" component={LandingPage} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/otp" component={Otp} />

            <RestrictedRoute
              path={`${match.url}`}
              authUser={authUser}
              component={MainApp}
            />
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  const { authUser, initURL, otpPass } = auth;
  return {
    locale,
    navStyle,
    themeType,
    layoutType,
    authUser,
    initURL,
    otpPass
  };
};
export default connect(mapStateToProps, {
  setInitUrl,
  getUser,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange
})(App);
