import {
  VMC_USER_SAVE_USER_ROLE_REQUEST,
  VMC_USER_SAVE_USER_ROLE_SUCCESS,
  VMC_USER_SAVE_USER_ROLE_ERROR,
  VMC_USER_SAVE_USER_ROLE_CLEAR,
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_REQUEST,
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_SUCCESS,
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_ERROR,
  VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_CLEAR,
  VMC_USER_GET_ALL_USER_ROLE_REQUEST,
  VMC_USER_GET_ALL_USER_ROLE_SUCCESS,
  VMC_USER_GET_ALL_USER_ROLE_ERROR,
  VMC_USER_GET_ALL_USER_ROLE_CLEAR,
  VMC_USER_REG_LIST_REQUEST,
  VMC_USER_REG_LIST_SUCCESS,
  VMC_USER_REG_LIST_ERROR,
  VMC_USER_REG_LIST_CLEAR
} from "constants/ActionTypes";

import gql from "graphql-tag";
import { store } from "../../NextApp";
import GraphqlCli from "util/GraphqlCli";
import constants from "../../constants";
import { CALL_API } from "../../middleware/api";

const createFetchErrorPayload = action_name =>
  "( " + action_name + " ) Fetch error.";

const apiAction = ({
  path = "user?Action",
  method = "query",
  gql = null,
  variables = {},
  types = []
}) => ({
  [CALL_API]: {
    path: path,
    method: method,
    gql: gql,
    variables: variables,
    types: types
  }
});

export const getUserRoleByUserRoleCodeAC = userRoleCode => (
  dispatch,
  getState
) => {
  const _gql = gql`
    query($userRoleCode: String!, $locationId: Int!) {
      data: getUserRoleByUserRoleCode(
        userRoleCode: $userRoleCode
        locationId: $locationId
      ) {
        status
        error {
          message
        }
        data {
          id
          userRoleName
          userRoleCode
          userRoleDesc
          recordStatus
          createdId
          updatedId
          locationId
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      gql: _gql,
      variables: {
        userRoleCode,
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_REQUEST,
        VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_SUCCESS,
        VMC_USER_GET_USER_ROLE_BY_USER_ROLE_CODE_ERROR
      ]
    })
  );
};

export const getAllUserRoleListAC = () => (dispatch, getState) => {
  const _gql = gql`
    query($locationId: Int!) {
      data: getAllUserRole(locationId: $locationId) {
        status
        error {
          message
        }
        data {
          id
          userRoleName
          userRoleCode
          userRoleDesc
          recordStatus
          createdId
          updatedId
          locationId
        }
      }
    }
  `;
  return dispatch(
    apiAction({
      gql: _gql,
      variables: {
        locationId: getState().auth.selectedLocation.locationId
      },
      types: [
        VMC_USER_GET_ALL_USER_ROLE_REQUEST,
        VMC_USER_GET_ALL_USER_ROLE_SUCCESS,
        VMC_USER_GET_ALL_USER_ROLE_ERROR
      ]
    })
  );
};

export const clearAllUserRoleListAC = () => ({
  type: VMC_USER_GET_ALL_USER_ROLE_CLEAR
});

export const getUserRegListAC = (
  userId,
  userName,
  contactNo,
  email,
  authModeId,
  tfaModeId,
  locationId
) => {
  const _gql = gql`
    query getUserRegList($post: SearchUserInput) {
      data: getUserRegList(post: $post) {
        status
        error {
          message
        }
        data {
          userName
          contactNo
          email
          authModeName
          tfaModeName
          userId
          mlurId
          locationId
          userRoleId
          locationName
          userRoleDesc
          recordStatus
          userLoginId
        }
      }
    }
  `;

  return apiAction({
    gql: _gql,
    variables: {
      post: {
        userId,
        contactNo,
        userName,
        email,
        authModeId,
        tfaModeId,
        locationId
      }
    },
    types: [
      VMC_USER_REG_LIST_REQUEST,
      VMC_USER_REG_LIST_SUCCESS,
      VMC_USER_REG_LIST_ERROR
    ]
  });
};

export const clearUserRegListAC = () => ({
  type: VMC_USER_REG_LIST_CLEAR
});
