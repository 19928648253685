import React from "react";
import ReactDOM from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUpload,
  faIdBadge,
  faMobileAlt,
  faFileSignature,
  faIdCard,
  faCar,
  faCalendarAlt,
  faHourglassHalf,
  faUserSecret,
  faCommentAlt,
  faCreditCard,
  faHandPointer,
  faChartLine,
  faUserCircle,
  faTemperatureHigh,
  faUserTie,
  faMapMarkedAlt,
  faBuilding,
  faDoorOpen,
  faDoorClosed,
  faWalking,
  faStickyNote,
  faTrashAlt,
  faHouseUser,
  faPaperPlane,
  faQrcode,
  faHdd,
  faRoad,
  faUserInjured,
  faStar,
  faListUl,
  faUser,
  faUserCheck,
  faCheckDouble,
  faEnvelopeOpenText,
  faMapMarkerAlt,
  faPhotoVideo,
  faLaptopHouse,
  faUsersCog,
  faTimes,
  faChartBar,
  faFilePdf,
  faFileExcel,
  faGripVertical,
  faArrowsAlt,
  faSms,
  faTimesCircle,
  faChalkboardTeacher,
  faQuestionCircle,
  faSortNumericUpAlt,
  faFileAlt,
  faBorderStyle,
  faSignOutAlt,
  faSignInAlt,
  faEye,
  faPowerOff,
  faCheckCircle,
  faPlay,
  faLayerGroup,
  faGamepad,
  faUniversalAccess,
  faLockOpen
} from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";
// Add this import:
import { AppContainer } from "react-hot-loader";

library.add(
  far,
  fab,
  faUpload,
  faIdBadge,
  faMobileAlt,
  faFileSignature,
  faIdCard,
  faCar,
  faCalendarAlt,
  faHourglassHalf,
  faUserSecret,
  faCommentAlt,
  faCreditCard,
  faHandPointer,
  faChartLine,
  faUserCircle,
  faTemperatureHigh,
  faUserTie,
  faMapMarkedAlt,
  faBuilding,
  faDoorOpen,
  faDoorClosed,
  faWalking,
  faStickyNote,
  faTrashAlt,
  faHouseUser,
  faPaperPlane,
  faCreditCard,
  faQrcode,
  faHdd,
  faRoad,
  faUserInjured,
  faStar,
  faListUl,
  faUser,
  faUserCheck,
  faCheckDouble,
  faEnvelopeOpenText,
  faMapMarkerAlt,
  faLaptopHouse,
  faPhotoVideo,
  faUsersCog,
  faTimes,
  faChartBar,
  faFilePdf,
  faFileExcel,
  faGripVertical,
  faArrowsAlt,
  faSms,
  faTimesCircle,
  faChalkboardTeacher,
  faQuestionCircle,
  faSortNumericUpAlt,
  faFileAlt,
  faBorderStyle,
  faSignOutAlt,
  faSignInAlt,
  faEye,
  faPowerOff,
  faCheckCircle,
  faPlay,
  faLayerGroup,
  faGamepad,
  faUniversalAccess,
  faLockOpen
);

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
