import React from "react";
import { connect } from "react-redux";
import { selectLocation } from "../../appRedux/actions/Auth";

import { Card, Select } from "antd";
const Option = Select.Option;

const Locatoin = props => {
  function handleChange(value) {
    let selectedLocation_ = props.locations.filter(
      loc => loc.locationId === value
    )[0];
    props.selectLocation(selectedLocation_);
  }

  return (
    <div className="gx-cc-hide-sider-collapsed">
      <Select
        value={props.selectedLocation.locationId}
        style={{ width: "100%" }}
        onChange={handleChange}
      >
        {props.locations &&
          props.locations.map(row => {
            return (
              <Option key={row.locationId} value={row.locationId}>
                {row.locationName}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { authUser, selectedLocation } = auth;
  return {
    locations: authUser ? authUser.locations : [],
    selectedLocation: selectedLocation ? selectedLocation : {}
  };
};

export default connect(mapStateToProps, { selectLocation })(Locatoin);
