import React, { Component } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { connect } from "react-redux";
import { setServerTime } from "appRedux/actions/Common";
const GET_SRVER_TIME = gql`
  query {
    serverTime: getServerTime(format: "HH:mm")
  }
`;

function ServerTimePanel(props) {
  const { loading, error, data } = useQuery(GET_SRVER_TIME, {
    context: { path: "user?getServerTime" },
    pollInterval: 1000 * 60
  });
  if (loading) return <div>Loading...</div>;

  // props.setServerTime(data ? data.serverTime : "");

  return <div>Time : {data ? data.serverTime : ""}</div>;
  // return <div>Time : 00</div>;
}

// const mapStateToProps = ({ commonData: { serverTime } }) => {
//   return { serverTime };
// };
// export default connect(mapStateToProps, { setServerTime })(ServerTimePanel);
export default ServerTimePanel;
